import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ama-loading-alert',
  templateUrl: './loading-alert.component.html'
})
export class LoadingAlertComponent {
  @Input() sendingStatus = false;
  @Input() isDismissible = true;
  @Input() message = 'Loading...';

  constructor() { }
}
