import { LookupOptions } from '../../core/util/lookup-options';

export const NAVIGATION_FEATURE = 'navigationUppUi';

export interface NavigationState {
  navigation?: LookupOptions;
  partitionDestination?: string;
  showPartitionSwitchPopup?: boolean;
}

export const navigationInitialState: NavigationState = {
  navigation: {}
};
