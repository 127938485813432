export class URLUtils {
  private static readonly urlQuerySeparator = /\?(.*)/;
  private static readonly splitLimit = 2;

  public static getQueryParamValue(url: string, paramName: string): string | undefined {
    const query = url.split(URLUtils.urlQuerySeparator, URLUtils.splitLimit)[1];

    return new URLSearchParams(query).get(paramName) ?? undefined;
  }
}
