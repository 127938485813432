<div class="photo-full-screen"></div>
@if (cookieBannerLoaded()) {
  <ngb-alert type="info" (closed)="closeCookieBanner()">
    <div class="d-flex flex-row">
      <div  class="me-5">
        <span>This website only uses first-party, strictly necessary cookies for technical purposes, and it does not collect or transfer users' personal data without their consent. For more information, please read our </span>
        <a href="#" class="link-normal" (click)="openCookiePolicyModal(cookiePolicyPopupContent )"> Cookie policy. </a>
      </div>
    </div>
  </ngb-alert>
}

<ng-template #cookiePolicyPopupContent  let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title">Cookie Policy</h2>
    <button type="button" class="btn-close" aria-label="Close Modal title" (click)="d('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <h2 class="text-primary">What are cookies?</h2>
    <p>
      Cookies are small files stored on a device, such as a computer, a mobile device or any other device that can store information. Cookies serve a number of important functions, including remembering users and their previous interactions with a website.
    </p>
    <h2 class="text-primary">What type of cookies does UPP use?</h2>
    <p>
      UPP does not use cookies to collect information from users. It only uses session or strictly necessary cookies for technical purposes (those used to deliver the basic functions of a website i.e. to allow pages to remember technical changes or selections you may make between pages). Session or strictly necessary cookies are temporary cookies and are generally erased when you close your browser.
    </p>
  </div>
</ng-template>
<div class="container container-body">
  <div class="row">
    <div class="col">
      <h1 class="display-3 headline">Amadeus Unified Preferences Platform</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 col-lg-5 col-md-3 col-sm-1 col-0"></div>
    <div class="col-auto">
      <div class="login-background">
        <h1>Sign In</h1>
        <p>Using your Amadeus credentials</p>
        <p *ngIf="this.clpError" class="text-danger">
          {{clpError[0]?.error.message}} ({{clpError[0]?.code}})
        </p>
        <ama-ng-login></ama-ng-login>
      </div>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-0"></div>
  </div>
</div>

<div class="login-footer">
  <div class="row">
    <div class="col-4 text-start">
      <div class="df-amadeuslogo-primary df-logo-dim"></div>
    </div>
    <div class="col-4 text-center">
      <div>&copy; Amadeus IT Group SA</div>
    </div>
    <div class="col-4 d-flex justify-content-end gap-4">
      <a href="javascript:void(0);" (click)="openCookiePolicyModal(cookiePolicyPopupContent)">Cookie Policy</a>
      <a href="https://amadeus.com/en/policies/privacy-policy" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>
