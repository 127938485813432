import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlightsState, FLIGHTS_FEATURE } from './flights-state';

export const selectFlights = createFeatureSelector<FlightsState>(FLIGHTS_FEATURE);

export const selectFlightRuleCreateValue = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.create.value
);

export const selectFlightRuleCreateSending = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.create.sending
);

export const selectFlightRuleCreateNotification = createSelector(selectFlights, (state: FlightsState) => {
  if (state.notification && state.notification.create) {
    return state.notification.create;
  }
  return undefined;
});

export const selectFlightModifyActivated = createSelector(selectFlights, (state: FlightsState) => {
  if (state.flightRule.modify.active) {
    return state.flightRule.modify.active;
  }
  return false;
});

export const selectFlightRuleDisplayActivated = createSelector(selectFlights, (state: FlightsState) => {
  if (state.flightRule.display.active) {
    return state.flightRule.display.active;
  }
  return false;
});

export const selectFlightModifyValue = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.modify.value
);

export const selectFlightModifySending = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.modify.sending
);

export const selectFlightModifyNotification = createSelector(selectFlights, (state: FlightsState) => {
  if (state.notification && state.notification.modify) {
    return state.notification.modify;
  }
  return undefined;
});

export const selectFlightRuleDisplayValue = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.display.value
);

export const selectFlightRuleDisplayNotification = createSelector(selectFlights, (state: FlightsState) => {
  if (state.notification && state.notification.display) {
    return state.notification.display;
  }
  return undefined;
});

export const selectFlightRules = createSelector(selectFlights, (state: FlightsState) => state.flightRules);

export const selectFlightRuleSearchSending = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.search.sending
);

export const selectFlightRuleSearchNotification = createSelector(selectFlights, (state: FlightsState) => {
  if (state.notification && state.notification.search) {
    return state.notification.search;
  }
  return undefined;
});

export const selectFlightRuleSearchValue = createSelector(
  selectFlights,
  (state: FlightsState) => state.flightRule.search.value
);

export const selectFlightRuleDeleteSending = createSelector(selectFlights, (state: FlightsState) => {
  if (state && state.flightRule && state.flightRule.delete) {
    return state.flightRule.delete.sending;
  }
  return undefined;
});
