import { Action } from '@ngrx/store';
import {
  FlightRuleCreateRequest,
  FlightRuleSearchRequest,
  FlightRuleDeleteRequest,
  FlightRuleUpdateRequest
} from '../../flights/model/flight-request';
import { JourneyUi, JourneySearchCriteriaUi } from '../../flights/model';

export const FLIGHTS_ACTION = {
  INIT_FLIGHT_RULE_STATE: '[FLIGHTS Action] Initializing Flight rule state',
  CREATE_FLIGHT_RULE: '[FLIGHTS Action] Creating Flight rule',
  SET_CREATE_FLIGHT_RULE_VALUE: '[FLIGHTS Action] Setting create flight rule values',
  FINISH_FLIGHT_RULE_CREATION: '[FLIGHTS Action] Finishing flight rule creation',

  START_FLIGHT_MODIFICATION: '[FLIGHTS Action] Activating flight modification',
  CANCEL_FLIGHT_MODIFICATION: '[FLIGHTS Action] Canceling flight modification',
  SET_FLIGHT_MODIFY_VALUE: '[FLIGHTS Action] Setting flight modify value',
  SET_FLIGHT_UPDATE_SUCCESS_VALUES: '[FLIGHTS Action] Setting flight update success values',
  UPDATE_FLIGHT: '[FLIGHTS Action] Updating flight',
  FINISH_FLIGHT_UPDATE: '[FLIGHTS Action] Finishing flight update',

  ACTIVATE_FLIGHT_RULE_DISPLAY: '[FLIGHTS Action] Activating flight rule display',
  ACTIVATE_CREATED_FLIGHT_RULE_DISPLAY: '[FLIGHTS Action] Displaying created flight rule display',
  SEARCH_FLIGHT_RULE: '[FLIGHTS Action] Searching flight rules',
  SET_FLIGHT_RULE_LIST: '[FLIGHTS Action] Setting flight rules',
  CLEAR_FLIGHT_RULE_LIST: '[FLIGHTS Action] Clearing flight rules',
  SET_FLIGHT_RULE_SEARCH_VALUE: '[FLIGHTS Action] Setting flight rule search value',

  DELETE_FLIGHT_RULE: '[FLIGHTS Action] Deleting Flight rule',
  DELETE_FLIGHT_RULE_CLEAR_STORE: '[FLIGHTS Action] Clearing store after flight rule deletion',
  DELETE_FLIGHT_RULE_FROM_LIST: '[FLIGHTS Action] Deleting flight rule from list',
  DELETE_FLIGHT_RULE_SEARCH_NOTIFICATION: '[FLIGHTS Action] Deleting flight rule search notification',
  DELETE_FLIGHT_RULE_CREATE_NOTIFICATION: '[FLIGHTS Action] Deleting flight rule create notification',
  DELETE_FLIGHT_RULE_MODIFY_NOTIFICATION: '[FLIGHTS Action] Deleting flight modify notification',
  DELETE_FLIGHT_RULE_DISPLAY_NOTIFICATION: '[FLIGHTS Action] Deleting flight rule display notification',

  COPY_FLIGHT_RULE: '[FLIGHTS Action] Copy flight rule'
};

export class InitFlightRuleStateAction implements Action {
  readonly type = FLIGHTS_ACTION.INIT_FLIGHT_RULE_STATE;
  constructor(public payload: any) {}
}

export class CreateFlightRuleAction implements Action {
  readonly type = FLIGHTS_ACTION.CREATE_FLIGHT_RULE;
  constructor(public payload: { request: FlightRuleCreateRequest }) {}
}

export class SetCreateFlightRuleValueAction implements Action {
  readonly type = FLIGHTS_ACTION.SET_CREATE_FLIGHT_RULE_VALUE;
  constructor(public payload: { value: JourneyUi }) {}
}

export class FinishFlightRuleCreationAction implements Action {
  readonly type = FLIGHTS_ACTION.FINISH_FLIGHT_RULE_CREATION;
  constructor(public payload: { journey: JourneyUi }) {}
}

export class ActivateFlightRuleDisplayAction implements Action {
  readonly type = FLIGHTS_ACTION.ACTIVATE_FLIGHT_RULE_DISPLAY;
  constructor(public payload: { id: string }) {}
}

export class ActivateCreatedFlightRuleDisplayAction implements Action {
  readonly type = FLIGHTS_ACTION.ACTIVATE_CREATED_FLIGHT_RULE_DISPLAY;
  constructor(public payload: any) {}
}

export class UpdateFlightAction implements Action {
  readonly type = FLIGHTS_ACTION.UPDATE_FLIGHT;
  constructor(public payload: { request: FlightRuleUpdateRequest }) {}
}

export class SetFlightModifyValueAction implements Action {
  readonly type = FLIGHTS_ACTION.SET_FLIGHT_MODIFY_VALUE;
  constructor(public payload: { value: JourneyUi }) {}
}
export class StartFlightModificationAction implements Action {
  readonly type = FLIGHTS_ACTION.START_FLIGHT_MODIFICATION;
  constructor(public payload: { id: string }) {}
}
export class SetFlightUpdateSuccessValuesAction implements Action {
  readonly type = FLIGHTS_ACTION.SET_FLIGHT_UPDATE_SUCCESS_VALUES;
  constructor(public payload: { updatedFlightRule: JourneyUi }) {}
}
export class CancelFlightModificationAction implements Action {
  readonly type = FLIGHTS_ACTION.CANCEL_FLIGHT_MODIFICATION;
  constructor(public payload: any) {}
}
export class FinishFlightUpdateAction implements Action {
  readonly type = FLIGHTS_ACTION.FINISH_FLIGHT_UPDATE;
  constructor(public payload: { updatedJourneyUi: JourneyUi }) {}
}

export class SearchFlightRuleAction implements Action {
  readonly type = FLIGHTS_ACTION.SEARCH_FLIGHT_RULE;
  constructor(public payload: { request: FlightRuleSearchRequest }) {}
}

export class SetFlightRuleListAction implements Action {
  readonly type = FLIGHTS_ACTION.SET_FLIGHT_RULE_LIST;
  constructor(public payload: { flightRules: JourneyUi[] }) {}
}

export class ClearFlightRulesAction implements Action {
  readonly type = FLIGHTS_ACTION.CLEAR_FLIGHT_RULE_LIST;
  constructor(public payload: any) {}
}

export class SetFlightRuleSearchValueAction implements Action {
  readonly type = FLIGHTS_ACTION.SET_FLIGHT_RULE_SEARCH_VALUE;
  constructor(public payload: { flightSearchCriteria: JourneySearchCriteriaUi }) {}
}

export class DeleteFlightRuleAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE;
  constructor(public payload: { request: FlightRuleDeleteRequest }) {}
}

export class DeleteFlightRuleClearStoreAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE_CLEAR_STORE;
  constructor(public payload: any) {}
}

export class DeleteFlightRuleFromListAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE_FROM_LIST;
  constructor(public payload: { deletedRule: JourneyUi }) {}
}

export class DeleteFlightModifyNotificationAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE_MODIFY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFlightRuleCreateNotificationAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE_CREATE_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFlightRuleDisplayNotificationAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE_DISPLAY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFlightRuleSearchNotificationAction implements Action {
  readonly type = FLIGHTS_ACTION.DELETE_FLIGHT_RULE_SEARCH_NOTIFICATION;
  constructor(public payload: any) {}
}

export class CopyFlightRuleAction implements Action {
  readonly type = FLIGHTS_ACTION.COPY_FLIGHT_RULE;
  constructor(public payload: { id: string }) {}
}

export type FlightsActions =
  | InitFlightRuleStateAction
  | CreateFlightRuleAction
  | SetCreateFlightRuleValueAction
  | UpdateFlightAction
  | SetFlightModifyValueAction
  | StartFlightModificationAction
  | SetFlightUpdateSuccessValuesAction
  | CancelFlightModificationAction
  | FinishFlightUpdateAction
  | FinishFlightRuleCreationAction
  | ActivateFlightRuleDisplayAction
  | ActivateCreatedFlightRuleDisplayAction
  | SearchFlightRuleAction
  | SetFlightRuleListAction
  | ClearFlightRulesAction
  | SetFlightRuleSearchValueAction
  | DeleteFlightRuleAction
  | DeleteFlightRuleClearStoreAction
  | DeleteFlightRuleFromListAction
  | DeleteFlightRuleCreateNotificationAction
  | DeleteFlightRuleDisplayNotificationAction
  | DeleteFlightRuleSearchNotificationAction
  | DeleteFlightModifyNotificationAction
  | CopyFlightRuleAction;
