import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PosMarketSearchParameter, VALIDATORS } from '../../../service/model/pos';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MarketElement } from '../../../service/model/market/market-element';
import { select, Store } from '@ngrx/store';
import { SetMarketsSearchValueAction } from '../../../store/markets/markets-actions';
import { selectMarketsSearchValue } from '../../../store/markets/markets-selector';
import { UserDetailsService } from '../../../service/user-details.service';
import { SearchCriteriaComponent } from '../../../base/search-criteria/search-criteria.component';
import { UppValidatorService } from '../../../service/upp-validator.service';
import { UppComponentNames } from '../../../service/model';
import { LookupOptions } from '../../../core/util/lookup-options';
import { selectNavigation } from '../../../store/navigation/navigation-selector';

@Component({
  selector: 'ama-ng-upp-markets-search-criteria',
  templateUrl: './markets-search-criteria.component.html',
  styleUrls: ['./markets-search-criteria.component.scss']
})
export class MarketsSearchCriteriaComponent extends SearchCriteriaComponent implements OnInit {

  @Input() sendingStatus: boolean;
  @Output() searchMarkets = new EventEmitter<PosMarketSearchParameter>();

  readonly COMPONENT_NAME = UppComponentNames.MARKETS;

  readonly: false;
  nameRegex = '^[A-Z0-9]{0,30}$';

  numberOfItems = 2;

  marketsElement: MarketElement = { location: {} };
  searchParameters: PosMarketSearchParameter;

  regionPasteRegExp = /[,|;]/; // separator regex for ',' and ';'

  lookupOptions: LookupOptions;
  lookup = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly validatorService: UppValidatorService
  ) {
    super();
    this.validatorService.setComponentValidation(UppComponentNames.MARKETS, VALIDATORS);
    this.buildRegionsDescriptionOnly();
  }

  get includeFormGroup(): UntypedFormGroup {
    return this.searchCriteriaForm.get('include') as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.createAndLoadSearchForm();
    this.store.select(selectNavigation).subscribe(
      lookupParams => this.lookupOptions = lookupParams);
    this.lookup = this.lookupOptions?.lookup;
  }

  createAndLoadSearchForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.maxLength(30), Validators.pattern(this.nameRegex)]),
      include: this.formBuilder.group({
        world: this.formBuilder.control(false)
      })
    });
    setTimeout(() => {
      this.store.pipe(select(selectMarketsSearchValue)).subscribe(markets => {
        if (!this.lookup && markets) {
          this.searchCriteriaForm.patchValue(markets);
        }
      });
    }, 0);
  }

  clearClicked() {
    this.searchCriteriaForm.setValue({
      name: '',
      include: {
        world: false,
        regions: [],
        countries: [],
        cities: [],
        airports: []
      }
    });
  }

  searchClicked() {
    const regionCodes = this.getRegionCodesForDescriptions(this.searchCriteriaForm.get(['include', 'regions']).value);
    this.marketsElement = {
      location: {
        world: this.searchCriteriaForm.get(['include', 'world']).value,
        regions: regionCodes,
        countries: this.searchCriteriaForm.get(['include', 'countries']).value,
        cities: this.searchCriteriaForm.get(['include', 'cities']).value,
        airports: this.searchCriteriaForm.get(['include', 'airports']).value
      }
    };
    this.initializeSearchParameters();
  }

  initializeSearchParameters() {
    if(this.userDetailsService.loggedInUser.partitions) {
      this.searchParameters = {
        partitionId: this.userDetailsService.loggedInUser.selectedPartition,
        element: this.marketsElement
      };
    } else {
      this.searchParameters = {
        organization: this.userDetailsService.getLssOrganization(),
        element: this.marketsElement
      };
    }
    const name = this.searchCriteriaForm.get('name').value as string;
    if (name && name.length > 0) {
      this.searchParameters.name = name;
    }
    this.emitSearchParameters();
  }

  // Search-parameters(form value) will be emitted to the MarketsSearchComponent, in order to create a search request.
  emitSearchParameters() {
    this.searchMarkets.emit(this.searchParameters);
  }

  storeUnsavedMarketsSearch() {
    this.store.dispatch(new SetMarketsSearchValueAction({ value: this.searchCriteriaForm.value }));
  }
}
