import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../service/core/navigation.service';
import { HotelRuleUi, initialHotelRuleUi } from '../model';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  selectHotelRuleDisplayValue,
  selectHotelRuleDisplayActivated,
  selectHotelRuleDisplayNotification
} from '../../store/hotel/hotels-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppComponent } from '../../service/model/upp-component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  ActivateHotelRuleDisplayAction,
  DeleteHotelRuleDisplayNotificationAction,
  SetHotelModifyValueAction,
  StartHotelModificationAction
} from '../../store/hotel/hotels-action';
import { PERMISSION_MANAGE_HOTEL } from '../../service/user-permissions.service';
import { UserDetailsService } from '../../service/user-details.service';

@Component({
  selector: 'ama-ng-upp-hotels-display',
  templateUrl: './hotels-display.component.html',
  styleUrls: ['./hotels-display.component.scss']
})
export class HotelsDisplayComponent implements OnInit {
  hotelForm: UntypedFormGroup;
  hotelRule: HotelRuleUi = initialHotelRuleUi;
  notification$: Observable<UppNotification>;
  displayActivated: boolean;

  readonly = true;
  uppComponent = UppComponent.HOTELS_DISPLAY;
  mainMessages: NotificationMessages;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.store
      .pipe(select(selectHotelRuleDisplayActivated))
      .subscribe(activated => (this.displayActivated = activated));
    this.notification$ = this.store.pipe(select(selectHotelRuleDisplayNotification));
    this.mainMessages = {
      error: $localize`:@@upp.hotels.display.mainErrorText:The following errors for hotel rule appeared:`,
      warning: $localize`:@@upp.hotels.display.mainWarningText:The following warning for hotel rule appeared:`,
      success: $localize`:@@upp.hotels.display.mainSuccessText:The hotel rule was stored successfully.`
    };
  }

  ngOnInit() {
    this.createForm();
    this.navigationService.setSelectedMenuTitle('Hotel rule display');
    this.navigationService.activateHotels();
    if (!this.displayActivated) {
      this.router.navigate(['hotels/search/']);
    } else {
      this.loadHotelRule();
    }
  }

  createForm() {
    this.hotelForm = this.formBuilder.group({});
    this.store.pipe(select(selectHotelRuleDisplayValue)).subscribe(value => (this.hotelRule = value));
  }

  loadHotelRule() {
    this.route.paramMap.subscribe((params: ParamMap) => this.activateHotelRuleWithId(params.get('id')));
  }

  activateHotelRuleWithId(paramId: string) {
    this.store.dispatch(new ActivateHotelRuleDisplayAction({ id: paramId }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteHotelRuleDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess() && this.hotelRule && this.hotelRule.name && this.hotelRule.name !== '';
  }

  modifyHotel() {
    this.store.dispatch(new StartHotelModificationAction({ id: undefined }));
    this.store.dispatch(new SetHotelModifyValueAction({ value: this.hotelRule }));
    this.router.navigate(['hotels/modify/'], { queryParams: { sourceView: 'display' } });
  }

  hasManageAccess() {
    const permissions = this.userDetailsService.loggedInUser.permissions;
    return permissions.filter(p => p === PERMISSION_MANAGE_HOTEL).length > 0;
  }
}
