<div *ngIf="(partitionCallDone$ | async) !== true; else userLoggedIn">
  <app-ama-upp-login [clpError]="clpError$ | async"></app-ama-upp-login>
</div>

<ng-template class="design-factory-v2 amadeus-style" #userLoggedIn>
  <div class="d-flex flex-column vh-100">
    <ama-ng-upp-header
      [user]="loggedInUser$ | async"
      [applicationName]="appName"
      (signOffClicked)="signOffUser()"
    ></ama-ng-upp-header>
    <div class="container-fluid p-0 flex-column">
      <ama-ng-upp-navigation-menu></ama-ng-upp-navigation-menu>
      <div
        class="d-inline-flex align-top h-100"
        [ngClass]="{ 'width-plus-sidebar': lookup, 'width-minus-sidebar': !lookup }"
      >
        <div class="d-inline-flex flex-column h-100 w-100">
          <div class="flex-shrink-0">
            <main id="router" class="d-block align-top">
              <router-outlet></router-outlet>
            </main>
          </div>
          <div class="mt-auto">
            <ama-ng-upp-footer></ama-ng-upp-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
