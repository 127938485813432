import { AirFamily } from '../../../families/air/model/air-family';
import { createAction, props } from '@ngrx/store';
import { ViewMode } from '../../../service/model';
import { CORE_ACTION } from '../../core/actions';

export const saveAirFamilies = createAction('[AIR FAMILIES] Save air families', props<{ airFamilies: AirFamily[] }>());

export const getAllAvailableAirFamilies = createAction('[AIR FAMILIES] Get all available air families', props<any>());

export const setAllAvailableAirFamilies = createAction('[AIR FAMILIES] Set all available air families', props<{ airFamilies: AirFamily[]}>());

export const setSelectedAirFamilyNames = createAction('[AIR FAMILIES] Select air family names', props<{ selectedNames: Set<string>}>());

export const clearAirFamilyNamesSelection = createAction('[AIR FAMILIES] Clear air family selection');

export const airFamilyDeleted = createAction('[AIR FAMILIES] Air family deleted', props<{ id: string }>());

export const airFamilyCreated = createAction('[AIR FAMILIES] Air family created', props<{ airFamily: AirFamily }>());

export const airFamilyModified = createAction('[AIR FAMILIES] Air family modified', props<{ airFamily: AirFamily }>());

export const saveAirFamily = createAction(
  '[AIR FAMILIES] Save air family',
  props<{ selectedAirFamily: AirFamily, viewMode: ViewMode }>()
);

export const airFamilyNotificationClosed = createAction(
  '[AIR FAMILIES] Air family notification closed',
  props<{ viewMode: ViewMode }>()
);

export const saveLastActiveAirFamilyView = createAction(
  '[AIR FAMILIES] Save last active view',
  props<{ lastActiveView: ViewMode }>()
);

export const partitionChanged = createAction(CORE_ACTION.UPDATE_SELECTED_PARTITION);
