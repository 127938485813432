<h1 i18n="@@upp.families.air.search.title">Search Air Families</h1>

<section class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="isLoading"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</section>

<ama-upp-notification
  class="col"
  [mainMessages]="searchNotificationMessages"
>
</ama-upp-notification>

<form class="styled-form" [formGroup]="airFamiliesSearchForm" (ngSubmit)="search()">
  <section class="row">
    <div class="col text-end mb-2">
      <button
        i18n="@@upp.global.button.clear"
        type="button"
        [disabled]="isLoading"
        class="btn btn-outline-primary btn-sm"
        (click)="clearForm()"
        data-test="clear-button"
      >Clear
      </button>
    </div>
  </section>

  <section class="mb-4">
    <label i18n="@@upp.families.air.search.nameLabel" for="name" class="form-label">Name</label>
    <input
      type="text"
      class="form-control"
      id="name"
      data-test="air-family-name"
      [formControlName]="FormElements.NAME"
      [ngClass]="{ 'is-invalid': nameControl.invalid && nameControl.touched }"
      placeholder="Air family name"
      i18n-placeholder="@@upp.families.air.namePlaceholder"
      ngbTooltip="Enter the Air Family name with letters, numbers or spaces (up to 30 characters)"
      i18n-ngbTooltip="@@upp.families.air.nameTooltip"
      oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
    />
    <ama-ng-upp-validation-error-message
      [control]="nameControl"
      [messageFunctions]="NAME_WITH_SPACES_VALIDATION_MESSAGES">
    </ama-ng-upp-validation-error-message>


    <label class="form-label mt-5" [for]="FormElements.CARRIER_CODES"
           i18n="@@upp.global.criteria.carrierCode.label">Carrier code</label>
    <ama-ng-upp-lookup-select [formControlName]="FormElements.CARRIER_CODES" [multiple]="true"
                              id="carrierCode"
                              [addTag]="true"
                              notFoundText="No airlines found"
                              i18n-notFoundText="@@upp.global.criteria.carrierCode.notFoundText">
    </ama-ng-upp-lookup-select>
    <ama-ng-upp-validation-error-message [control]="airFamiliesSearchForm.get(FormElements.CARRIER_CODES)"
                                         [messageFunctions]="TWO_SYMBOLS_REQUIRED">
    </ama-ng-upp-validation-error-message>
  </section>

  <section class="d-flex justify-content-center mt-8">
    <button
      i18n="@@upp.global.button.search"
      id="search"
      type="submit"
      class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
      [disabled]="airFamiliesSearchForm.invalid || isLoading">
      Search
    </button>
  </section>

  <ama-ng-upp-basic-search-result-table
    [searchResults]="airFamilies$ | async"
    [selectedNames]="selectedAirFamilyNames$ | async"
    [sendingStatus]="isLoading"
    [sourceComponent]="UppComponentNames.AIR_FAMILIES"
    [entryType]="EntryType.AIR_FAMILY"
    [recordToSearchResult]="recordToSearchResult.bind(this)"
    [entryToUpdateCreator]="entryToUpdateCreator"
    [entryToUpdateNameResolver]="entryToUpdateNameResolver"
    (deleteEntry)="deleteAirFamily($any($event))"
    (clearTableEvent)="clearAirFamilies()"
    [managePermission]="PERMISSION_MANAGE_FAMILY"
    [displayableFields]="displayableFields"
    [isLookup]="lookup"
    [lookupValues]="lookupValues"
    [lookupOptions]="lookupOptions"
    (showDetail)="showAirFamily($event, UppViewNames.DISPAY)"
    (modifyDetail)="showAirFamily($event, UppViewNames.MODIFY)"
    [copyForbidden]="true">
  </ama-ng-upp-basic-search-result-table>

</form>
