import { arraysEquals } from '../../util/arrayUtils';
import { BaseUi, baseUiEquals } from '../../base/model/base-ui';
import { CabinOptions, FareServiceElements, FareTypeOptions, PriceRangeType, ServiceType } from './fare-service-elements';

export const RULE_STATUS_ALL = 'all';
export const RULE_STATUS_ACTIVE = 'active';
export const RULE_STATUS_INACTIVE = 'inactive';

export interface FareRuleUi extends BaseUi {
  rule: FareRuleDefinitionUi | null;
  reference: FareRuleUiReference | null;
  exclusion: FareRuleUiCriteria | null;
}

export interface FareRuleDefinitionUi {
  version?: string;
  organization: string;
  name: string;
  description: string;
  active: boolean;
}

export const initialFareRuleUi: FareRuleUi = {
  rule: {
    organization: '',
    name: '',
    description: '',
    active: true
  },
  reference: {
    pointOfSaleNames: [],
    originMarkets: [],
    destinationMarkets: [],
    airlines: [],
    cabins: null,
    fareTypes: []
  },
  exclusion: {
    priceRangeType: PriceRangeType.RATE,
    priceDifferenceMin: null,
    priceDifferenceMax: null,
    departureRangeActive: false,
    departureTimeWindowMin: null,
    departureTimeWindowMax: null,
    serviceTypes: [],
    carriers: [],
    fareTypes: [],
    numberOfConnections: null,
    content: FareServiceElements.CONTENT_OPTIONS_NDC
  }
};

export interface FareRuleUiReference {
  pointOfSaleNames?: string[];
  originMarkets?: string[];
  destinationMarkets?: string[];
  airlines?: string[];
  cabins?: FareCabin | null;
  fareTypes?: FareTypeOptions[];
}

export interface FareCabin {
  label: string;
  value: CabinOptions;
}

export interface FareRuleUiCriteria {
  carriers?: string[];
  fareTypes?: FareTypeOptions[];
  content?: string;
  priceRangeType: PriceRangeType;
  priceRange?: string;
  priceDifferenceMin?: number | null;
  priceDifferenceMax?: number | null;
  departureRangeActive?: boolean;
  departureTimeWindowMin?: number | null;
  departureTimeWindowMax?: number | null;
  serviceTypes?: ServiceType[];
  directionality?: string;
  numberOfConnections?: number | number[] | null;
}


export const fareUiEquals = (f1: FareRuleUi, f2: FareRuleUi): boolean =>
  baseUiEquals(f1, f2) &&
  fareRuleUiEquals(f1.rule, f2.rule) &&
  fareRuleUiReferenceEquals(f1.reference, f2.reference) &&
  fareRuleUiCriteriaEquals(f1.exclusion, f2.exclusion);

export const fareRuleUiEquals = (r1: FareRuleDefinitionUi | null | undefined, r2: FareRuleDefinitionUi | null | undefined): boolean => {
  if (!r1 || !r2) {
    return false;
  }

  if (r1 === r2) {
      return true;
  }

  return r1.version === r1.version &&
    r1.name === r1.name &&
    r1.description === r1.description &&
    r1.active === r1.active;
};

export const fareRuleUiReferenceEquals = (r1: FareRuleUiReference | null | undefined, r2: FareRuleUiReference | null | undefined): boolean => {
  if (!r1 || !r2) {
    return false;
  }

  if (r1 === r2) {
    return true;
  }

  return arraysEquals(r1.pointOfSaleNames, r2.pointOfSaleNames) &&
    arraysEquals(r1.originMarkets, r2.originMarkets) &&
    arraysEquals(r1.destinationMarkets, r2.destinationMarkets) &&
    arraysEquals(r1.airlines, r2.airlines) &&
    fareRuleUiFareCabinEquals(r1.cabins, r2.cabins) &&
    arraysEquals(r1.fareTypes, r2.fareTypes);
};

export const fareRuleUiFareCabinEquals = (c1: FareCabin | null | undefined, c2: FareCabin | null | undefined): boolean => {
  if (!c1 || !c2) {
    return false;
  }

  if (c1 === c2) {
      return true;
  }

  return c1.label === c2.label && c1.value === c2.value;
};

export const fareRuleUiCriteriaEquals = (c1: FareRuleUiCriteria | null | undefined, c2: FareRuleUiCriteria | null | undefined): boolean => {
  if (!c1 || !c2) {
    return false;
  }

  if (c1 === c2) {
    return true;
  }

  return  arraysEquals(c1.carriers, c2.carriers) &&
    arraysEquals(c1.fareTypes, c2.fareTypes) &&
    c1.content === c2.content &&
    c1.priceRangeType === c2.priceRangeType &&
    c1.priceRange === c2.priceRange &&
    c1.priceDifferenceMin === c2.priceDifferenceMin &&
    c1.priceDifferenceMax === c2.priceDifferenceMax &&
    c1.departureRangeActive === c2.departureRangeActive &&
    c1.departureTimeWindowMin === c2.departureTimeWindowMin &&
    c1.departureTimeWindowMax === c2.departureTimeWindowMax &&
    arraysEquals(c1.serviceTypes, c2.serviceTypes) &&
    c1.directionality === c2.directionality &&
    c1.numberOfConnections === c2.numberOfConnections;
};

