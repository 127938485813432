import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FlightsSearchComponent } from '../../flights/flights-search/flights-search.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFlightRuleSearchGuard  {
  canDeactivate(component: FlightsSearchComponent): Observable<boolean> | boolean {
    component.storeUnsavedFlightSearch();
    return true;
  }
}
