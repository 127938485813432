import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserDetailsService } from '../../../../service/user-details.service';
import {
  PERMISSION_MANAGE_EDIFACT_RULE,
  PERMISSION_MANAGE_FLIGHT,
  PERMISSION_MANAGE_NDC_RULE
} from '../../../../service/user-permissions.service';
import { ConfigurationService } from '../../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../resources';

@Injectable({
  providedIn: 'root'
})
export class CanManageFlightGuard {
  constructor(private readonly userDetailsService: UserDetailsService, private readonly configurationService: ConfigurationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isNDCCriteriaActivated = this.configurationService.getParameter<boolean>(FeatureFlags.flightsNDCCriteria);
    const permissions = this.userDetailsService.loggedInUser?.permissions ?? [];
    return permissions.filter((p) =>
        isNDCCriteriaActivated
          ? (p === PERMISSION_MANAGE_EDIFACT_RULE || p === PERMISSION_MANAGE_NDC_RULE)
          : (p === PERMISSION_MANAGE_FLIGHT)
      ).length > 0;
  }
}
