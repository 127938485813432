import { NgClass } from '@angular/common';
import { Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ComponentsModule } from '../../components/components.module';
import { integerNumberValidator } from '../../components/form-validators/integer-number.validator';
import { lessThanValidator } from '../../components/form-validators/less-than.validator';
import { moreThanValidator } from '../../components/form-validators/more-than.validator';
import { positiveNumberValidator } from '../../components/form-validators/positive-number.validator';
import { LookupOptions } from '../../core/util/lookup-options';
import { ButtonGroupItem } from '../../model/button-group-item';
import { MarketPair } from '../../model/market-pair';
import { NavigationService } from '../../service/core/navigation.service';
import { UppComponent, UppComponentNames, UppViewNames } from '../../service/model';
import {
  MAX_LENGTH_STRING_VALIDATION_MESSAGES,
  NAME_WITH_SPACES_VALIDATION_MESSAGES,
  REQUIRED_MSG,
  SECTION_VALIDATION_MESSAGES,
  TWO_SYMBOLS_REQUIRED
} from '../../service/model/common/validation-messages';
import { nameRegex, twoAlphaNumericRegex } from '../../service/model/common/validators';
import { UserDetailsService } from '../../service/user-details.service';
import { PERMISSION_VIEW_MARKET, PERMISSION_VIEW_POS } from '../../service/user-permissions.service';
import { selectAllAvailableMarketsRecords } from '../../store/markets/markets-selector';
import { selectAllAvailablePosRecords } from '../../store/pos/pos-selector';
import { fareTypesOptions } from '../model/fare-criteria-data';
import { convertFareRuleUiToFareRuleUiV2 } from '../model/fare-rule-converter';
import { FareRuleUi, initialFareRuleUi } from '../model/fare-rule-ui';
import { FareRuleUiV2 } from '../model/fare-rule-ui-v2';
import { PriceRangeType } from '../model/fare-service-elements';


const MARKET_LOOKUP_OPTIONS: LookupOptions = {
  destinationComponent: UppComponentNames.MARKETS,
  sourceComponent: UppComponentNames.FARES,
  sourceView: UppViewNames.CREATE
};

const MIN_DEPARTURE_SLIDER = -1440;
const MAX_DEPARTURE_SLIDER = 1440;

const PRICE_RANGE_MAX_PATH = 'exclusion.priceRangeMax';

@Component({
  selector: 'ama-ng-upp-fares-details-v2',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, NgClass, NgbTooltipModule],
  templateUrl: './fares-details-v2.component.html',
  styleUrl: './fares-details-v2.component.scss'
})
export class FaresDetailsV2Component implements OnInit, OnChanges, OnDestroy {
  @Input() fareDetailsForm!: UntypedFormGroup;
  // This is the model for the old UI. It is later converted to the new model. Do not use directly! Use fareRuleUi instead.
  @Input() fareDetails!: FareRuleUi;
  @Input() readonly!: boolean;
  @Input() parent!: UppComponent;

  fareRuleUi!: FareRuleUiV2;

  NAME_WITH_SPACES_VALIDATION_MESSAGES = NAME_WITH_SPACES_VALIDATION_MESSAGES;
  MAX_LENGTH_STRING_VALIDATION_MESSAGES = MAX_LENGTH_STRING_VALIDATION_MESSAGES;
  SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;
  TWO_SYMBOLS_REQUIRED = TWO_SYMBOLS_REQUIRED;

  priceRangeMinValidationMessageFunctions = {
    ...REQUIRED_MSG,
    integerNumberValidator: () =>
      $localize`:@@upp.validation.priceRange.lower.integer:Only digits without decimals are acceptable`,
    min: (params: any) =>
      $localize`:@@upp.validation.priceRange.percentage.lower.min:Value is less than minimum ${params.min}`,
    max: (params: any) => $localize`:@@upp.validation.priceRange.lower.max:Value is more than maximum ${params.max}`,
    lessThan: () => $localize`:@@upp.validation.priceRange.lowerBiggerThanUpper:Amount must be less than upper limit`
  };

  priceRangeMaxValidationMessageFunctions = {
    ...REQUIRED_MSG,
    integerNumberValidator: () =>
      $localize`:@@upp.validation.priceRange.upper.integer:Only digits without decimals are acceptable`,
    max: (params: any) => $localize`:@@upp.validation.priceRange.upper.max:Value is more than maximum ${params.max}`,
    positiveNumberValidator: () => $localize`:@@upp.validation.priceRange.positiveUpper:Enter only positive amounts`,
    moreThan: () => $localize`:@@upp.validation.priceRange.upperSmallerThanLower:Amount must be more than lower limit`
  };

  // Validation messages for both departure time window fields are the same with only difference
  // being lessThan and moreThan. If both are present in messages it will work for both fields.
  departureTimeWindowMessageFunctions = {
    ...this.priceRangeMinValidationMessageFunctions,
    moreThan: () => $localize`:@@upp.validation.priceRange.upperSmallerThanLower:Amount must be more than lower limit`
  };

  posLookupOptions!: LookupOptions;
  firstMarketLookupOptions!: LookupOptions;
  secondMarketLookupOptions!: LookupOptions;

  availablePosNames: string[] = [];
  availableMarketsNames: string[] = [];

  edifactReferenceCriteriaButtonGroupItems!: ButtonGroupItem[];
  edifactReferenceCriteriaButtonGroupMap!: { [key: string]: ButtonGroupItem };

  ndcExclusionCriteriaButtonGroupItems!: ButtonGroupItem[];
  ndcExclusionCriteriaButtonGroupMap!: { [key: string]: ButtonGroupItem };

  cabinOptions = [
    {
      value: 'M',
      label: $localize`:@@upp.fares.create.cabin.option.standardEconomy:Standard Economy`
    },
    {
      value: 'Y',
      label: $localize`:@@upp.fares.create.cabin.option.premiumEconomy:Premium Economy`
    },
    {
      value: 'W',
      label: $localize`:@@upp.fares.create.cabin.option.economy:Economy`
    },
    {
      value: 'C',
      label: $localize`:@@upp.fares.create.cabin.option.business:Business`
    },
    {
      value: 'F',
      label: $localize`:@@upp.fares.create.cabin.option.first:First`
    }
  ];
  fareTypesOptions = fareTypesOptions;

  ANY_SEGMENT_CONNECTION = 'any';
  journeyTypesOptions = [
    {
      value: 'Non stop',
      label: 'Non Stop'
    },
    {
      value: 'Direct',
      label: 'Direct'
    },
    {
      value: 'Connection2',
      label: 'Connection with 2 segments',
      parent: this.ANY_SEGMENT_CONNECTION
    },
    {
      value: 'Connection3',
      label: 'Connection with 3 segments',
      parent: this.ANY_SEGMENT_CONNECTION
    },
    {
      value: 'Connection4',
      label: 'Connection with 4 segments',
      parent: this.ANY_SEGMENT_CONNECTION
    },
    {
      value: 'Connection5',
      label: 'Connection with 5 segments',
      parent: this.ANY_SEGMENT_CONNECTION
    }
  ];

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly store: Store,
    private readonly navigationService: NavigationService,
    private readonly destroyRef: DestroyRef
  ) {
  }

  get active(): AbstractControl | null {
    return this.fareDetailsForm.get('active');
  }

  get nameControl(): AbstractControl | null {
    return this.fareDetailsForm.get('criteria.name');
  }

  get descriptionControl(): AbstractControl | null {
    return this.fareDetailsForm.get('criteria.description');
  }

  get priceRangeMinControl(): AbstractControl | null {
    return this.fareDetailsForm.get('exclusion.priceRangeMin');
  }

  get priceRangeMaxControl(): AbstractControl | null {
    return this.fareDetailsForm.get(PRICE_RANGE_MAX_PATH);
  }

  get departureTimeWindowMinControl(): AbstractControl | null {
    return this.fareDetailsForm.get('exclusion.departureTimeWindowMin');
  }

  get departureTimeWindowMaxControl(): AbstractControl | null {
    return this.fareDetailsForm.get('exclusion.departureTimeWindowMax');
  }

  journeyTypesGroupValueFn = () => ({ value: this.ANY_SEGMENT_CONNECTION, label: 'Connection from 2 to 5 segments' });

  ngOnInit(): void {
    if (this.canLookupPos()) {
      this.posLookupOptions = {
        destinationComponent: UppComponentNames.POS,
        sourceComponent: UppComponentNames.FARES,
        sourceView: this.fareRuleUi.id ? UppViewNames.MODIFY : UppViewNames.CREATE
      };
    }

    if (this.canLookupMarket()) {
      this.firstMarketLookupOptions = this.getMarketLookupOptions();
      this.secondMarketLookupOptions = this.getMarketLookupOptions();
    }

    this.subscription.add(
      this.store.pipe(select(selectAllAvailablePosRecords)).subscribe((result) => {
        if (result) {
          this.availablePosNames = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((name): name is string => name != null);
        }
      })
    );

    this.subscription.add(
      this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe((result) => {
        if (result) {
          this.availableMarketsNames = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((name): name is string => name != null);
        }
      })
    );

    this.navigationService.enableNavigation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initializeCriteriaButtonGroups();
    if (changes.fareDetails) {
      this.fareRuleUi = convertFareRuleUiToFareRuleUiV2(JSON.parse(JSON.stringify(changes.fareDetails.currentValue)));
      this.initializeForm();
    }
  }

  clearScreen(): void {
    // Keep the name in case of Update (Modify, Display)
    const name = this.parent === UppComponent.FARES_CREATE ? '' : this.fareRuleUi.criteria.name;
    const id = this.fareRuleUi.id;
    const ruleVersion = this.fareRuleUi.ruleVersion;

    this.fareRuleUi = convertFareRuleUiToFareRuleUiV2(initialFareRuleUi);
    this.fareRuleUi.criteria.name = name;
    this.fareRuleUi.id = id;
    this.fareRuleUi.ruleVersion = ruleVersion;

    this.fareDetailsForm.reset(this.fareRuleUi);

    this.initializeSections();
  }

  onEdifactReferenceCriteriaToggle(buttonGroupItem: ButtonGroupItem): void {
    const referenceFormGroup = this.fareDetailsForm.get('reference') as FormGroup;
    if (buttonGroupItem.name === 'carrier') {
      if (buttonGroupItem.active) {
        referenceFormGroup.addControl(
          'carrierCodes',
          this.formBuilder.control({ value: this.fareRuleUi?.reference?.carrierCodes, disabled: this.readonly }, [
            Validators.required,
            Validators.pattern(twoAlphaNumericRegex)
          ])
        );
      } else {
        referenceFormGroup.removeControl('carrierCodes');
      }
    }

    if (buttonGroupItem.name === 'cabin') {
      if (buttonGroupItem.active) {
        referenceFormGroup.addControl(
          'cabin',
          this.formBuilder.control({ value: this.fareRuleUi?.reference?.cabin, disabled: this.readonly }, [
            Validators.required
          ])
        );
      } else {
        referenceFormGroup.removeControl('cabin');
      }
    }

    if (buttonGroupItem.name === 'fareTypes') {
      if (buttonGroupItem.active) {
        referenceFormGroup.addControl(
          'fareTypes',
          this.formBuilder.control({ value: this.fareRuleUi?.reference?.fareTypes, disabled: this.readonly }, [
            Validators.required
          ])
        );
      } else {
        referenceFormGroup.removeControl('fareTypes');
      }
    }
  }

  onNdcExclusionCriteriaToggle(buttonGroupItem: ButtonGroupItem): void {
    const exclusionFormGroup = this.fareDetailsForm.get('exclusion') as FormGroup;
    if (buttonGroupItem.name === 'carrier') {
      if (buttonGroupItem.active) {
        exclusionFormGroup.addControl(
          'carrierCodes',
          this.formBuilder.control({ value: this.fareRuleUi?.exclusion?.carrierCodes, disabled: this.readonly }, [
            Validators.required,
            Validators.pattern(twoAlphaNumericRegex)
          ])
        );
      } else {
        exclusionFormGroup.removeControl('carrierCodes');
      }
    }

    if (buttonGroupItem.name === 'departureTimeRange') {
      this.onNdcDepartureTimeRangeToggle(buttonGroupItem);
    }

    if (buttonGroupItem.name === 'fareTypes') {
      if (buttonGroupItem.active) {
        exclusionFormGroup.addControl(
          'fareTypes',
          this.formBuilder.control({ value: this.fareRuleUi?.exclusion?.fareTypes, disabled: this.readonly }, [
            Validators.required
          ])
        );
      } else {
        exclusionFormGroup.removeControl('fareTypes');
      }
    }

    if (buttonGroupItem.name === 'journeyTypes') {
      if (buttonGroupItem.active) {
        exclusionFormGroup.addControl(
          'journeyTypes',
          this.formBuilder.control({ value: this.fareRuleUi?.exclusion?.journeyTypes, disabled: this.readonly }, [
            Validators.required
          ])
        );
      } else {
        exclusionFormGroup.removeControl('journeyTypes');
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initializeCriteriaButtonGroups(): void {
    this.edifactReferenceCriteriaButtonGroupItems = this.generateButtonGroupItemsForEdifactReferenceCriteria();
    this.edifactReferenceCriteriaButtonGroupMap = this.edifactReferenceCriteriaButtonGroupItems.reduce(
      (acc: any, item: any) => {
        acc[item.name] = item;
        return acc;
      },
      {}
    );
    this.ndcExclusionCriteriaButtonGroupItems = this.generateButtonGroupItemsForNdcExclusionCriteria();
    this.ndcExclusionCriteriaButtonGroupMap = this.ndcExclusionCriteriaButtonGroupItems.reduce(
      (acc: any, item: any) => {
        acc[item.name] = item;
        return acc;
      },
      {}
    );
  }

  private onNdcDepartureTimeRangeToggle(buttonGroupItem: ButtonGroupItem): void {
    const exclusionFormGroup = this.fareDetailsForm.get('exclusion') as FormGroup;
    if (buttonGroupItem.active) {
      exclusionFormGroup.addControl(
        'departureTimeWindowMin',
        this.formBuilder.control(
          { value: this.fareRuleUi?.exclusion?.departureTimeWindowMin, disabled: this.readonly },
          [
            Validators.required,
            integerNumberValidator,
            Validators.min(MIN_DEPARTURE_SLIDER),
            Validators.max(MAX_DEPARTURE_SLIDER),
            lessThanValidator('exclusion.departureTimeWindowMax', this.destroyRef)
          ]
        )
      );
      exclusionFormGroup.addControl(
        'departureTimeWindowMax',
        this.formBuilder.control(
          { value: this.fareRuleUi?.exclusion?.departureTimeWindowMax, disabled: this.readonly },
          [
            Validators.required,
            integerNumberValidator,
            Validators.min(MIN_DEPARTURE_SLIDER),
            Validators.max(MAX_DEPARTURE_SLIDER),
            moreThanValidator('exclusion.departureTimeWindowMin', this.destroyRef)
          ]
        )
      );
    } else {
      exclusionFormGroup.removeControl('departureTimeWindowMin');
      exclusionFormGroup.removeControl('departureTimeWindowMax');
    }
  }

  private initializeSections(): void {
    this.edifactReferenceCriteriaButtonGroupMap.carrier.active = (this.fareRuleUi?.reference?.carrierCodes?.length ?? 0) > 0;
    this.onEdifactReferenceCriteriaToggle(this.edifactReferenceCriteriaButtonGroupMap.carrier);

    this.edifactReferenceCriteriaButtonGroupMap.cabin.active = !!this.fareRuleUi?.reference?.cabin;
    this.onEdifactReferenceCriteriaToggle(this.edifactReferenceCriteriaButtonGroupMap.cabin);

    this.edifactReferenceCriteriaButtonGroupMap.fareTypes.active = this.fareRuleUi?.reference?.fareTypes?.length > 0;
    this.onEdifactReferenceCriteriaToggle(this.edifactReferenceCriteriaButtonGroupMap.fareTypes);

    this.ndcExclusionCriteriaButtonGroupMap.carrier.active = (this.fareRuleUi?.exclusion?.carrierCodes?.length ?? 0) > 0;
    this.onNdcExclusionCriteriaToggle(this.ndcExclusionCriteriaButtonGroupMap.carrier);

    this.ndcExclusionCriteriaButtonGroupMap.departureTimeRange.active =
      this.fareRuleUi?.exclusion?.departureTimeWindowMax != null;
    this.onNdcExclusionCriteriaToggle(this.ndcExclusionCriteriaButtonGroupMap.departureTimeRange);

    this.ndcExclusionCriteriaButtonGroupMap.fareTypes.active = this.fareRuleUi?.exclusion?.fareTypes?.length > 0;
    this.onNdcExclusionCriteriaToggle(this.ndcExclusionCriteriaButtonGroupMap.fareTypes);

    this.ndcExclusionCriteriaButtonGroupMap.journeyTypes.active = this.fareRuleUi?.exclusion?.journeyTypes?.length > 0;
    this.onNdcExclusionCriteriaToggle(this.ndcExclusionCriteriaButtonGroupMap.journeyTypes);
  }

  private getMarketLookupOptions(): LookupOptions {
    return {
      ...MARKET_LOOKUP_OPTIONS,
      sourceView: this.fareRuleUi.id ? UppViewNames.MODIFY : UppViewNames.CREATE
    };
  }

  private initializeForm(): void {
    if (this.fareDetailsForm) {
      this.fareDetailsForm.setControl('id', this.formBuilder.control(this.fareRuleUi?.id));
      this.fareDetailsForm.setControl(
        'active',
        this.formBuilder.control({ value: this.fareRuleUi?.active, disabled: this.readonly })
      );
      this.fareDetailsForm.setControl('ruleVersion', this.formBuilder.control(this.fareRuleUi?.ruleVersion));
      this.fareDetailsForm.setControl(
        'organization',
        this.formBuilder.control(this.userDetailsService.getLssOrganization())
      );

      this.fareDetailsForm.setControl(
        'criteria',
        this.formBuilder.group({
          name: [
            {
              value: this.fareRuleUi?.criteria?.name,
              // Disabled until implemented by backend. Should be disabled in modify mode.
              // Use this when backend is ready: this.parent !== UppComponent.FARES_CREATE
              disabled: true
            },
            [Validators.required, Validators.maxLength(30), Validators.pattern(nameRegex)]
          ],
          description: [{ value: this.fareRuleUi?.criteria?.description, disabled: true }, [Validators.maxLength(128)]],
          pointOfSale: [
            { value: this.fareRuleUi?.criteria?.pointOfSale, disabled: this.readonly },
            [Validators.required]
          ],
          marketPair: this.createMarketPairFormGroup(this.fareRuleUi?.criteria?.marketPair)
        })
      );

      this.fareDetailsForm.setControl('reference', this.formBuilder.group({}));

      this.fareDetailsForm.setControl(
        'exclusion',
        this.formBuilder.group({
          priceRangeType: [
            { value: this.fareRuleUi?.exclusion?.priceRangeType, disabled: this.readonly },
            [Validators.required]
          ],
          priceRangeMin: [
            { value: this.fareRuleUi?.exclusion?.priceRangeMin, disabled: this.readonly },
            [
              Validators.required,
              Validators.min(-100),
              Validators.max(500),
              integerNumberValidator,
              lessThanValidator(PRICE_RANGE_MAX_PATH, this.destroyRef)
            ]
          ],
          priceRangeMax: [
            { value: this.fareRuleUi?.exclusion?.priceRangeMax, disabled: this.readonly },
            [
              Validators.required,
              Validators.max(5000),
              integerNumberValidator,
              positiveNumberValidator,
              moreThanValidator('exclusion.priceRangeMin', this.destroyRef)
            ]
          ]
        })
      );

      this.subscription.add(
        this.fareDetailsForm.get('exclusion.priceRangeType')?.valueChanges.subscribe((newValue) => {
          const priceRangeMinValidators = [
            Validators.required,
            Validators.max(500),
            integerNumberValidator,
            lessThanValidator(PRICE_RANGE_MAX_PATH, this.destroyRef)
          ];
          if (newValue === PriceRangeType.RATE) {
            priceRangeMinValidators.push(Validators.min(-100));
          } else {
            priceRangeMinValidators.push(Validators.min(-500));
          }

          this.priceRangeMinControl?.setValidators(priceRangeMinValidators);
          this.priceRangeMinControl?.updateValueAndValidity();
        })
      );
    }

    this.initializeSections();
  }

  private createMarketPairFormGroup(pair: MarketPair): UntypedFormGroup {
    return this.formBuilder.group({
      firstMarketName: [{ value: pair?.firstMarketName, disabled: this.readonly }, [Validators.required]],
      secondMarketName: [{ value: pair?.secondMarketName, disabled: this.readonly }, [Validators.required]]
    });
  }

  private canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  private canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }

  private canLookup(permisionName: string): boolean {
    if (this.readonly) {
      return false;
    }
    const permissions = this.userDetailsService.loggedInUser?.permissions;

    if (!permissions) {
      return false;
    }

    return permissions.filter((p) => p === permisionName).length > 0;
  }

  private generateButtonGroupItemsForEdifactReferenceCriteria(): ButtonGroupItem[] {
    return [
      {
        name: 'carrier',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.carrierCode.label:Carrier code`
      },
      {
        name: 'cabin',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.cabin.label:Cabin`
      },
      {
        name: 'fareTypes',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.fareTypes.label:Fare types`
      }
    ];
  }

  private generateButtonGroupItemsForNdcExclusionCriteria(): ButtonGroupItem[] {
    return [
      {
        name: 'carrier',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.carrierCode.label:Carrier code`
      },
      {
        name: 'departureTimeRange',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.departureTimeRange.label:Departure time range`
      },
      {
        name: 'fareTypes',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.fareTypes.label:Fare types`
      },
      {
        name: 'journeyTypes',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.journeyTypes.label:Journey type`
      }
    ];
  }
}
