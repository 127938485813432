import { UntypedFormGroup } from '@angular/forms';
import { FamilyServiceElements } from '.';

export const validateFamily = (familyForm: UntypedFormGroup): boolean => {
  if (!(familyForm && familyForm.get(FamilyServiceElements.NAME))) {
    return false;
  }
  const hotelType: string = familyForm.get(FamilyServiceElements.HOTEL_TYPE)?.value;
  let array: string[];
  if (hotelType === FamilyServiceElements.CHAINS) {
    array = familyForm.get(FamilyServiceElements.CHAINS)?.value;
  } else {
    array = familyForm.get(FamilyServiceElements.PROPERTY_CODES)?.value;
  }
  if (array === undefined || array.length < 1) {
    return false;
  }
  const nameValue: string = familyForm.get(FamilyServiceElements.NAME)?.value;
  if (nameValue && nameValue.length > 0) {
    return familyForm.valid;
  }
  return false;
};
