import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginService } from '../../service/login.service';
import { CORE_ACTION, CoreActions } from './actions';

@Injectable()
export class Effects {

  logOutAfterSessionExpired: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(CORE_ACTION.USER_SESSION_EXPIRED),
      tap(() => {
        this.loginService.permissionFailedLogout();
        window.location.reload();
      })
    ),
    { dispatch: false }
  );

  constructor(private readonly actions: Actions, private readonly loginService: LoginService) {}
}
