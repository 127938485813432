import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { UserSessionExpired } from '../core/actions';
import { FaresService } from '../../service/fares.service';
import {
  DeleteFareRuleClearStoreAction,
  DeleteFareRuleFromListAction,
  DeleteFareRuleSearchNotificationAction,
  FaresActions,
  FARES_ACTION,
  FinishFareRuleCreationAction,
  FinishFareUpdateAction,
  SetFareRuleListAction
} from './fares-action';
import { FareRuleUi } from '../../fares/model/fare-rule-ui';
import { TYPE_ERROR } from '../../service/model';
import { PriceRangeType } from '../../fares/model/fare-service-elements';
import { createFareRuleError } from '../../service/handler/fare-response-handler';

@Injectable()
export class FaresEffects {

  createFare: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(FARES_ACTION.CREATE_FARE_RULE),
      mergeMap(action => {
        const { request } = (action as FaresActions).payload;
        return this.fareService.createFareRule(request).pipe(
          map(fareRuleUi => new FinishFareRuleCreationAction({ fareRule: fareRuleUi })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            if (error.status === 400) {
              return of(new FinishFareRuleCreationAction({ fareRule: createFareRuleError(error) }));
            }
            return of(new FinishFareRuleCreationAction({ fareRule: createErrorRule(error) }));
          })
        );
      })
    ));

  searchFareRules: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FARES_ACTION.SEARCH_FARE_RULE),
      mergeMap(action => {
        const { request } = (action as FaresActions).payload;
        return this.fareService.searchFareRules(request).pipe(
          switchMap(fareRules => [
            new DeleteFareRuleSearchNotificationAction({}),
            new SetFareRuleListAction({ fareRules })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new SetFareRuleListAction({ fareRules: [createErrorRule(error)] }));
          })
        );
      })
    )
  );

  deleteFareRule: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FARES_ACTION.DELETE_FARE_RULE),
      mergeMap(action => {
        const { request } = (action as FaresActions).payload;
        return this.fareService.deleteFareRule(request).pipe(
          switchMap(deletedRule => [
            new DeleteFareRuleSearchNotificationAction({}),
            new DeleteFareRuleClearStoreAction(request),
            new DeleteFareRuleFromListAction({ deletedRule })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new DeleteFareRuleFromListAction({ deletedRule: createErrorRule(error) }));
          })
        );
      })
    )
  );

  updateFareRule: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FARES_ACTION.UPDATE_FARE),
      mergeMap(action => {
        const { request } = (action as FaresActions).payload;
        return this.fareService.updateFareRule(request).pipe(
          map(fareRule => new FinishFareUpdateAction({ updatedFareRule: fareRule })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishFareUpdateAction({ updatedFareRule: createErrorRule(error) }));
          })
        );
      })
    )
  );

  constructor(private readonly actions: Actions,
    private readonly fareService: FaresService) { }
}

const createErrorRule = (error: Error): FareRuleUi => ({
    statusType: TYPE_ERROR,
    statusNotification: { error: [error.message] },
    id: 'fareRuleError',
    rule: {
      name: '',
      organization: '',
      description: '',
      active: false
    },
    reference: {
      pointOfSaleNames: []
    },
    exclusion: {
      priceRangeType: PriceRangeType.RATE,
      priceDifferenceMin: 0,
      priceDifferenceMax: 0
    }
});

