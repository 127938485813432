export class PosMarketRegions {
  private static readonly regions: Map<string, string> = new Map([
    ['ATLAN', 'ATLANTIC'],
    ['ASIA', 'ASIA REGION'],
    ['AFRIC', 'AFRICA'],
    ['AUSTL', 'AUSTRALIA'],
    ['CAMER', 'CENTRAL AMERICA'],
    ['CARIB', 'CARIBBEAN SUB AREA'],
    ['EEURO', 'EASTERN EUROPE'],
    ['EURAS', 'EURO ASIA'],
    ['EUROP', 'EUROPE'],
    ['IOCEA', 'INDIAN OCEAN'],
    ['MEAST', 'MIDDLE EAST'],
    ['NAMER', 'NORTH AMERICA'],
    ['PACIF', 'PACIFIC'],
    ['SAMER', 'SOUTH AMERICA'],
    ['SEASI', 'SOUTH EAST ASIA']
  ]);

  static getRegionList(): Region[] {
    const regionsList: Region[] = [];
    this.regions.forEach((value, key) => {
      regionsList.push(new Region(key, value));
    });
    return regionsList;
  }

  static getRegionCodesForDescriptions(descriptions: string[] | undefined): string[] | undefined {
    if (!descriptions) {
      return undefined;
    }

    const codeValues: string[] = [];
    descriptions.forEach(description => {
      this.regions.forEach((value, key) => {
        if (value === description) {
          codeValues.push(key);
        }
      });
    });

    return codeValues;
  }

  static getDescriptionsForRegionCodes(codes: string[]): string[] | undefined {
    if (!codes) {
      return;
    }
    const descriptionValues: string[] = [];
    codes.forEach(code => {
      const description = this.regions.get(code);
      if (description && description !== null) {
        descriptionValues.push(description);
      }
    });
    return descriptionValues;
  }
}

export class Region {
  readonly code: string;
  readonly description: string;

  constructor(code: string, description: string) {
    this.code = code;
    this.description = description;
  }
}
