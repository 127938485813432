import { createFeatureSelector, createSelector } from '@ngrx/store';
import { POS_FEATURE, PosState } from './pos-state';

export const selectPos = createFeatureSelector<PosState>(POS_FEATURE);

export const selectPosRecords = createSelector(selectPos, (state: PosState) => state?.posRecords);

export const selectAllAvailablePosRecords = createSelector(selectPos, (state: PosState) => state?.availablePosRecords);

export const selectPosSearchSending = createSelector(selectPos, (state: PosState) => {
  if (state && state.pointOfSale && state.pointOfSale.search) {
    return state.pointOfSale.search.sending;
  }
  return undefined;
});

export const selectPosSearchValue = createSelector(selectPos, (state: PosState) => state.pointOfSale.search?.value);

export const selectPosDeleteSending = createSelector(selectPos, (state: PosState) => {
  if (state && state.pointOfSale && state.pointOfSale.delete) {
    return state.pointOfSale.delete.sending;
  }
  return undefined;
});

export const selectPosCreateValue = createSelector(selectPos, (state: PosState) => state.pointOfSale.create.value);

export const selectPosCreateSending = createSelector(selectPos, (state: PosState) => state.pointOfSale.create.sending);

export const selectPosCreateNotification = createSelector(selectPos, (state: PosState) => {
  if (state.notification && state.notification.create) {
    return state.notification.create;
  }
  return undefined;
});

export const selectPosModifyValue = createSelector(selectPos, (state: PosState) => state.pointOfSale.modify.value);

export const selectPosModifySending = createSelector(selectPos, (state: PosState) => state.pointOfSale.modify.sending);

export const selectPosModifyActivated = createSelector(selectPos, (state: PosState) => {
  if (state.pointOfSale.modify.active) {
    return state.pointOfSale.modify.active;
  }
  return false;
});

export const selectPosModifyNotification = createSelector(selectPos, (state: PosState) => {
  if (state.notification && state.notification.modify) {
    return state.notification.modify;
  }
  return undefined;
});

export const selectPosDisplayValue = createSelector(selectPos, (state: PosState) =>
  state && state.pointOfSale ? state.pointOfSale.display.value : undefined
);

export const selectPosDisplaySending = createSelector(selectPos, (state: PosState) =>
  state && state.pointOfSale ? state.pointOfSale.display.sending : undefined
);

export const selectPosDisplayNotification = createSelector(selectPos, (state: PosState) => {
  if (state && state.notification && state.notification.display) {
    return state.notification.display;
  }
  return undefined;
});

export const selectPosSearchNotification = createSelector(selectPos, (state: PosState) => {
  if (state.notification && state.notification.search) {
    return state.notification.search;
  }
  return undefined;
});

export const selectPosDisplayActivated = createSelector(selectPos, (state: PosState) => {
  if (state && state.pointOfSale && state.pointOfSale.display && state.pointOfSale.display.active) {
    return state.pointOfSale.display.active;
  }
  return false;
});

export const selectSelectedPosNames = createSelector(selectPos, (state: PosState) => state?.selectedPosNames);
