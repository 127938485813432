import { Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export const PERMISSION_VIEW_POS = 'VIEW_POINT_OF_SALE';
export const PERMISSION_VIEW_HOTEL = 'VIEW_HOTEL_RULE';
export const PERMISSION_VIEW_MARKET = 'VIEW_MARKET';
export const PERMISSION_VIEW_FLIGHT = 'VIEW_JOURNEY_RULE';
export const PERMISSION_VIEW_FAMILY = 'VIEW_FAMILY';
export const PERMISSION_MANAGE_FAMILY = 'MANAGE_FAMILY';
export const PERMISSION_MANAGE_MARKET = 'MANAGE_MARKET';
export const PERMISSION_MANAGE_HOTEL = 'MANAGE_HOTEL_RULE';
export const PERMISSION_MANAGE_POS = 'MANAGE_POINT_OF_SALE';
export const PERMISSION_MANAGE_FLIGHT = 'MANAGE_JOURNEY_RULE';
export const PERMISSION_VIEW_FARES = 'VIEW_ADPR_RULE_DYCTEX';
export const PERMISSION_MANAGE_FARES = 'MANAGE_ADPR_RULE_DYCTEX';
export const PERMISSION_MANAGE_NDC_RULE = 'MANAGE_NDC_RULE';
export const PERMISSION_MANAGE_EDIFACT_RULE = 'MANAGE_EDIFACT_RULE';

type UppJwtPayload = JwtPayload & {
  permissions_v2?: { UPP: string[] }
}

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  constructor() {}

  extractUppPermissions(idToken: string): string[] {
    return jwtDecode<UppJwtPayload>(idToken).permissions_v2?.UPP ?? [];
  }

  checkLoginPermission(permissions: string[]): boolean {
    if (!permissions || permissions.length === 0) {
      return false;
    }
    return (
      permissions.includes(PERMISSION_VIEW_POS) ||
      permissions.includes(PERMISSION_VIEW_MARKET) ||
      permissions.includes(PERMISSION_VIEW_HOTEL) ||
      permissions.includes(PERMISSION_VIEW_FLIGHT) ||
      permissions.includes(PERMISSION_VIEW_FAMILY) ||
      permissions.includes(PERMISSION_VIEW_FARES)
    );
  }
}
