import { ValidatorFn, FormArray, AbstractControl } from '@angular/forms';

export const marketPairsUniqueValidator = (): ValidatorFn => ((control1: AbstractControl) => {
  const marketPairsArray = control1.parent as FormArray;
  if (marketPairsArray?.controls) {
    const control1firstMarketName = control1.get('firstMarketName')?.value as string;
    const control1secondMarketName = control1.get('secondMarketName')?.value as string;

    // Compare only if marketNames are arrays with at least one element
    if (control1firstMarketName?.length > 0 && control1secondMarketName?.length > 0) {
      const duplicate = marketPairsArray.controls
      // Skip comparing with self
      .filter(control2 => control2 !== control1)
      .some(control2 => {
        const control2firstMarketName = control2.get('firstMarketName')?.value as string;
        const control2secondMarketName = control2.get('secondMarketName')?.value as string;

        return (
          control2firstMarketName?.length > 0 &&
          control2secondMarketName?.length > 0 &&
          control1firstMarketName === control2firstMarketName &&
          control1secondMarketName === control2secondMarketName
        );
      });

      if (duplicate) {
        // Touch in order to display error message
        control1.markAsTouched();
        return { duplicateMarketPair: true };
      }
    }
  }
  return null;
});
