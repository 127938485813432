import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormValidatorsModule } from '../components/form-validators.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DfSelectModule } from '@design-factory/design-factory';
import { ComponentsModule } from '../components/components.module';
import { PointsOfSaleRoutingModule } from './points-of-sale-routing.module';
import { PointsOfSaleAppComponent } from './points-of-sale-app.component';
import { PosSearchComponent } from './pos-search/pos-search.component';
import { PosDisplayComponent } from './pos-display/pos-display.component';
import { PosCreateComponent } from './pos-create/pos-create.component';
import { PosModifyComponent } from './pos-modify/pos-modify.component';
import { PosSearchCriteriaComponent } from './pos-search/pos-search-criteria/pos-search-criteria.component';
import { PosDetailsComponent } from './pos-details/pos-details.component';
import { BasicSearchResultTableComponent } from '../base/search/basic-search-result-table/basic-search-result-table.component';
import { PipesModule } from '../core/util/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    PointsOfSaleRoutingModule,
    ComponentsModule,
    FormValidatorsModule,
    DfSelectModule,
    PipesModule
  ],
  declarations: [
    PointsOfSaleAppComponent,
    PosSearchComponent,
    PosSearchCriteriaComponent,
    BasicSearchResultTableComponent,
    PosDisplayComponent,
    PosCreateComponent,
    PosDetailsComponent,
    PosModifyComponent
  ],
  exports: [PosSearchComponent, PosSearchCriteriaComponent, PosDisplayComponent, BasicSearchResultTableComponent]
})
export class PointsOfSaleModule {}
