import {
  PosCommonResponse,
  PosMarketSearchResponse,
  PosAlert,
  MarketSearchResponse
} from '../model/resp/pos-market-response';
import { PosMarketRecord, TYPE_SUCCESS, TYPE_WARNING, TYPE_ERROR } from '../model/pos/pos-market-record';
import { DEFAULT_SUCCESS, UppNotification } from '../../model/notification';
import { PosMarketDetail } from '../model/pos/pos-market-detail';
import { BaseResponse } from '../../base/model/base-response';
import { buildErrorOrWarning } from './response-handler';

const SUCCESS_STATUS = 'success';
const WARNING_STATUS = 'warning';
const FAILED_STATUS = 'error';

const MISSING_RESULT_STATUS = 'Result status could not be retrieved.';
const UNKNOWN_RESULT_STATUS = 'An unknown status has been retrieved.';

export const successDeletePosUi: PosMarketRecord = {
  id: '',
  statusType: TYPE_SUCCESS,
  statusNotification: { success: [DEFAULT_SUCCESS] }
};

export const warningPosUi: PosMarketRecord = {
  statusType: TYPE_WARNING,
  id: 'posWarning',
  statusMessage: [],
  statusNotification: { warning: [UNKNOWN_RESULT_STATUS] }
};

export const errorPosUi: PosMarketRecord = {
  statusType: TYPE_ERROR,
  id: 'posError',
  statusMessage: [],
  statusNotification: { error: [MISSING_RESULT_STATUS] }
};

export const buildPosRecordList = (searchResult: PosMarketSearchResponse): PosMarketRecord[] => {
  const posMarketRecords: PosMarketRecord[] = [];

  if (searchResult.status.state !== SUCCESS_STATUS) {
    return [buildFailedResponse(searchResult)];
  }

  searchResult.pointOfSales?.forEach(element => {
    posMarketRecords.push({
      id: element.id,
      statusType: TYPE_SUCCESS,
      posMarketDetail: new PosMarketDetail(element)
    });
  });

  return posMarketRecords;
};

export const buildMarketRecordList = (searchResult: MarketSearchResponse): PosMarketRecord[] => {
  if (searchResult.status.state !== SUCCESS_STATUS) {
    return [buildFailedResponse(searchResult)];
  }

  const marketRecords: PosMarketRecord[] = [];

  searchResult.markets?.forEach(element => {
    marketRecords.push({
      id: element.id,
      statusType: TYPE_SUCCESS,
      posMarketDetail: new PosMarketDetail(element)
    });
  });

  return marketRecords;
};

export const buildPosMarketDeleteResult = (result: PosCommonResponse, recordId: string | undefined) => {
  if (!recordId || result.status.state !== SUCCESS_STATUS) {
    return buildFailedResponse(result);
  }

  return {
    ...successDeletePosUi,
    id: recordId
  };
};

export const buildPosResponseRecord = (result: PosCommonResponse, addLinkData = false): PosMarketRecord => {
  if (result.status.state === SUCCESS_STATUS) {
    return createSuccessRecord(result, addLinkData);
  }
  return buildFailedResponse(result);
};

const buildFailedResponse = (response: BaseResponse): PosMarketRecord => {
  const result = buildErrorOrWarning(warningPosUi, errorPosUi, response) as PosMarketRecord;

  let msg: string[] = [];

  switch (response.status.state) {
    case WARNING_STATUS:
      msg = extractMessages(response.status.warnings);
      break;
    case FAILED_STATUS:
      msg = extractMessages(response.status.errors);
  }

  result.statusMessage = msg;
  return result;
};

const extractMessages = (alerts: PosAlert[] | undefined): string[] => {
  const messages: string[] = [];

  if (!alerts) {
    return messages;
  }

  for (const alert of alerts) {
    if (alert.message) {
      messages.push(alert.message);
    } else {
      messages.push('An unspecified error occurred');
    }
  }
  return messages;
};

const createSuccessRecord = (result: PosCommonResponse, addLinkData: boolean): PosMarketRecord => {
  const details = result.pointOfSale === undefined ? result.market : result.pointOfSale;

  const notification: UppNotification = {
    success: [DEFAULT_SUCCESS]
  };

  if (!details?.id) {
    throw new Error('Market record id missing');
  }

  if (addLinkData) {
    notification.links = [
      {
        id: details.id,
        name: details.name
      }
    ];
  }

  return {
    id: details.id,
    statusType: TYPE_SUCCESS,
    posMarketDetail: new PosMarketDetail(details),
    statusNotification: notification
  };
};
