import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  PosMarketDetail,
  posMarketDetailEquals,
  PosMarketServiceData,
  PosMarketUpdateRequest,
  UppComponent
} from '../../service/model';
import { Observable, Subscription } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationService } from '../../service/core/navigation.service';
import { select, Store } from '@ngrx/store';
import {
  selectMarketsModifyNotification,
  selectMarketsModifySending,
  selectMarketsModifyValue, selectMarketsRecords
} from '../../store/markets/markets-selector';
import {
  CancelMarketsModificationAction,
  DeleteMarketsModifyNotificationAction,
  SetMarketsModifyValueAction,
  UpdateMarketsAction
} from '../../store/markets/markets-actions';
import { UserDetailsService } from '../../service/user-details.service';
import { FormComponent } from '../../model/FormComponent';
import { SOURCE_VIEW_DISPLAY, SOURCE_VIEW_QUERY_PARAM_KEY } from '../../service/core/navigation-constants';

@Component({
  selector: 'ama-ng-upp-markets-modify',
  templateUrl: './markets-modify.component.html',
  styleUrls: ['./markets-modify.component.scss']
})
export class MarketsModifyComponent implements OnInit, OnDestroy, FormComponent {
  marketDetails: PosMarketDetail = { organization: '', name: '' };
  notification$: Observable<UppNotification>;

  mainMessages: NotificationMessages;
  sendingStatus: boolean;
  activated: boolean;

  marketForm: UntypedFormGroup;
  uppComponent = UppComponent.MARKETS_MODIFY;
  readonly = false;
  sourceView: string;

  private subscriptions: Subscription[] = [];
  private lastSavedMarket: PosMarketDetail;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.store.pipe(select(selectMarketsModifySending)).subscribe(sending => (this.sendingStatus = sending));
    this.store.pipe(select(selectMarketsModifyValue)).subscribe(value => (this.lastSavedMarket = this.marketDetails = value));
    this.notification$ = this.store.pipe(select(selectMarketsModifyNotification));
    this.mainMessages = {
      error: $localize`:@@upp.markets.modify.mainErrorText:Modification failed due to the following errors:`,
      warning: $localize`:@@upp.markets.modify.mainWarningText:Warnings were generated during the modification process:`,
      success: $localize`:@@upp.markets.modify.mainSuccessText:The modification process succeeded.`
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('Markets Modification');
    this.navigationService.activateMarkets();
    this.createAndLoadForm();
    this.route.queryParams.subscribe((params: Params) => {
      this.sourceView = params[SOURCE_VIEW_QUERY_PARAM_KEY];
    });
  }

  createAndLoadForm() {
    this.marketForm = this.formBuilder.group({});
    if (this.marketDetails) {
      this.marketForm.patchValue(this.marketDetails);
    }
  }

  cancelModification() {
    const id = this.marketDetails.id;
    this.store.dispatch(new CancelMarketsModificationAction({}));
    if (this.sourceView === SOURCE_VIEW_DISPLAY) {
      this.router.navigate(['markets/display/' + id]); // NOSONAR
    } else {
      this.router.navigate(['markets/search']); // NOSONAR
    }
  }

  saveModification() {
    this.store.dispatch(new UpdateMarketsAction({ request: this.createRequest() }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(
      new SetMarketsModifyValueAction({
        value: this.marketForm.getRawValue()
      })
    );
  }

  closeNotification() {
    this.store.dispatch(new DeleteMarketsModifyNotificationAction({}));
  }

  hasUnsavedChanges(): boolean {
    return !posMarketDetailEquals(this.lastSavedMarket, this.marketForm.value);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private createRequest(): PosMarketUpdateRequest {
    let marketData;
    if (this.userDetailsService.loggedInUser.partitions) {
      marketData = new PosMarketServiceData(
        this.marketForm.getRawValue(),
        null,
        this.userDetailsService.loggedInUser.selectedPartition
      );
    } else {
      marketData = new PosMarketServiceData(
        this.marketForm.getRawValue(),
        this.userDetailsService.getLssOrganization(),
        null
      );
    }
    if (this.marketForm.get('version').value) {
      marketData.version = this.marketForm.get('version').value;
    }

    return {
      version: '1.0',
      market: marketData
    };
  }
}
