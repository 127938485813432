import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UppComponent } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  HotelRuleCreateRequest,
  HotelRuleUi,
  convertToHotelRule,
  initialHotelRuleUi,
  isHotelRuleUiEquals
} from '../model';
import {
  CreateHotelRuleAction,
  SetCreateHotelRuleValueAction,
  DeleteHotelRuleCreateNotificationAction,
  ActivateCreatedHotelRuleDisplayAction
} from '../../store/hotel/hotels-action';
import {
  selectHotelRuleCreateValue,
  selectHotelRuleCreateNotification,
  selectHotelRuleCreateSending
} from '../../store/hotel/hotels-selector';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UserDetailsService } from '../../service/user-details.service';
import { FormComponent } from '../../model/FormComponent';

@Component({
  selector: 'ama-ng-upp-hotel-create',
  templateUrl: './hotels-create.component.html'
})
export class HotelsCreateComponent implements OnInit, FormComponent {
  notification$: Observable<UppNotification>;
  mainMessages: NotificationMessages;

  hotelRule: HotelRuleUi = initialHotelRuleUi;
  hotelForm: UntypedFormGroup;
  readonly = false;
  sendingStatus: boolean;

  uppComponent = UppComponent.HOTELS_CREATE;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly navigationService: NavigationService,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.notification$ = this.store.pipe(select(selectHotelRuleCreateNotification));
    this.store.pipe(select(selectHotelRuleCreateSending)).subscribe(sending => (this.sendingStatus = sending));
    this.mainMessages = {
      error: $localize`:@@upp.hotels.create.mainErrorText:Creation of hotel rule failed due to the following errors:`,
      warning: $localize`:@@upp.hotels.create.mainWarningText:Warnings were generated during the creation process of hotel rule:`,
      success: $localize`:@@upp.hotels.create.mainSuccessText:Well done! You get this message about your successful Hotel Rule creation`,
      linkSuccessText: $localize`:@@upp.hotels.create.linkSuccessText:Display new Hotel Rule: `
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.hotels.create.navigationTitle:Hotel Rule Creation`);
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.hotelForm = this.formBuilder.group({});
    this.store.pipe(select(selectHotelRuleCreateValue)).subscribe(rule => {
      if (rule) {
        this.hotelRule = rule;
        this.hotelForm.reset();
      }
    });
  }

  saveData() {
    const request = this.createRequest();
    request.rule = this.userDetailsService.assignEntity(request.rule);
    this.storeUnsavedChanges();
    this.store.dispatch(new CreateHotelRuleAction({ request }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetCreateHotelRuleValueAction({ value: this.hotelForm.value }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteHotelRuleCreateNotificationAction({}));
  }

  showHotelRuleInDisplay() {
    this.store.dispatch(new ActivateCreatedHotelRuleDisplayAction({}));
    this.router.navigate(['hotels/display']);
  }

  saveButtonEnabled(): boolean {
    if (!(this.hotelForm && this.hotelForm.get('name'))) {
      return false;
    }
    const nameValue: string = this.hotelForm.get('name').value;
    if (nameValue && nameValue.length > 0) {
      return this.hotelForm.valid;
    }
    return false;
  }

  createRequest(): HotelRuleCreateRequest {
    return {
      version: '1.0',
      rule: convertToHotelRule(this.hotelForm.value)
    };
  }

  hasUnsavedChanges(): boolean {
    return !isHotelRuleUiEquals(initialHotelRuleUi, this.hotelForm.value as HotelRuleUi);
  }
}
