import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FaresModifyComponent } from '../../fares/fares-modify/fares-modify.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFareModifyGuard  {
  canDeactivate(component: FaresModifyComponent): Observable<boolean> | boolean {
    component.storeUnsavedChanges();
    return true;
  }
}
