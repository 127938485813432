export enum RouteURL {
  familyHotelSearch = '/families/hotel/search',
  familyHotelDisplay = '/families/hotel/display',
  familyHotelModify = '/families/hotel/modify',
  familyHotelCreate = '/families/hotel/create',
  familyAirSearch = '/families/air/search',
  familyAirDisplay = '/families/air/display',
  familyAirModify = '/families/air/modify',
  familyAirCreate = '/families/air/create',
}
