import { Injectable } from '@angular/core';
import { FormComponent } from '../model/FormComponent';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectPartitionDestination } from '../store/navigation/navigation-selector';
import { take } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { DfModalService } from '@design-factory/design-factory';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(
      private readonly store: Store,
      private readonly modalService: DfModalService,
      private readonly userDetailsService: UserDetailsService
  ) { }

  canDeactivate(component: FormComponent): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.store.pipe(select(selectPartitionDestination)).pipe(take(1)).subscribe(partition => {
        if (partition
            && partition !== this.userDetailsService.loggedInUser?.selectedPartition
            && component.hasUnsavedChanges()) {
          const modal = this.modalService.open(ConfirmationModalComponent);
          modal.componentInstance.targetPartition = partition;
          modal.result.then((action) => obs.next(action === 'CONFIRM'))
              .catch(error => obs.next(false));
        } else {
          component.storeUnsavedChanges();
          obs.next(true);
        }
      });
    });
  }
}
