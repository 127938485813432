import { DisplayableField } from '../../base/search/basic-search-result-table/basic-search-result-table.component';

export const fareDisplayableFields: DisplayableField[] = [
    {
        value: 'pointOfSaleNames',
        label: 'POS'
    },
    {
        value: 'originNames',
        label: 'Origin Market'
    },
    {
        value: 'destinationNames',
        label: 'Destination Market'
    },
    {
        value: 'actionType',
        label: 'Action Type'
    },
    {
        value: 'description',
        label: 'Description'
    },
    {
        value: 'ruleStatusActive',
        label: 'Rule Status'
    }
];

export const dynamicExclusionDisplayableFields: DisplayableField[] = [
    {
        value: 'pointOfSaleNames',
        label: 'Point of sale'
    },
    {
        value: 'originNames',
        label: 'First market'
    },
    {
        value: 'destinationNames',
        label: 'Second market'
    },
    {
        value: 'description',
        label: 'Description'
    },
    {
        value: 'ruleStatusActive',
        label: 'Rule status'
    }
];
