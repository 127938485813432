<div>
  <div class="modal-header no-border-bottom">
    <h2 class="modal-title" i18n="@@upp.global.text.confirmationNeeded">Confirmation needed</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true" class="fa-times"></span>
    </button>
  </div>
  <div class="modal-body df-info-modal">
    <div class="text-center">
      <span aria-hidden="true" class="alert-modal-icon fa-exclamation-triangle"> </span>
    </div>
    <p i18n="@@upp.global.text.dataLostWarning">
      <br /><strong>Switch to {{ targetPartition }} ?</strong><br />
      <br />You will lose all unsaved changes.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('CANCEL')">{{ cancelButton }}</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close('CONFIRM')">{{ switchButton }}</button>
  </div>
</div>
