import { PosMarketDetailIncludeExcludeStructure } from './pos-market-details-include-exclude';
import { PosMarketRegions } from './pos-market-regions';

export class PosMarketServiceIncludeExcludeStructure {
  location: {
    world?: boolean;
    regions?: string[];
    countries?: string[];
    cities?: string[];
    airports?: string[];
  };
  level?: {
    officeIds?: string[];
    corporateCodesAndQualifiers?: string[];
  };

  constructor(formInEx: PosMarketDetailIncludeExcludeStructure) {
    let worldFlag: boolean | undefined;
    if (formInEx.world !== undefined && formInEx.world === true) {
      worldFlag = true;
      this.location = { world: worldFlag };
      return;
    } else {
      worldFlag = undefined;
    }

    this.location = {
      world: worldFlag,
      regions: this.getRegionCodes(formInEx.regions),
      countries: this.getValidArrayOrUndefined(formInEx.countries),
      cities: this.getValidArrayOrUndefined(formInEx.cities),
      airports: this.getValidArrayOrUndefined(formInEx.airports)
    };
    if (
      this.isValidArray(formInEx.officeIds) ||
      this.isValidArray(formInEx.corporateCodesAndQualifiers)
    ) {
      this.level = {
        officeIds: this.getValidArrayOrUndefined(formInEx.officeIds),
        corporateCodesAndQualifiers: this.getValidArrayOrUndefined(formInEx.corporateCodesAndQualifiers)
      };
    }
  }

  getRegionCodes(values: string[] | undefined): string[] | undefined {
    if (!this.isValidArray(values)) {
      return;
    }
    return PosMarketRegions.getRegionCodesForDescriptions(values);
  }

  getValidArrayOrUndefined(value: string[] | undefined): string[] | undefined {
    if (value && value.length > 0) {
      return value;
    }
  }

  isValidArray(value: string[] | undefined): boolean {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }
}
