import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Card } from '../model/card';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html'
})
export class CardComponent implements OnInit {
  @Input() card!: Card;
  @Input() percentageOfDeck!: number;

  @HostBinding('style.flex') flex!: string;

  constructor() {}

  ngOnInit(): void {
    this.flex = `0 ${this.percentageOfDeck}%`;
  }
}
