<div *ngIf="this.notification && this.notification.success && this.notification.success.length > 0">
  <ngb-alert insert-alert-icon [dismissible]="true" type="success" (closed)="closeNotification()" autoFocus tabindex="0">
    <div id="successMessage">{{ mainMessages.success }}</div>
    <ul *ngIf="notification.success[0] !== defaultSuccessValue">
      <li *ngFor="let message of notification.success">{{ message }}</li>
    </ul>
    <div *ngIf="showLink()">
      <button id="successLink" type="button" class="btn btn-link me-2" (click)="openDisplay()">
        <span class="fa-clipboard-list df-icon-wtd me-2"></span>{{ geFirstLinkText() }}
      </button>
    </div>
  </ngb-alert>
</div>
<div *ngIf="notification && notification.warning && notification.warning.length > 0">
  <ngb-alert insert-alert-icon [dismissible]="true" type="warning" (closed)="closeNotification()" autoFocus tabindex="0">
    <div id="warningMessage">{{ mainMessages.warning }}</div>
    <ul>
      <li *ngFor="let message of notification.warning">{{ message }}</li>
    </ul>
  </ngb-alert>
</div>
<div *ngIf="notification && notification.error && notification.error.length > 0">
  <ngb-alert insert-alert-icon [dismissible]="true" type="danger" (closed)="closeNotification()" autoFocus tabindex="0">
    <div id="errorMessage">{{ mainMessages.error }}</div>
    <ul>
      <li *ngFor="let message of notification.error">{{ message }}</li>
    </ul>
  </ngb-alert>
</div>
