<div class="container-fluid">
    <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..."
        i18n-message="@@upp.global.messages.loading">
    </ama-loading-alert>
</div>
<div class="mb-2">
    <form [formGroup]="searchCriteriaForm" id="fareSearchCriteriaForm"
        (ngSubmit)="searchClicked()">
        <div class="row">
            <div class="col text-end mb-2">
                <button
                    i18n="@@upp.global.button.clear"
                    id="clearDisabled"
                    type="button"
                    [disabled]="sendingStatus"
                    class="btn btn-outline-primary btn-sm"
                    (click)="clearClicked()"> Clear </button>
            </div>
        </div>
        <div formGroupName="rule">
            <div class="mb-4">
                <label i18n="@@upp.fares.display.nameLabel" for="name"
                    class="form-label">Name </label>
                    @if (dynamicExclusionV2) {
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                        [ngClass]="{ 'is-invalid': !isNameControlValid() }"
                        data-test="dynamic-exclusion-rule-name"
                        placeholder="Dynamic exclusion rule name"
                        i18n-placeholder="@@upp.dynamicExclusions.common.namePlaceholder"
                        ngbTooltip="Enter the Dynamic exclusion rule name, with letters, numbers, without spaces (up to 30 characters)."
                        i18n-ngbTooltip="@@upp.dynamicExclusions.search.common.nameTooltip"
                        oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();
                                 this.setSelectionRange(p,p);" 
                      />
                    } 
                    @else {   
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        formControlName="name"
                        [ngClass]="{ 'is-invalid': !isNameControlValid() }"
                        data-test="fare-rule-name"
                        placeholder="Fare rule name"
                        i18n-placeholder="@@upp.fares.common.namePlaceholder"
                        ngbTooltip="Enter a fare rule name, max. 30 alpha numeric without spaces"
                        i18n-ngbTooltip="@@upp.fares.search.common.nameTooltip"
                        oninput="let r=this.selectionStart;this.value=this.value.toUpperCase();
                                 this.setSelectionRange(r,r);"
                      />
                    }
                <div *ngIf="!this.isNameControlValid()" class="invalid mt-1">
                  {{ getNameValidationError() }} 
                </div>
                @if (!dynamicExclusionV2) {
                  <div class="row mb-1 mt-4">
                    <div class="col">
                        <div class="d-flex flex-wrap">
                            <div *ngFor="let criteria of dynamicSearchCriteria">
                                <button
                                    type="button"
                                    [id]="criteria.name"
                                    [ngClass]="
                                    criteria.active
                                    ? 'btn btn-primary btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
                                    : 'btn btn-outline-primary btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'"
                                    (click)="toggleCriteriaButtonAndControl(criteria.name)"
                                    [disabled]="criteria.disabled"> 
                                    {{ criteria.title }}
                                </button>
                            </div>
                        </div>
                    </div>
                  </div>
                }
                <div class="row">
                    <div class="col">
                        <div
                            *ngIf="isCriteriaActive(availableDynamicCriteria.RULE_STATUS)"
                            role="radiogroup"
                            aria-labelledby="ruleStatusSearchFares">
                            <hr class="separator" />
                            <div class="d-flex flex-wrap">
                                <span
                                    i18n="@@upp.fares.search.ruleStatusLabel"
                                    class="label-criteria"
                                    id="ruleStatusSearchFares">Rule Status</span>
                                <!-- Disable until ABR API has option for both -->
                                <!-- <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="ruleStatusBoth"
                                        value="all"
                                        name="ruleStatus"
                                        formControlName="ruleStatus" />
                                    <label i18n="@@upp.global.labels.both"
                                        class="form-check-label"
                                        for="ruleStatusBoth"> Both </label>
                                </div> -->
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="ruleStatusActive"
                                        value="active"
                                        name="ruleStatus"
                                        formControlName="ruleStatus" />
                                    <label
                                        i18n="@@upp.fares.search.table.active"
                                        class="form-check-label"
                                        for="ruleStatusActive"> Active </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="ruleStatusInactive"
                                        value="inactive"
                                        name="ruleStatus"
                                        formControlName="ruleStatus" />
                                    <label
                                        i18n="@@upp.fares.search.table.inactive"
                                        class="form-check-label"
                                        for="ruleStatusInactive"> Inactive
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button
                i18n="@@upp.global.button.search"
                *ngIf="!sendingStatus"
                id="search"
                type="submit"
                class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
                [disabled]="!searchCriteriaForm.valid"> Search </button>
            <button
                i18n="@@upp.global.button.search"
                *ngIf="sendingStatus"
                id="searchIsSending"
                type="submit"
                disabled
                class="btn btn-primary btn-action mb-2 mb-md-0 me-2"> Search
            </button>
        </div>
    </form>
</div>
