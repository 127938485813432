import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UppNotification, DEFAULT_SUCCESS } from '../../model/notification';

@Component({
  selector: 'ama-upp-notification',
  templateUrl: './upp-notification.component.html'
})
export class UppNotificationComponent implements OnInit {
  @Input() notification!: UppNotification;
  @Input() mainMessages!: NotificationMessages;
  @Input() linkEnabled = true;

  @Output() closeNotificationEvent = new EventEmitter<void>();
  @Output() openDisplayEvent = new EventEmitter<void>();

  defaultSuccessValue: string;

  constructor() {
    this.defaultSuccessValue = DEFAULT_SUCCESS;
  }

  ngOnInit() {
    if (!this.mainMessages.linkSuccessText) {
      this.mainMessages.linkSuccessText = '';
    }
  }

  closeNotification() {
    this.closeNotificationEvent.emit();
  }

  openDisplay() {
    this.openDisplayEvent.emit();
  }

  showLink(): boolean | undefined {
    return this.linkEnabled && this.notification.links && this.notification.links.length > 0;
  }

  geFirstLinkText() {
    return this.prepareLinks()[0].text;
  }

  prepareLinks(): LinkElement[] {
    if (!this.notification.links) {
      return [];
    }
    const links: LinkElement[] = [];
    this.notification.links.forEach(link => {
      links.push({
        text: this.mainMessages.linkSuccessText + link.name,
        targetId: link.id
      });
    });
    return links;
  }
}

export interface NotificationMessages {
  error: string;
  warning: string;
  success: string;
  linkSuccessText?: string;
}

export interface LinkElement {
  text: string;
  targetId?: string;
}
