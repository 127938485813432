import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, mergeMap, switchMap } from 'rxjs';
import { getAllAvailableAirFamilies, setAllAvailableAirFamilies } from './air-families-action';
import { AirFamiliesService } from '../../../service/air-families.service';
import { ConfigurationService } from '../../../service/configuration/configuration.service';

@Injectable()
export class AirFamilyEffects {
  constructor(
    private readonly actions: Actions,
    private readonly airFamiliesService: AirFamiliesService,
    private readonly configurationService: ConfigurationService
  ) {}

  getAllAvailableAirFamilies: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(getAllAvailableAirFamilies),
      mergeMap((action) => {
        const partitionEnabled = this.configurationService.getParameter('UPP_PARTITIONS_A');

        const request = {
          version: '2.0',
          airFamilies: partitionEnabled ? { partitionId: action.payload } : { organization: action.payload }
        };

        return this.airFamiliesService
          .search(request)
          .pipe(switchMap((response) => [setAllAvailableAirFamilies({ airFamilies: response })]));
      })
    )
  );
}