<nav class="navbar-expand-lg navbar-light d-inline-block h-100">
  <div
    *ngIf="!isNavigationDisabled()"
    id="sideNav"
    class="navbar navbar-light collapse navbar-collapse align-items-start h-100"
    [ngbCollapse]="isMenuCollapsed()"
  >
    <div class="navbar h-auto d-inline-block w-100">
      <ul class="navbar-nav flex-column">
        <li class="nav-item">
          <a id="dashboard-item" routerLink="/dashboard" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
            ><em class="fa-tachometer"></em> <span>Dashboard</span></a
          >
        </li>
        <li class="nav-item" *ngIf="navigationService.posViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.posViewEnabled ? true : null"
            (click)="togglePos()"
            [routerLink]="isPosCollapsed() ? ['/pos/search'] : []"
          >
            <em class="fa-cash-register"></em> Points Of Sale&nbsp;
            <span [ngClass]="{ 'fa-angle-down': isPosCollapsed() === true, 'fa-angle-up': isPosCollapsed() === false }"> </span>
          </a>
          <div [ngbCollapse]="isPosCollapsed()" id="posSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/pos/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="fa-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/pos/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.posManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="fa-file-signature"></em> Create</a
                >
              </li>
              <li class="nav-link">
                <a
                  *ngIf="!posModifyViewEnabled"
                  routerLink="/pos/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!posDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-alt"></em> Display
                </a>
                <a
                  *ngIf="posModifyViewEnabled"
                  routerLink="/pos/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!posDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.marketsViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.marketsViewEnabled ? true : null"
            (click)="toggleMarkets()"
            [routerLink]="isMarketsCollapsed() ? ['/markets/search'] : []"
          >
            <em class="fa-store"></em> Markets&nbsp;
            <span
              [ngClass]="{
                'fa-angle-down': isMarketsCollapsed() === true,
                'fa-angle-up': isMarketsCollapsed() === false
              }"
            >
            </span>
          </a>
          <div [ngbCollapse]="isMarketsCollapsed()" id="marketsSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/markets/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="fa-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/markets/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.marketsManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="fa-file-signature"></em> Create</a
                >
              </li>
              <li class="nav-link">
                <a
                  *ngIf="!marketsModifyViewEnabled"
                  routerLink="/markets/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!marketsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-alt"></em> Display
                </a>
                <a
                  *ngIf="marketsModifyViewEnabled"
                  routerLink="/markets/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!marketsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.familiesViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.familiesViewEnabled"
            (click)="toggleFamilies()"
            [routerLink]="navigationService.isFamiliesCollapsed && !familiesV2 ? [RouteURL.familyHotelSearch] : []"
            data-test="families-link"
          >
            <em class="fa-desktop"></em> Families&nbsp;
            <span [ngClass]="[navigationService.isFamiliesCollapsed ? 'fa-angle-down' : 'fa-angle-up']" class="float-end"> </span>
          </a>
          @if (familiesV2) {
          <section [ngbCollapse]="navigationService.isFamiliesCollapsed">
            <a
              class="df-collapse nav-link w-100 fw-normal ps-8"
              (click)="navigationService.isHotelFamiliesCollapsed = !navigationService.isHotelFamiliesCollapsed"
              [routerLink]="navigationService.isHotelFamiliesCollapsed ? [RouteURL.familyHotelSearch] : []"
              data-test="hotel-families-link"
              >Hotel Families&nbsp;
              <span [ngClass]="[navigationService.isHotelFamiliesCollapsed ? 'fa-angle-down' : 'fa-angle-up']" class="float-end"></span>
            </a>
          </section>
          <ng-container *ngTemplateOutlet="hotelFamilies; context: { $implicit: navigationService.isHotelFamiliesCollapsed }">
          </ng-container>
          <section [ngbCollapse]="navigationService.isFamiliesCollapsed">
            <a
              class="df-collapse nav-link w-100 fw-normal ps-8"
              (click)="navigationService.isAirFamiliesCollapsed = !navigationService.isAirFamiliesCollapsed"
              [routerLink]="navigationService.isAirFamiliesCollapsed ? [RouteURL.familyAirSearch] : []"
              data-test="air-families-link"
              >Air Families&nbsp;
              <span [ngClass]="[navigationService.isAirFamiliesCollapsed ? 'fa-angle-down' : 'fa-angle-up']" class="float-end"></span>
            </a>
          </section>
          <section [ngbCollapse]="navigationService.isAirFamiliesCollapsed" id="familiesAirSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a [routerLink]="RouteURL.familyAirSearch" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="fa-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  [routerLink]="RouteURL.familyAirCreate"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.familiesManageEnabled"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-signature"></em> Create</a
                >
              </li>

              <li class="nav-link">
                <a
                  [routerLink]="lastActiveAirFamilyView === UppViewNames.MODIFY ? RouteURL.familyAirModify : RouteURL.familyAirDisplay"
                  routerLinkActive="is-active"
                  [class.disabled]="!lastActiveAirFamilyView"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-edit"></em> Display</a
                >
              </li>
            </ul>
          </section>
          } @else {
          <ng-container *ngTemplateOutlet="hotelFamilies; context: { $implicit: navigationService.isFamiliesCollapsed }"> </ng-container>
          }
        </li>

        <li class="nav-item" *ngIf="navigationService.faresViewEnabled && dynamicExclusionV2 !== null">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.faresViewEnabled ? true : null"
            (click)="toggleFares()"
            [routerLink]="isFaresCollapsed() ? ['/fares/search'] : []"
            [attr.data-test]="dynamicExclusionV2 ? 'dynamic-exclusions-link' : 'fares-link'"
          >
            <em class="fa-money-check-alt"></em>
            @if (dynamicExclusionV2) {
            <ng-container i18n="@@upp.navigation.dynamicExclusions.name">Dynamic Exclusions</ng-container>&nbsp; } @else {
            <ng-container i18n="@@upp.navigation.fares.name">Fares</ng-container>&nbsp; }
            <span
              [ngClass]="{
                'fa-angle-down': isFaresCollapsed() === true,
                'fa-angle-up': isFaresCollapsed() === false
              }"
              class="float-right"
            >
            </span>
          </a>
          <div [ngbCollapse]="isFaresCollapsed()" id="faresSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a
                  routerLink="/fares/search"
                  routerLinkActive="is-active"
                  class="nav-link"
                  (click)="hideSideNav()"
                  [attr.data-test]="dynamicExclusionV2 ? 'dynamic-exclusions-search-link' : 'fares-search-link'"
                  ><em class="fa-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/fares/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.faresManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  [attr.data-test]="dynamicExclusionV2 ? 'dynamic-exclusions-create-link' : 'fares-create-link'"
                  ><em class="fa-file-signature"></em> Create</a
                >
              </li>

              <li class="nav-link">
                <a
                  *ngIf="!faresModifyViewEnabled"
                  routerLink="/fares/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!faresDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-alt"></em> Display
                </a>
                <a
                  *ngIf="faresModifyViewEnabled"
                  routerLink="/fares/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!faresDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.flightsViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.flightsViewEnabled ? true : null"
            (click)="toggleFlights()"
            [routerLink]="isFlightsCollapsed() ? ['/flights/search'] : []"
          >
            <em class="fa-plane"></em> Flights&nbsp;
            <span
              [ngClass]="{
                'fa-angle-down': isFlightsCollapsed() === true,
                'fa-angle-up': isFlightsCollapsed() === false
              }"
              class="float-end"
            >
            </span>
          </a>
          <div [ngbCollapse]="isFlightsCollapsed()" id="flightsSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/flights/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="fa-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/flights/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.flightsManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="fa-file-signature"></em> Create</a
                >
              </li>

              <li class="nav-link">
                <a
                  *ngIf="!flightsModifyViewEnabled"
                  routerLink="/flights/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!flightsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-alt"></em> Display
                </a>
                <a
                  *ngIf="flightsModifyViewEnabled"
                  routerLink="/flights/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!flightsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.hotelsViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.hotelsViewEnabled ? true : null"
            (click)="toggleHotels()"
            [routerLink]="isHotelsCollapsed() ? ['/hotels/search'] : []"
          >
            <em class="fa-hotel"></em> Hotels&nbsp;
            <span
              [ngClass]="{
                'fa-angle-down': isHotelsCollapsed() === true,
                'fa-angle-up': isHotelsCollapsed() === false
              }"
              class="float-end"
            >
            </span>
          </a>
          <div [ngbCollapse]="isHotelsCollapsed()" id="hotelsSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/hotels/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="fa-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/hotels/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.hotelsManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="fa-file-signature"></em> Create</a
                >
              </li>
              <li class="nav-link">
                <a
                  *ngIf="!hotelsModifyViewEnabled"
                  routerLink="/hotels/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!hotelsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-alt"></em> Display
                </a>
                <a
                  *ngIf="hotelsModifyViewEnabled"
                  routerLink="/hotels/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!hotelsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="fa-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a routerLink="/about" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
            ><em class="fa-info-circle"></em> About</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #hotelFamilies let-collapsed>
  <section [ngbCollapse]="collapsed" id="familiesSubMenu" class="subMenu">
    <ul class="navbar-nav flex-column">
      <li class="nav-link">
        <a [routerLink]="RouteURL.familyHotelSearch" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
          ><em class="fa-search"></em> Search</a
        >
      </li>
      <li class="nav-link">
        <a
          [routerLink]="RouteURL.familyHotelCreate"
          routerLinkActive="is-active"
          [class.disabled]="!navigationService.familiesManageEnabled"
          class="nav-link"
          (click)="hideSideNav()"
          ><em class="fa-file-signature"></em> Create</a
        >
      </li>

      <li class="nav-link">
        @if (!familiesModifyViewEnabled) {
        <a
          [routerLink]="RouteURL.familyHotelDisplay"
          routerLinkActive="is-active"
          [class.disabled]="!familiesDisplayViewEnabled"
          class="nav-link"
          (click)="hideSideNav()"
          ><em class="fa-file-alt"></em> Display</a
        >
        } @if (familiesModifyViewEnabled) {
        <a
          [routerLink]="RouteURL.familyHotelModify"
          routerLinkActive="is-active"
          [class.disabled]="!familiesDisplayViewEnabled"
          class="nav-link"
          (click)="hideSideNav()"
          ><em class="fa-file-edit"></em> Display</a
        >
        }
      </li>
    </ul>
  </section>
</ng-template>
