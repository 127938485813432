import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDetailsService } from '../../../service/user-details.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard  {

    constructor(private readonly userDetailsService: UserDetailsService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.userDetailsService.loggedInUser) {
            return false;
        }
        return true;
    }
}
