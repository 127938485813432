import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HotelService } from '../../service/hotel.service';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  HOTELS_ACTION,
  HotelsActions,
  FinishHotelRuleCreationAction,
  SetHotelRuleListAction,
  DeleteHotelRuleSearchNotificationAction,
  DeleteHotelRuleFromListAction,
  DeleteHotelRuleClearStoreAction,
  FinishHotelUpdateAction
} from './hotels-action';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { HotelRuleUi } from '../../hotels/model';
import { TYPE_ERROR } from '../../service/model/pos/pos-market-record';
import { UserSessionExpired } from '../core/actions';

@Injectable()
export class HotelsEffects {

  createHotel: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(HOTELS_ACTION.CREATE_HOTEL_RULE),
      mergeMap(action => {
        const { request } = (action as HotelsActions).payload;
        return this.hotelService.createHotelRule(request).pipe(
          map(rule => new FinishHotelRuleCreationAction({ hotelRule: rule })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishHotelRuleCreationAction({ hotelRule: createErrorRule(error) }));
          })
        );
      })
    ));

  searchHotelRules: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(HOTELS_ACTION.SEARCH_HOTEL_RULE),
      mergeMap(action => {
        const { request } = (action as HotelsActions).payload;
        return this.hotelService.searchHotelRules(request).pipe(
          switchMap(hotelRules => [
            new DeleteHotelRuleSearchNotificationAction({}),
            new SetHotelRuleListAction({ hotelRules })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new SetHotelRuleListAction({ hotelRules: [createErrorRule(error)] }));
          })
        );
      })
    ));

  deleteHotelRule: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(HOTELS_ACTION.DELETE_HOTEL_RULE),
      mergeMap(action => {
        const { request } = (action as HotelsActions).payload;
        return this.hotelService.deleteHotelRule(request).pipe(
          switchMap(deletedRule => [
            new DeleteHotelRuleSearchNotificationAction({}),
            new DeleteHotelRuleClearStoreAction(request),
            new DeleteHotelRuleFromListAction({ deletedRule })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new DeleteHotelRuleFromListAction({ deletedRule: createErrorRule(error) }));
          })
        );
      })
    ));

  updateHotelRule: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(HOTELS_ACTION.UPDATE_HOTEL),
      mergeMap(action => {
        const { request } = (action as HotelsActions).payload;
        return this.hotelService.updateHotelRule(request).pipe(
          map(hotelRule => new FinishHotelUpdateAction({ updatedHotelRuleUi: hotelRule })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishHotelUpdateAction({ updatedHotelRuleUi: createErrorRule(error) }));
          })
        );
      })
    ));

    constructor(private readonly actions: Actions, private readonly hotelService: HotelService) {}
}

const createErrorRule = (error: Error): HotelRuleUi => ({
    statusType: TYPE_ERROR,
    id: 'hotelRuleError',
    organization: '',
    name: '',
    action: '',
    active: false,
    hotelApplicability: {},
    statusNotification: { error: [error.message] }
});
