import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NavigationService } from '../../service/core/navigation.service';
import {
  getEmptyPosMarketDetail,
  PosMarketDetail,
  posMarketDetailEquals,
  PosMarketServiceData,
  PosMarketUpdateRequest,
  UppComponent
} from '../../service/model';

import {
  ActivateCreatedPosDisplayAction,
  CreatePosAction,
  DeletePosCreateNotificationAction,
  SetPosCreateValueAction
} from '../../store/pos/pos-actions';
import {
  selectPosCreateNotification,
  selectPosCreateSending,
  selectPosCreateValue
} from '../../store/pos/pos-selector';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UserDetailsService } from '../../service/user-details.service';
import { isIncludeExcludeFilled } from '../../util/include-exclude-filter';
import { FormComponent } from '../../model/FormComponent';

@Component({
  selector: 'ama-ng-upp-pos-display',
  templateUrl: './pos-create.component.html',
  styleUrls: ['./pos-create.component.scss']
})
export class PosCreateComponent implements OnInit, FormComponent {

  posDetails: PosMarketDetail = { organization: '', name: '' };
  mainMessages: NotificationMessages;
  notification$: Observable<UppNotification>;

  posForm: UntypedFormGroup;
  numberOfItem = 2;
  readonly = false;
  loadResources = true;
  sendingStatus: boolean;

  uppComponent = UppComponent.POS_CREATE;
  includeExcludeNames = ['regions', 'countries', 'cities', 'airports', 'officeIds'];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.notification$ = this.store.pipe(select(selectPosCreateNotification));
    this.store.pipe(select(selectPosCreateSending)).subscribe(sending => (this.sendingStatus = sending));
    this.mainMessages = {
      error: $localize`:@@upp.pointOfSale.create.mainErrorText:Creation failed due to the following errors:`,
      warning: $localize`:@@upp.pointOfSale.create.mainWarningText:Warnings were generated during the creation process:`,
      success: $localize`:@@upp.pointOfSale.mainSuccessText:Well done! You get this message about your successful POS creation`,
      linkSuccessText: $localize`:@@upp.pointOfSale.create.linkSuccessText:Display new Point of Sale: `
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('POS Creation');
    this.navigationService.activatePos();
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.posForm = this.formBuilder.group({});
    this.store.pipe(select(selectPosCreateValue)).subscribe(pos => {
      if (pos) {
        this.posDetails = pos;
        this.posForm.reset();
      }
    });
  }

  saveData() {
    const request = this.createRequest();
    this.storeUnsavedChanges();
    this.store.dispatch(new CreatePosAction({ request }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetPosCreateValueAction({ value: this.posForm.value }));
  }

  closeNotification() {
    this.store.dispatch(new DeletePosCreateNotificationAction({}));
  }

  showPosInDisplay() {
    this.store.dispatch(new ActivateCreatedPosDisplayAction({}));
    this.router.navigate(['pos/display']);
  }

  saveButtonEnabled(): boolean {
    if (!(this.posForm && this.posForm.get('name'))) {
      return false;
    }
    const nameValue: string = this.posForm.get('name').value;
    if (nameValue && nameValue.length > 0) {
      return this.posForm.valid && this.includeValdiation(this.posForm);
    }
    return false;
  }

  createRequest(): PosMarketUpdateRequest {
    if (this.userDetailsService.loggedInUser.partitions) {
      return {
        version: '1.0',
        pointOfSale: new PosMarketServiceData(this.posForm.value, null, this.userDetailsService.loggedInUser.selectedPartition)
      };
    }
    return {
      version: '1.0',
      pointOfSale: new PosMarketServiceData(this.posForm.value, this.userDetailsService.getLssOrganization(), null)
    };
  }

  hasUnsavedChanges(): boolean {
    return !posMarketDetailEquals(getEmptyPosMarketDetail(), this.posForm.value);
  }

  private includeValdiation(posForm: UntypedFormGroup) {
    // TODO: Attribute dirty cannot be used currently due to patch logic etc...
    return (
      isIncludeExcludeFilled(posForm.get('include'), this.includeExcludeNames) ||
      posForm.get('include').get('world').value === true
    );
  }
}
