import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HotelsSearchComponent } from './hotels-search/hotels-search.component';
import { HotelsDisplayComponent } from './hotels-display/hotels-display.component';
import { HotelsModifyComponent } from './hotels-modify/hotels-modify.component';
import { HotelsCreateComponent } from './hotels-create/hotels-create.component';
import { CanDeactivateHotelRuleCreateGuard } from '../core/util/can-deactivate-hotel-rule-create.guard';
import { CanDeactivateHotelRuleSearchGuard } from '../core/util/can-deactivate-hotel-rule-search.guard';
import { CanDeactivateHotelModifyGuard } from '../core/util/can-deactivate-hotel-modify.guard';
import { CanManageHotelGuard } from '../core/util/routing-guards/hotels/can-manage-hotel-guard';
import { CanViewHotelGuard } from '../core/util/routing-guards/hotels/can-view-hotel-guard';
import { UppComponentNames } from '../service/model';
import { LoggedInGuard } from '../core/util/routing-guards/logged-in.guard';

const hotelRoutes: Routes = [
  {
    path: UppComponentNames.HOTELS,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'search',
        component: HotelsSearchComponent,
        canDeactivate: [CanDeactivateHotelRuleSearchGuard],
        canActivate: [CanViewHotelGuard]
      },
      {
        path: 'display',
        component: HotelsDisplayComponent,
        canActivate: [CanViewHotelGuard]
      },
      {
        path: 'display/:id',
        component: HotelsDisplayComponent,
        canActivate: [CanViewHotelGuard]
      },
      {
        path: 'modify',
        component: HotelsModifyComponent,
        canDeactivate: [CanDeactivateHotelModifyGuard],
        canActivate: [CanManageHotelGuard]
      },
      {
        path: 'modify/:id',
        component: HotelsModifyComponent,
        canDeactivate: [CanDeactivateHotelModifyGuard],
        canActivate: [CanManageHotelGuard]
      },
      {
        path: 'create',
        component: HotelsCreateComponent,
        canDeactivate: [CanDeactivateHotelRuleCreateGuard],
        canActivate: [CanManageHotelGuard]
      },
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: '**', redirectTo: 'search', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(hotelRoutes)],
  exports: [RouterModule]
})
export class HotelsRoutingModule {}
