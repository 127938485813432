import { SelectableCriteria } from '../base/search-criteria/selectable-criteria';

export interface ButtonGroupItem extends SelectableCriteria {
  tooltip?: string;
  // If true next button will start on a new line
  endOfLine?: boolean;
  canToggle?: () => boolean;
}

export const hasActiveButtonItem = (buttonGroupItems: ButtonGroupItem[]): boolean =>
  buttonGroupItems.some((buttonGroupItem) => buttonGroupItem.active);
