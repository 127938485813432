import { CanActivateChildFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { Activatable } from '../../../model/activatable';

export const activationParamGuard: CanActivateChildFn = (route) => {
  if (!(route.component?.prototype instanceof Activatable)) {
    throw new Error('Component must extend Activatable abstract class');
  }

  const router = inject(Router);

  return route.component.prototype.isActivated()
    .pipe(map(isActivated => isActivated || router.parseUrl('/dashboard')));
}
