import { Action } from '@ngrx/store';
import {
  HotelRuleCreateRequest,
  HotelRuleSearchRequest,
  HotelRuleDeleteRequest,
  HotelUpdateRequest
} from '../../hotels/model/hotel-request';
import { HotelRuleUi } from '../../hotels/model/hotel-rule-ui';
import { HotelRuleSearchCriteriaUi } from '../../hotels/model/hotel-rule-search-criteria';

export const HOTELS_ACTION = {
  INIT_HOTEL_RULE_STATE: '[HOTELS Action] Initializing Hotel rule state',
  CREATE_HOTEL_RULE: '[HOTELS Action] Creating Hotel rule',
  SET_CREATE_HOTEL_RULE_VALUE: '[HOTELS Action] Setting create hotel rule values',
  FINISH_HOTEL_RULE_CREATION: '[HOTELS Action] Finishing hotel rule creation',

  START_HOTEL_MODIFICATION: '[HOTELS Action] Activating hotel modification',
  CANCEL_HOTEL_MODIFICATION: '[HOTELS Action] Canceling hotel modification',
  SET_HOTEL_MODIFY_VALUE: '[HOTELS Action] Setting hotel modify value',
  SET_HOTEL_UPDATE_SUCCESS_VALUES: '[HOTELS Action] Setting hotel update success values',
  UPDATE_HOTEL: '[HOTELS Action] Updating hotel',
  FINISH_HOTEL_UPDATE: '[HOTELS Action] Finishing hotel update',

  ACTIVATE_HOTEL_RULE_DISPLAY: '[HOTELS Action] Activating hotel rule display',
  ACTIVATE_CREATED_HOTEL_RULE_DISPLAY: '[HOTELS Action] Displaying created hotel rule display',
  SEARCH_HOTEL_RULE: '[HOTELS Action] Searching Hotel rules',
  SET_HOTEL_RULE_LIST: '[HOTELS Action] Setting Hotel rules',
  CLEAR_HOTEL_RULE_LIST: '[HOTELS Action] Clearing Hotel rules',
  SET_HOTEL_RULE_SEARCH_VALUE: '[HOTELS Action] Setting hotel rule search notification',

  DELETE_HOTEL_RULE: '[HOTELS Action] Deleting Hotel rule',
  DELETE_HOTEL_RULE_CLEAR_STORE: '[HOTELS Action] Clearing store after hotel rule deletion',
  DELETE_HOTEL_RULE_FROM_LIST: '[HOTELS Action] Deleting hotel rule from list',
  DELETE_HOTEL_RULE_SEARCH_NOTIFICATION: '[HOTELS Action] Deleting hotel rule search notification',
  DELETE_HOTEL_RULE_CREATE_NOTIFICATION: '[HOTELS Action] Deleting Hotel rule create notification',
  DELETE_HOTEL_RULE_MODIFY_NOTIFICATION: '[HOTELS Action] Deleting hotel modify notification',
  DELETE_HOTEL_RULE_DISPLAY_NOTIFICATION: '[HOTELS Action] Deleting hotel rule display notification'
};

export class InitHotelRuleStateAction implements Action {
  readonly type = HOTELS_ACTION.INIT_HOTEL_RULE_STATE;
  constructor(public payload: any) {}
}

export class CreateHotelRuleAction implements Action {
  readonly type = HOTELS_ACTION.CREATE_HOTEL_RULE;
  constructor(public payload: { request: HotelRuleCreateRequest }) {}
}

export class SetCreateHotelRuleValueAction implements Action {
  readonly type = HOTELS_ACTION.SET_CREATE_HOTEL_RULE_VALUE;
  constructor(public payload: { value: HotelRuleUi }) {}
}

export class FinishHotelRuleCreationAction implements Action {
  readonly type = HOTELS_ACTION.FINISH_HOTEL_RULE_CREATION;
  constructor(public payload: { hotelRule: HotelRuleUi }) {}
}

export class ActivateHotelRuleDisplayAction implements Action {
  readonly type = HOTELS_ACTION.ACTIVATE_HOTEL_RULE_DISPLAY;
  constructor(public payload: { id: string }) {}
}

export class ActivateCreatedHotelRuleDisplayAction implements Action {
  readonly type = HOTELS_ACTION.ACTIVATE_CREATED_HOTEL_RULE_DISPLAY;
  constructor(public payload: any) {}
}

export class UpdateHotelAction implements Action {
  readonly type = HOTELS_ACTION.UPDATE_HOTEL;
  constructor(public payload: { request: HotelUpdateRequest }) {}
}
export class SetHotelModifyValueAction implements Action {
  readonly type = HOTELS_ACTION.SET_HOTEL_MODIFY_VALUE;
  constructor(public payload: { value: HotelRuleUi }) {}
}
export class StartHotelModificationAction implements Action {
  readonly type = HOTELS_ACTION.START_HOTEL_MODIFICATION;
  constructor(public payload: { id: string }) {}
}
export class SetHotelUpdateSuccessValuesAction implements Action {
  readonly type = HOTELS_ACTION.SET_HOTEL_UPDATE_SUCCESS_VALUES;
  constructor(public payload: { updatedHotelRule: HotelRuleUi }) {}
}
export class CancelHotelModificationAction implements Action {
  readonly type = HOTELS_ACTION.CANCEL_HOTEL_MODIFICATION;
  constructor(public payload: any) {}
}
export class FinishHotelUpdateAction implements Action {
  readonly type = HOTELS_ACTION.FINISH_HOTEL_UPDATE;
  constructor(public payload: { updatedHotelRuleUi: HotelRuleUi }) {}
}

export class SearchHotelRuleAction implements Action {
  readonly type = HOTELS_ACTION.SEARCH_HOTEL_RULE;
  constructor(public payload: { request: HotelRuleSearchRequest }) {}
}

export class SetHotelRuleListAction implements Action {
  readonly type = HOTELS_ACTION.SET_HOTEL_RULE_LIST;
  constructor(public payload: { hotelRules: HotelRuleUi[] }) {}
}

export class ClearHotelRulesAction implements Action {
  readonly type = HOTELS_ACTION.CLEAR_HOTEL_RULE_LIST;
  constructor(public payload: any) {}
}

export class SetHotelRuleSearchValueAction implements Action {
  readonly type = HOTELS_ACTION.SET_HOTEL_RULE_SEARCH_VALUE;
  constructor(public payload: { hotelSearchCriteria: HotelRuleSearchCriteriaUi }) {}
}

export class DeleteHotelRuleAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE;
  constructor(public payload: { request: HotelRuleDeleteRequest }) {}
}

export class DeleteHotelRuleClearStoreAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE_CLEAR_STORE;
  constructor(public payload: any) {}
}

export class DeleteHotelRuleFromListAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE_FROM_LIST;
  constructor(public payload: { deletedRule: HotelRuleUi }) {}
}

export class DeleteHotelModifyNotificationAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE_MODIFY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteHotelRuleCreateNotificationAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE_CREATE_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteHotelRuleDisplayNotificationAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE_DISPLAY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteHotelRuleSearchNotificationAction implements Action {
  readonly type = HOTELS_ACTION.DELETE_HOTEL_RULE_SEARCH_NOTIFICATION;
  constructor(public payload: any) {}
}

export type HotelsActions =
  | InitHotelRuleStateAction
  | CreateHotelRuleAction
  | SetCreateHotelRuleValueAction
  | UpdateHotelAction
  | SetHotelModifyValueAction
  | StartHotelModificationAction
  | SetHotelUpdateSuccessValuesAction
  | CancelHotelModificationAction
  | FinishHotelUpdateAction
  | FinishHotelRuleCreationAction
  | ActivateHotelRuleDisplayAction
  | ActivateCreatedHotelRuleDisplayAction
  | SearchHotelRuleAction
  | SetHotelRuleListAction
  | ClearHotelRulesAction
  | SetHotelRuleSearchValueAction
  | DeleteHotelRuleAction
  | DeleteHotelRuleClearStoreAction
  | DeleteHotelRuleFromListAction
  | DeleteHotelRuleCreateNotificationAction
  | DeleteHotelRuleDisplayNotificationAction
  | DeleteHotelRuleSearchNotificationAction
  | DeleteHotelModifyNotificationAction;
