export enum UppComponent {
  POS_CREATE,
  POS_MODIFY,
  POS_SEARCH,
  POS_DISPLAY,
  MARKETS_SEARCH,
  MARKETS_DISPLAY,
  MARKETS_MODIFY,
  MARKETS_CREATE,
  HOTELS_CREATE,
  HOTELS_MODIFY,
  HOTELS_DISPLAY,
  FLIGHTS_CREATE,
  FLIGHTS_DISPLAY,
  FAMILIES_SEARCH,
  FAMILIES_DISPLAY,
  FAMILIES_MODIFY,
  FAMILIES_CREATE,
  FARES_CREATE,
  FARES_DISPLAY,
  FARES_MODIFY
}

export enum UppComponentNames {
  POS = 'pos',
  MARKETS = 'markets',
  HOTELS = 'hotels',
  FLIGHTS = 'flights',
  FAMILIES = 'families',
  FARES = 'fares',
  FLIGHTS_SEARCH = 'flights-search',
  AIR_FAMILIES = 'air-families',
}

export enum UppViewNames {
  CREATE = 'create',
  DISPAY = 'display',
  MODIFY = 'modify',
  SEARCH = 'search'
}

export type ViewMode = UppViewNames.CREATE | UppViewNames.DISPAY | UppViewNames.MODIFY;
