import { ClpInitConfig, LoginAlertModel } from '@seco/login';
import { LoggedInUser } from '../../service/model';
import { ConfigurationOutput } from '@seco/core';

export const CORE_FEATURE = 'coreUppUi';

export interface CoreState {
  clpUrl?: string;
  clpConfig?: ClpInitConfig;
  loggedIn: boolean;
  user?: LoggedInUser;
  error?: LoginAlertModel;
  sending: boolean;
  configuration?: ConfigurationOutput;
}

export const coreInitialState: CoreState = {
  loggedIn: false,
  sending: false
};
