import { AbstractControl, ValidationErrors } from '@angular/forms';

export const positiveNumberValidator = (control: AbstractControl): ValidationErrors | null => {
    const numericValue = control.value;
    if (numericValue === null || numericValue === undefined) {
        return null;
    }
    if (numericValue > 0) {
        return null;
    }
    return { positiveNumberValidator: { currentValue: numericValue } };
};
