import {
  HotelPropertyCodesFamilySearchResponse,
  HotelPropertyCodesFamilyCreateUpdateResponse
} from '../../families/model/family-response';
import { TYPE_ERROR, TYPE_SUCCESS, TYPE_WARNING } from '../model';
import { UppNotification, DEFAULT_SUCCESS } from '../../model/notification';
import {
  FamilyUi,
  convertHotelPropertyCodesFamilyToFamilyUi,
  HotelPropertyCodesDeleteRequest,
  HotelPropertyCodesFamily,
  HOTEL_PROP_CODES_TYPE
} from '../../families/model';
import { SUCCESS_STATUS, MISSING_RESULT_STATUS, UNKNOWN_RESULT_STATUS, buildErrorOrWarning } from './response-handler';
import { BaseResponse } from '../../base/model/base-response';

export const HOTEL_CODES_ERROR = 'hotelPropertyCodesFamilyError';
export const HOTEL_CODES_WARN = 'hotelPropertyCodesFamilyWarning';

export const successDeleteHotelCodeUi: FamilyUi = {
  statusType: TYPE_SUCCESS,
  statusNotification: { success: [DEFAULT_SUCCESS] },
  id: '',
  organization: '',
  name: '',
  hotelFamilyType: HOTEL_PROP_CODES_TYPE
};

export const warningHotelCodeUi: FamilyUi = {
  statusType: TYPE_WARNING,
  statusNotification: { warning: [UNKNOWN_RESULT_STATUS] },
  id: HOTEL_CODES_WARN,
  organization: '',
  name: '',
  hotelFamilyType: HOTEL_PROP_CODES_TYPE
};

export const errorHotelCodeUi: FamilyUi = {
  statusType: TYPE_ERROR,
  statusNotification: { error: [MISSING_RESULT_STATUS] },
  id: HOTEL_CODES_ERROR,
  organization: '',
  name: '',
  hotelFamilyType: HOTEL_PROP_CODES_TYPE
};

export const buildCodesFamilyFromCreateOrUpdateResponse = (
  response: HotelPropertyCodesFamilyCreateUpdateResponse
): FamilyUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return createSuccessForCreate(response.hotelPropertyCodesFamily, true);
  }
  return buildFailedResponse(response);
};

export const buildHotelPropertyCodesFamilyListFromSearchResponse = (
  response: HotelPropertyCodesFamilySearchResponse
): FamilyUi[] => {
  if (response.status.state === SUCCESS_STATUS) {
    return createFamilyList(response);
  }
  return [buildFailedResponse(response)];
};

export const buildHotelPropertyCodesFamilyFromDeleteResponse = (
  response: BaseResponse,
  request: HotelPropertyCodesDeleteRequest
): FamilyUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return {
      ...successDeleteHotelCodeUi,
      id: request.hotelPropertyCodesFamily.id
    };
  }
  return buildFailedResponse(response);
};

const buildFailedResponse = (response: BaseResponse): FamilyUi =>
  buildErrorOrWarning(warningHotelCodeUi, errorHotelCodeUi, response) as FamilyUi;

const createFamilyList = (response: HotelPropertyCodesFamilySearchResponse): FamilyUi[] => {
  const families: FamilyUi[] = [];
  response.hotelPropertyCodesFamilies.forEach(hotelChain =>
    families.push(convertHotelPropertyCodesFamilyToFamilyUi(hotelChain))
  );
  return families;
};

const createSuccessForCreate = (hotelPropertyCodesFamily: HotelPropertyCodesFamily, addLinkData: boolean): FamilyUi => {
  const notification: UppNotification = {
    success: [DEFAULT_SUCCESS]
  };

  if (!hotelPropertyCodesFamily.id) {
    throw new Error('id is null or undefined.');
  }

  if (addLinkData) {
    notification.links = [
      {
        id: hotelPropertyCodesFamily.id,
        name: hotelPropertyCodesFamily.name
      }
    ];
  }

  const family = convertHotelPropertyCodesFamilyToFamilyUi(hotelPropertyCodesFamily);
  family.statusType = TYPE_SUCCESS;
  family.statusNotification = notification;
  return family;
};
