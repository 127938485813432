import { PosMarketDetail, PosMarketSearchCriteria } from '../../service/model';
import { PosMarketRecord } from '../../service/model/pos/pos-market-record';
import { Notification } from '../../model/notification';

export const MARKETS_FEATURE = 'marketsUppUi';

export interface MarketsState {
  marketsRecords?: PosMarketRecord[];
  availableMarketsRecords?: PosMarketRecord[];
  selectedMarketsNames?: Set<string>;
  markets: {
    search?: {
      sending: boolean;
      value: PosMarketSearchCriteria;
    };
    delete?: {
      sending: boolean;
    };
    create: MarketsStatus;
    display: MarketsStatus;
    modify: MarketsStatus;
  };
  notification?: Notification;
}

export interface MarketsStatus {
  sending: boolean;
  active?: boolean;
  value?: PosMarketDetail;
  updatedRecord?: PosMarketRecord;
}

export const initialMarketsStatus: MarketsStatus = {
  sending: false,
  value: {
    organization: '',
    name: ''
  },
  active: false
};

export const initialMarkets = {
  search: {
    sending: false,
    value: {
      name: '',
      include: {
        world: false,
        regions: [] as any[],
        countries: [] as any[],
        cities: [] as any[],
        airports: [] as any[],
        officeIds: [] as any[]
      }
    }
  },
  create: initialMarketsStatus,
  display: initialMarketsStatus,
  modify: initialMarketsStatus
};

export const marketsInitialState: MarketsState = {
  markets: initialMarkets
};
