@if (!flightsMGT) {
  <label class="d-block form-label">
    <ng-container i18n="@@upp.flights.details.routeContentSelectLabel">Route content</ng-container>
  </label>
  <div class="route-content-section mb-5" *ngFor="let contentForm of marketsFormArray.controls">
    <ng-container *ngTemplateOutlet="marketTemplate; context: { $implicit: contentForm }"></ng-container>
  </div>
} @else {
  <div class="route-content-section mb-5" [formGroup]="routeFormGroup">
    @if (!hasNDCContentType || flightsNDCConnectingTimeMarket) {
      <label class="d-block form-label">
        <ng-container i18n="@@upp.flights.details.routeContentSelectLabel">Route content</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-button-group
        class="mb-5"
        data-test="route-button-group"
        [items]="routeScopeButtonGroupItems"
        (toggled)="onRouteScopeToggle($event)"
      >
      </ama-ng-upp-button-group>
      <ama-ng-upp-validation-error-message [control]="routeScopeControl"
                                           [messageFunctions]="SECTION_VALIDATION_MESSAGES">
      </ama-ng-upp-validation-error-message>
      @if (routeScopeButtonGroupMap?.markets.active) {
        <div class="route-content-section mt-5" *ngFor="let contentForm of marketsFormArray?.controls">
          <ng-container *ngTemplateOutlet="marketTemplate; context: { $implicit: contentForm }"></ng-container>
        </div>
      }
      @if (routeScopeButtonGroupMap?.rangeConnectingTime.active) {
        <div class="route-content-section mt-5">
          <ng-container
            *ngTemplateOutlet="connectingTimeTemplate; context: { formGroup: routeFormGroup.get('rangeConnectingTime') }">
          </ng-container>
        </div>
      }
    } @else {
      <ng-container
        *ngTemplateOutlet="connectingTimeTemplate; context: { formGroup: routeFormGroup.get('rangeConnectingTime') }">
      </ng-container>
    }
  </div>
}

<ng-template #marketTemplate let-contentForm let-i="index">
  <div [formGroup]="contentForm" class="criteria-container" data-test="market-section">
    <div class="d-flex w-100">
      <div class="market-container">
        <label for="market" class="form-label">
          <ng-container i18n="@@upp.flights.details.marketNameLabel">Market</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select
          id="market"
          formControlName="name"
          [items]="availableMarkets"
          [lookupOptions]="readonly ? null : marketLookupOptions"
          notFoundText="No markets found"
          i18n-notFoundText="@@upp.global.criteria.markets.notFoundText"
        >
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="contentForm.get('name')"></ama-ng-upp-validation-error-message>
      </div>
      <div class="market-position-container d-inline-block">
        <label i18n="@@upp.flights.details.marketPosition.label" for="position" class="form-label">Market
          position</label>
        <ama-ng-upp-lookup-select
          id="position"
          formControlName="position"
          [items]="marketPositionOptions"
          ngbTooltip="Select a market position between -6 and 6 (zero is not allowed)."
          i18n-ngbTooltip="@@upp.flights.details.marketPosition.tooltip"
        >
        </ama-ng-upp-lookup-select>
      </div>
      <div *ngIf="!readonly" class="icon-container">
        <button
          class="btn btn-outline-primary df-btn-icononly fa-plus mt-7 mb-5 ms-2 flex-grow-auto"
          type="button"
          (click)="addButtonClicked()"
        >
        </button>
        <ng-container *ngIf="marketsFormArray.length > 1">
          <button
            class="btn btn-outline-primary df-btn-icononly fa-trash-alt df-icon-solid mt-7 mb-5 ms-2 flex-grow-auto"
            type="button"
            (click)="removeButtonClicked(i)"
          >
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #connectingTimeTemplate let-formGroup="formGroup">
  <div [formGroup]="formGroup" data-test="range-time-section">
    <label class="form-label">
      <ng-container i18n="@@upp.flights.details.rangeConnectingTimeLabel">Connecting time</ng-container>
    </label>
    <div class="d-flex">
      <div class="me-7">
        <label for="minCT" class="me-7">
          <ng-container i18n="@@upp.flights.details.route.minimumTimeLabel">Minimum</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ngb-timepicker formControlName="minCT" data-test="minCT"></ngb-timepicker>
        <ama-ng-upp-validation-error-message [control]="minTimeControl"
                                             [messageFunctions]="minTimeValidationMessageFunctions">
        </ama-ng-upp-validation-error-message>
      </div>
      <div class="me-7">
        <label for="maxCT" class="me-7">
          <ng-container i18n="@@upp.flights.details.route.maximumTimeLabel">Maximum</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ngb-timepicker formControlName="maxCT" data-test="maxCT"></ngb-timepicker>
        <ama-ng-upp-validation-error-message [control]="maxTimeControl"
                                             [messageFunctions]="maxTimeValidationMessageFunctions">
        </ama-ng-upp-validation-error-message>
      </div>
    </div>
  </div>
</ng-template>
