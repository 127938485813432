import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlightsSearchComponent } from './flights-search/flights-search.component';
import { FlightsDisplayComponent } from './flights-display/flights-display.component';
import { FlightsCreateComponent } from './flights-create/flights-create.component';
import { FlightsModifyComponent } from './flights-modify/flights-modify.component';
import { CanDeactivateFlightRuleCreateGuard } from '../core/util/can-deactivate-flight-rule-create.guard';
import { CanDeactivateFlightRuleSearchGuard } from '../core/util/can-deactivate-flight-rule-search.guard';
import { CanDeactivateFlightModifyGuard } from '../core/util/can-deactivate-flight-modify.guard';
import { CanManageFlightGuard } from '../core/util/routing-guards/flights/can-manage-flight-guard';
import { CanViewFlightGuard } from '../core/util/routing-guards/flights/can-view-flight-guard';
import { UppComponentNames } from '../service/model';
import { LoggedInGuard } from '../core/util/routing-guards/logged-in.guard';

const flightRoutes: Routes = [
  {
    path: UppComponentNames.FLIGHTS,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'search',
        component: FlightsSearchComponent,
        canDeactivate: [CanDeactivateFlightRuleSearchGuard],
        canActivate: [CanViewFlightGuard]
      },
      {
        path: 'display',
        component: FlightsDisplayComponent,
        canActivate: [CanViewFlightGuard]
      },
      {
        path: 'display/:id',
        component: FlightsDisplayComponent,
        canActivate: [CanViewFlightGuard]
      },
      {
        path: 'modify',
        component: FlightsModifyComponent,
        canDeactivate: [CanDeactivateFlightModifyGuard],
        canActivate: [CanManageFlightGuard]
      },
      {
        path: 'modify/:id',
        component: FlightsModifyComponent,
        canDeactivate: [CanDeactivateFlightModifyGuard],
        canActivate: [CanManageFlightGuard]
      },
      {
        path: 'create',
        component: FlightsCreateComponent,
        canDeactivate: [CanDeactivateFlightRuleCreateGuard],
        canActivate: [CanManageFlightGuard]
      },
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: '**', redirectTo: 'search', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(flightRoutes)],
  exports: [RouterModule]
})
export class FlightsRoutingModule {}
