<form [formGroup]="formGroup">
  <div class="row mt-2">
    <div class="col">
      <div class="d-flex flex-wrap">
        <ng-container *ngFor="let criteria of criteriaList; let i=index ">
          <button
            type="button"
            [id]="criteria.name"
            (click)="toggleActive(criteria)"
            (customCriteriaClicked)="customClicked($event)"
            [disabled]="criteria.disabled"
            [class.btn-primary]="criteria.active"
            [class.btn-outline-primary]="!criteria.active"
            [class.btn-criteria-lg]="largeButtons"
            [class.btn-criteria]="!largeButtons"
            class="btn btn-sm mt-2 mb-3 md-0 me-4"> {{ criteria.title }} </button>
          <div *ngIf="shouldAddLineBreak(i)" class="break"></div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngFor="let criteria of criteriaList">
    <div class="row">
      <div class="col" [class.mb-5]="criteria.customTemplate">
        <ng-container *ngIf="criteria.active">
          <ng-container
            *ngTemplateOutlet="criteria.customTemplate ? criteria.customTemplate: defaultTemplate">
          </ng-container>
          <ng-template #defaultTemplate>
            <ama-ng-upp-multi-select-badges
              *ngIf="!criteria.customInputField"
              [formElementName]="criteria.name"
              [formElementLabel]="criteria.title"
              [formGroup]="formGroup"
              [placeholder]="criteria.placeholder"
              [numberOfBadges]="criteria.numberOfBadges"
              [readonlyMode]="readonly"
              [badgeItems]="criteria.badgeItems"
              [notFoundText]="criteria.notFoundText"
              [tooltipText]="criteria.tooltip"
              [availableItems]="criteria.possibleValues ? criteria.possibleValues : []"
              [lookup]="readonly ? null : criteria.lookupOptions"
              [customPasteRegExp]="criteria.customPasteRegExp"
              [parent]="parent"
              (removeBadge)="onRemoveBadge($event, criteria)"
              [disableNewValues]="criteria.disableNewValues ? criteria.disableNewValues : false">
            </ama-ng-upp-multi-select-badges>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</form>
