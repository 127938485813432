import { CoreActions, CORE_ACTION } from './actions';
import { CoreState, coreInitialState } from './state';
import { CLP_ACTION } from '@seco/login';

export const coreReducer = (state = coreInitialState, action: CoreActions): CoreState => {
  switch (action.type) {
    case CORE_ACTION.UPDATE_CLP_CONFIG:
      return Object.assign({}, state, {
        clpConfig: action.payload
      });
    case CORE_ACTION.USER_LOGGED_IN:
      return Object.assign({}, state, {
        loggedIn: true,
        user: action.payload,
        error: false
      });
    case CORE_ACTION.USER_PERMISSION_FAILED:
      return Object.assign({}, state, {
        loggedIn: false,
        error: createLoginAlert(action.payload)
      });
    case CLP_ACTION.ERROR_RECEIVED:
    case CORE_ACTION.USER_SESSION_EXPIRED:
      return Object.assign({}, state, {
        loggedIn: false,
        error: action.payload
      });
    case CORE_ACTION.UPDATE_USER_PARTITIONS:
      const userWithPartitions = Object.assign({}, state.user, {
        partitions: action.payload,
        selectedPartition: action.payload[0]
      });
      return Object.assign({}, state, {
        user: userWithPartitions
      });
    case CORE_ACTION.UPDATE_SELECTED_PARTITION:
      const userWithSelectedPartition = Object.assign({}, state.user, {
        selectedPartition: action.payload
      });
      return Object.assign({}, state, {
        user: userWithSelectedPartition
      });
    case CORE_ACTION.CONFIGURATION_RETRIEVED:
      return Object.assign({}, state, {
        configuration: action.payload
      });
    default:
      return state;
  }
};

const createLoginAlert = (errorMessage: string): any => {
  const loginAlert = {
    code: '1',
    error: {
      message: errorMessage
    }
  };
  return [loginAlert];
};
