import { AbstractControl, ValidationErrors } from '@angular/forms';

export const threeLetterCodesValidator = (control: AbstractControl): ValidationErrors | null => {
  let code;
  const codeValue = control.value;
  const codeArray = [];
  if (codeValue && codeValue !== null) {
    codeArray.push(codeValue);
    for (const val of codeArray[0]) {
      code = val.length;
    }
  }
  if (!code) {
    return null;
  }
  if (code < 3 || code > 3) {
    return {
      threeLetterCodesValidator: {
        requiredValue: 3
      }
    };
  }
  return null;
};
