import { NavigationActions, NAVIGATION_ACTION } from './navigation-actions';
import { navigationInitialState, NavigationState } from './navigation-state';
import { CORE_ACTION } from '../core/actions';

export const navigationReducer = (state = navigationInitialState, action: NavigationActions): NavigationState => {
  switch (action.type) {
    case NAVIGATION_ACTION.SET_NAVIGATION_PARAMS:
      return Object.assign({}, state, {
        navigation: action.payload
      });
    case NAVIGATION_ACTION.CLEAR_LOOKUP_DATA:
      return Object.assign({}, state, {
        navigation: {
          ...state.navigation,
          data: undefined
        }
      });
    case NAVIGATION_ACTION.SET_PARTITION_DESTINATION:
      return Object.assign({}, state, {
        partitionDestination: action.payload
      });
    case NAVIGATION_ACTION.METAGROUP_SWITCH_ALERT_DISPLAYED:
      return Object.assign({}, state, {
        showPartitionSwitchPopup: false
      });
    case NAVIGATION_ACTION.RESET_PARTITION_DESTINATION:
      return Object.assign({}, state, {
        partitionDestination: null
      });
    case CORE_ACTION.UPDATE_SELECTED_PARTITION:
      return Object.assign({}, state, {
        partitionDestination: null,
        showPartitionSwitchPopup: true
      });
    default:
      return state;
  }
};
