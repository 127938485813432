import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { UppComponentNames } from './model';
import { DEFAULT_ERROR } from './model/common/validation-messages';
import { MAX_NUMBER_OF_BADGES} from './model/common/validators';

export class ValidationProvider {
  validator!: ValidatorFn[];
  massages: any;
}

@Injectable({
  providedIn: 'root'
})
export class UppValidatorService {

  private readonly validationProviders: Map<UppComponentNames, Map<string, ValidationProvider>> =
    new Map<UppComponentNames, Map<string, ValidationProvider>>();

  getValidatorsForControl(componentName: UppComponentNames, controlName: string,
      useMaxBadgesDefault: boolean): ValidatorFn | ValidatorFn[] {
    const provider = this.getValidationProvider(componentName, controlName);
    if (provider == null) {
      return useMaxBadgesDefault ? Validators.maxLength(MAX_NUMBER_OF_BADGES) : [];
    }
    return provider.validator;
  }

  getErrorMessagesForControl(componentName: UppComponentNames, controlName: string): any {
    const provider = this.getValidationProvider(componentName, controlName);
    if (provider == null) {
      return DEFAULT_ERROR;
    }
    return provider.massages;
  }

  setComponentValidation(componentName: UppComponentNames, providers: Map<string, ValidationProvider>) {
    this.validationProviders.set(componentName, providers);
  }

  private getValidationProvider(componentName: UppComponentNames, controlName: string): ValidationProvider | null {
    if (!this.validationProviders.has(componentName)) {
      return null;
    }
    if(!this.validationProviders.get(componentName)?.has(controlName)) {
      return null;
    }

    return this.validationProviders.get(componentName)?.get(controlName) ?? null;
  }
}
