import { AbstractControl, ValidationErrors } from '@angular/forms';

export const valuesNotAllowedValidator = (valuesNotAllowed: string[]) => (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  if (typeof control.value === 'string') {
    return valuesNotAllowed.includes(control.value) ? {valuesNotAllowed: control.value} : null;
  }

  const matchingValues: string[] = control.value.filter((code: string) => valuesNotAllowed.includes(code));

  return matchingValues.length > 0 ? {valuesNotAllowed: matchingValues} : null;
}
