<div class="container-fluid">
  <div class="row">
    <div id="about-title" class="col">
      <h1 i18n="@@upp.core.about.title">About</h1>
    </div>
  </div>
  <div class="row">
    <div id="upp-name" class="col">
      <h3 i18n="@@upp.name">Amadeus Unified Preferences Platform</h3>
      <p>
        <ng-container i18n="@@upp.core.about.copyright.date">© 2020 Amadeus s.a.s.</ng-container><br /><ng-container
          i18n="@@upp.core.about.copyright.text"
          >All rights reserved under national and international copyright laws.</ng-container
        >
      </p>
    </div>
  </div>
  <div class="row">
    <div id="details" class="col">
      <div class="mb-1 w-50">
        <div class="card">
          <img class="card-img-top" src="assets/images/dashboard/about.jpg" alt="About image" />
          <div class="card-body">
            <h3 i18n="@@upp.core.about.details.title" class="card-title">Functional Documentation</h3>
            <p i18n="@@upp.core.about.details.text" class="card-text">
                To get you started and make the most of Unified Preferences Platform, check our entry point in Amadeus
                Service Hub. All you need to learn is there: from a product description to its latest features, the
                channels where it works, examples and useful documentation.
            </p>
            <p class="card-text">
              <a
                i18n="@@upp.core.about.details.linkLabel"
                [href]="doclink"
                class="df-link-external"
                target="_blank"
                rel="noopener noreferrer"
                >Entry point in Amadeus Service Hub</a
              >
            </p>
          </div>        
        </div>
      </div>
    </div>
  </div>
</div>
