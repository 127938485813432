import { Validators } from '@angular/forms';
import { exactLengthValidator } from '../../../components/form-validators/exact-length.validator';
import { officeIdValidator } from '../../../components/form-validators/office-id.validator';
import { countryCodesValidator } from '../../../components/form-validators/country-codes.validator';
import { integerNumberValidator } from '../../../components/form-validators/integer-number.validator';
import { namesValidator } from '../../../components/form-validators/names.validator';
import { positiveNumberValidator } from '../../../components/form-validators/positive-number.validator';
import { threeLetterCodesValidator } from '../../../components/form-validators/three-letter-code.validator';


export const nameRegex = '^[A-Z0-9]{0,30}$';
export const alphanumericSpaceMax30 = '^[A-Z0-9 ]{0,30}$'
const multipleNamesRegex = '^(?:[A-Z0-9]{0,30}(?:,[A-Z0-9]{0,30})*)?$';
const countriesRegex = '^(?:[A-Z]{2}(?:,[A-Z]{2})*)?$';
const citiesAndAirportsRegex = '^(?:[A-Z]{3}(?:,[A-Z]{3})*)?$';
export const twoAlphaNumericRegex = '^(?:[A-Z0-9]{2}(?:,[A-Z0-9]{2})*)?$';
const threeAlphaNumericRegex = '^(?:[A-Z0-9]{3}(?:,[A-Z0-9]{3})*)?$';
const eightAlphaNumericRegex = '^(?:[A-Z0-9]{8}(?:,[A-Z0-9]{8})*)?$';
const _location = '[A-Z*]{3}';
const _corpCode = '[A-Z0-9*]{2}';
const _corpQualifier = '[0-9*]';
const _officeCode = '[0-9A-Z*]{3}';
const officeIdPattern = _location + _corpCode + _corpQualifier + _officeCode;
const corpCodePattern = _corpCode + _corpQualifier;
const officeIdRegex = `^(?:${officeIdPattern}(?:,${officeIdPattern})*)?$`;
const corpCodeRegex = `^(?:${corpCodePattern}(?:,${corpCodePattern})*)?$`;
// Validates strings of the type '7X0001-0002A' as '-0002' and suffix 'A' are optional
export const oneFlightRange = '([A-Za-z0-9]{2})(\\d{4})(-(\\d{4})([A-Za-z]?))?';
export const flightRangesRegexPattern = `^(?:${oneFlightRange}(?:,${oneFlightRange})*)?$`;

export const SINGLE_BADGE = 1;
export const MAX_TEN_BADGES = 10;
export const MAX_NUMBER_OF_BADGES = 100;
export const MAX_NUMBER_OF_LARGE_BADGES = 1000;

export const REQUIRED = 'required';
export const MAX_LENGTH_VALIDATION = 'maxlength';
export const PATTERN_VALIDATION = 'pattern';
export const COUNTRY_CODE_VALIDATION = 'countryCodesValidator';
export const THREE_LETTER_CODES_VALIDATION = 'threeLetterCodesValidator';
export const EIGHTS_SYMBOLS_VALIDATION = 'exactLengthValidator';
export const OFFICE_ID_VALIDATION = 'officeIdValidator';
export const NAMES_VALIDATION = 'namesValidator';

export const NAME = [Validators.maxLength(30), Validators.pattern(nameRegex)];
export const DESCRIPTION = [Validators.maxLength(128)];

export const COUNTRY = [
  countryCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(countriesRegex)
];

export const CITIES = [
  threeLetterCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(citiesAndAirportsRegex)
];

export const AIRPORTS = [
  threeLetterCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(citiesAndAirportsRegex)
];

export const OFFICE_IDS = [
  officeIdValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(officeIdRegex)
];

export const CORPORATE_CODES_LIST = [
  threeLetterCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(corpCodeRegex)
];

export const THREE_LETTERS_COUNTRY_CODES = [
  threeLetterCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(threeAlphaNumericRegex)
];

export const TWO_LETTERS_COUNTRY_CODES = [
  countryCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(twoAlphaNumericRegex)
];

export const TWO_LETTERS_COUNTRY_CODES_LONG_LIST = [
  countryCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_LARGE_BADGES),
  Validators.pattern(twoAlphaNumericRegex)
];

export const EIGHT_SYMBOLS_LIST = [
  exactLengthValidator(8),
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  // This regex needs to be confirmed if it is needed as currently is overridden by exactLengthValidator
  Validators.pattern(eightAlphaNumericRegex)
];

export const EIGHT_SYMBOLS_LONG_LIST = [
  exactLengthValidator(8),
  Validators.maxLength(MAX_NUMBER_OF_LARGE_BADGES),
  // This regex needs to be confirmed if it is needed as currently is overridden by exactLengthValidator
  Validators.pattern(eightAlphaNumericRegex)
];

export const CARRIERS = [
  countryCodesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(twoAlphaNumericRegex)
];

export const CARRIERS_REQUIRED = [
  Validators.required,
  ...CARRIERS
];

export const SINGLE_CARRIER_REQUIRED = [
  Validators.required,
  countryCodesValidator,
  Validators.maxLength(SINGLE_BADGE),
  Validators.pattern(twoAlphaNumericRegex)
];

export const NAMES_LIST = [
  namesValidator,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(multipleNamesRegex)
];

export const NAMES_LIST_REQUIRED = [
  namesValidator,
  Validators.required,
  Validators.maxLength(MAX_NUMBER_OF_BADGES),
  Validators.pattern(multipleNamesRegex)
];

export const NUMBER_OF_CONNECTIONS = [
  Validators.required,
  Validators.min(1),
  Validators.max(5),
  positiveNumberValidator,
  integerNumberValidator
];

export const SINGLE_NAME = [
  namesValidator,
  Validators.maxLength(SINGLE_BADGE),
  Validators.pattern(multipleNamesRegex)
];

export const SINGLE_NAME_REQUIRED = [
  namesValidator,
  Validators.required,
  Validators.maxLength(SINGLE_BADGE),
  Validators.pattern(multipleNamesRegex)
];

export const TEN_CARRIERS = [
  countryCodesValidator,
  Validators.maxLength(MAX_TEN_BADGES),
  Validators.pattern(twoAlphaNumericRegex)
];

export const FLIGHT_RANGES_REQUIRED = [
  Validators.required,
  Validators.pattern(flightRangesRegexPattern)
];

export const REQUIRED_FIELD = [
  Validators.required
];

export const SINGLE_REQUIRED = [
  Validators.required,
  Validators.maxLength(SINGLE_BADGE)
];

export const MAX_LENGTH_ONE = [
  Validators.maxLength(1)
];
