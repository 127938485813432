import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, filter } from 'rxjs';
import { FormComponent } from '../../model/FormComponent';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { NavigationService } from '../../service/core/navigation.service';
import { UppComponent } from '../../service/model';
import {
  CancelFareModificationAction,
  DeleteFareModifyNotificationAction,
  SetFareModifyValueAction,
  UpdateFareAction
} from '../../store/fares/fares-action';
import {
  selectFareModifyNotification,
  selectFareModifySending,
  selectFareModifyValue,
  selectFareRules
} from '../../store/fares/fares-selector';
import { FareFormValidator } from '../model/fare-form-validator';
import { FareRuleUpdateRequest } from '../model/fare-request';
import { convertFareRuleUiV2ToFareRuleUi, convertToFareRule } from '../model/fare-rule-converter';
import { FareRuleUi, fareUiEquals, initialFareRuleUi } from '../model/fare-rule-ui';
import { SOURCE_VIEW_DISPLAY, SOURCE_VIEW_QUERY_PARAM_KEY } from '../../service/core/navigation-constants';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-fares-modify',
  templateUrl: './fares-modify.component.html',
  styleUrls: ['./fares-modify.component.scss']
})
export class FaresModifyComponent implements OnInit, OnDestroy, FormComponent {

  fareDetail: FareRuleUi = initialFareRuleUi;

  notification$: Observable<UppNotification>;
  mainMessages: NotificationMessages;
  sendingStatus: boolean;
  activated: boolean;
  fareForm: UntypedFormGroup;
  uppComponent = UppComponent.FARES_MODIFY;
  readonly = false;
  sourceView: string;

  showFaresV2: boolean;

  private subscriptions: Subscription[] = [];
  private lastSavedFareUi: FareRuleUi;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fareFormValidator: FareFormValidator,
    private readonly configurationService: ConfigurationService
  ) {
    this.subscriptions.push(this.store.pipe(select(selectFareModifySending)).subscribe(sending => (this.sendingStatus = sending)));
    this.subscriptions.push(this.store.pipe(select(selectFareModifyValue)).subscribe(
      value => (this.lastSavedFareUi = this.fareDetail = value)));
      this.notification$ = this.store.pipe(
        select(selectFareModifyNotification),
        filter((notification): notification is UppNotification => notification !== undefined)
      );
    this.showFaresV2 = this.configurationService.getParameter<boolean>(FeatureFlags.dynamicExclusionV2);
    if (this.showFaresV2) {
      this.mainMessages = {
        error: $localize`:@@upp.dynamicExclusions.modify.mainErrorText:The following errors for dynamic exclusion rule appeared:`,
        warning: $localize`:@@upp.dynamicExclusions.modify.mainWarningText:The following warning for dynamic exclusion rule appeared:`,
        success: $localize`:@@upp.dynamicExclusions.modify.mainSuccessText:The dynamic exclusion rule was stored successfully.`
      };
    } else {
      this.mainMessages = {
        error: $localize`:@@upp.fares.modify.mainErrorText:The following errors for fare rule appeared:`,
        warning: $localize`:@@upp.fares.modify.mainWarningText:The following warning for fare rule appeared:`,
        success: $localize`:@@upp.fares.modify.mainSuccessText:The fare rule was stored successfully.`
      };
    }
  }

  ngOnInit() {
    if (this.showFaresV2) {
      this.navigationService.setSelectedMenuTitle(
        $localize`:@@upp.dynamicExclusions.modify.navigationTitle:Dynamic Exclusion Modification`
      );
    } else {
      this.navigationService.setSelectedMenuTitle('Fares Modification');
    }
    this.navigationService.activateFares();
    this.createAndLoadForm();
    this.route.queryParams.subscribe((params: Params) => {
      this.sourceView = params[SOURCE_VIEW_QUERY_PARAM_KEY];
    });
  }

  createAndLoadForm() {
    this.fareForm = this.formBuilder.group({});
    if (this.fareDetail) {
      this.fareForm.patchValue(this.fareDetail);
    }
    // Comment because after Save of Fare modification, page is redirected to Search instead of Display as expected
    // this.subscriptions.push(this.store.pipe(select(selectFareModifyActivated)).subscribe(activated => {
    //   if (!activated) {
    //     this.router.navigate(['fares/search/']);
    //   }
    // }));
  }

  cancelModification() {
    const id = this.fareDetail.id;
    this.store.dispatch(new CancelFareModificationAction({}));
    if (this.sourceView === SOURCE_VIEW_DISPLAY) {
      this.router.navigate(['fares/display/' + id]); // NOSONAR
    } else {
      this.router.navigate(['fares/search']);
    }
  }

  saveModification() {
    this.fareForm.markAllAsTouched();
    if (this.fareForm.valid) {
      this.store.dispatch(new UpdateFareAction({ request: this.createRequest() }));
    }
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(
      new SetFareModifyValueAction({
        value: this.getFormValue()
      })
    );
  }

  hasUnsavedChanges(): boolean {
    return !fareUiEquals(this.lastSavedFareUi, this.getFormValue());
  }


  saveEnabled(): boolean {
    return this.fareFormValidator.hasMandatoryData(this.fareForm) && this.fareForm.valid;
  }

  closeNotification() {
    this.store.dispatch(new DeleteFareModifyNotificationAction({}));
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private createRequest(): FareRuleUpdateRequest {
    return {
      rule: convertToFareRule(this.getFormValue())
    };
  }

  private getFormValue(): FareRuleUi {
    return this.showFaresV2 ? convertFareRuleUiV2ToFareRuleUi(this.fareForm.value) : this.fareForm.value;
  }

}
