import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FlightService } from '../../service/flight.service';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  FLIGHTS_ACTION,
  FlightsActions,
  FinishFlightRuleCreationAction,
  SetFlightRuleListAction,
  DeleteFlightRuleSearchNotificationAction,
  DeleteFlightRuleFromListAction,
  DeleteFlightRuleClearStoreAction,
  FinishFlightUpdateAction
} from './flights-action';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { JourneyUi } from '../../flights/model';
import { TYPE_ERROR } from '../../service/model/pos/pos-market-record';
import { UserSessionExpired } from '../core/actions';

@Injectable()
export class FlightsEffects {

  createFlight: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(FLIGHTS_ACTION.CREATE_FLIGHT_RULE),
      mergeMap(action => {
        const { request } = (action as FlightsActions).payload;
        return this.flightService.createFlightRule(request).pipe(
          map(journeyUi => new FinishFlightRuleCreationAction({ journey: journeyUi })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishFlightRuleCreationAction({ journey: createErrorRule(error) }));
          })
        );
      })
    ));

  searchFlightRules: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(FLIGHTS_ACTION.SEARCH_FLIGHT_RULE),
      mergeMap(action => {
        const { request } = (action as FlightsActions).payload;
        return this.flightService.searchFlightRules(request).pipe(
          switchMap(flightRules => [
            new DeleteFlightRuleSearchNotificationAction({}),
            new SetFlightRuleListAction({ flightRules })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new SetFlightRuleListAction({ flightRules: [createErrorRule(error)] }));
          })
        );
      })
    ));

  deleteFlightRule: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(FLIGHTS_ACTION.DELETE_FLIGHT_RULE),
      mergeMap(action => {
        const { request } = (action as FlightsActions).payload;
        return this.flightService.deleteFlightRule(request).pipe(
          switchMap(deletedRule => [
            new DeleteFlightRuleSearchNotificationAction({}),
            new DeleteFlightRuleClearStoreAction(request),
            new DeleteFlightRuleFromListAction({ deletedRule })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new DeleteFlightRuleFromListAction({ deletedRule: createErrorRule(error) }));
          })
        );
      })
    ));

  updateFlightRule: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(FLIGHTS_ACTION.UPDATE_FLIGHT),
      mergeMap(action => {
        const { request } = (action as FlightsActions).payload;
        return this.flightService.updateFlightRule(request).pipe(
          map(flightRule => new FinishFlightUpdateAction({ updatedJourneyUi: flightRule })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishFlightUpdateAction({ updatedJourneyUi: createErrorRule(error) }));
          })
        );
      })
    ));

    constructor(private readonly actions: Actions, private readonly flightService: FlightService) {}
}

const createErrorRule = (error: Error): JourneyUi => ({
    statusType: TYPE_ERROR,
    statusNotification: { error: [error.message] },
    rule: {
      id: 'flightRuleError',
      organization: '',
      name: '',
      action: '',
      active: false
    },
    applicability: {
      pointOfSaleName: ''
    }
});
