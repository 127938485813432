import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonGroupItem, hasActiveButtonItem } from '../../../../model/button-group-item';
import { ValueLabelItem } from '../../../../model/value-label-item';
import { UppComponentNames, UppViewNames } from '../../../../service/model';
import {
  SECTION_VALIDATION_MESSAGES,
  TWO_SYMBOLS_REQUIRED
} from '../../../../service/model/common/validation-messages';
import { twoAlphaNumericRegex } from '../../../../service/model/common/validators';
import { CarrierDataUi, FlightServiceElements } from '../../../model';
import { carrierCodeValidator } from '../../validators/carrier-code-validator';
import { ConfigurationService } from '../../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../../../core/util/resources';
import { LookupOptions } from '../../../../core/util/lookup-options';
import { PERMISSION_VIEW_FAMILY } from '../../../../service/user-permissions.service';
import { UserDetailsService } from '../../../../service/user-details.service';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectAvailableAirFamilies } from '../../../../store/families/air/air-families-selector';
import { SimplifiedAirFamily } from '../../../../families/air/model/air-family';

@Component({
  selector: 'ama-ng-upp-airline-scope-selector',
  templateUrl: './airline-scope-selector.component.html'
})
export class AirlineScopeSelectorComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() carrierData: CarrierDataUi;
  @Input() multiple = false;
  @Input() carrierCodeLabel = $localize`:@upp.global.criteria.carrierCode.label: Carrier code`;
  @Input() allianceLabel = $localize`:@upp.global.criteria.alliance.label: Alliance`;
  @Input() airlineFamilyLabel = $localize`:@upp.global.criteria.airlineFamilies.label: Air family`;
  @Input() disabled = false;
  @Input() hasNDCContentType = true;
  @Input() flightDetailsId?: string;

  familiesV2 = false;
  showFlightsYY = false;

  private readonly subscription: Subscription = new Subscription();

  CARRIER_CODE_TOOLTIP: string = $localize`:@@upp.common.carrierCodeTooltip:Entering YY as carrier will add all carriers to the rule.`;
  INITIAL_FORM_FIELD_VALUE = ' ';

  availableAlliances: ValueLabelItem<string>[] = [
    {
      value: '*A',
      label: $localize`:@@upp.global.criteria.alliance.starAlliance:Star Alliance`
    },
    {
      value: '*O',
      label: $localize`:@@upp.global.criteria.alliance.oneWorld:Oneworld`
    },
    {
      value: '*S',
      label: $localize`:@@upp.global.criteria.alliance.skyteam:Skyteam`
    }
  ];

  airlineScopeButtonGroupItems: ButtonGroupItem[];
  airlineScopeButtonGroupMap: { [key: string]: ButtonGroupItem };
  availableAirlineFamilyNames: string[] = [];
  availableAirlineFamilies: SimplifiedAirFamily[] = [];

  UppComponentNames = UppComponentNames;
  SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;
  carrierCodesValidationMessages = {
    ...TWO_SYMBOLS_REQUIRED,
    carrierCodeValidator: () =>
      $localize`:@@upp.validation.yyValueInCarrierCode:YY covers all airlines and cannot be combined with other airline codes`
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService,
    private readonly store: Store
  ) {
    this.subscription.add(
      this.configurationService.getParameter$(FeatureFlags.flightsYY).subscribe((showFlightsYY) => {
        this.showFlightsYY = showFlightsYY ?? false;
      })
    );

    this.subscription.add(
      this.configurationService.getParameter$(FeatureFlags.familiesV2).subscribe((familiesV2) => {
        this.familiesV2 = familiesV2 ?? false;
      })
    );
  }

  get hasSelected(): AbstractControl | null {
    return this.formGroup.get('hasSelected');
  }

  ngOnInit(): void {
    this.getAirlineFamiliesLookupOptions();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (!this.familiesV2) {
      this.airlineScopeButtonGroupItems = this.generateAirlineScopeButtonGroupItems().filter(
        (item: ButtonGroupItem) => item.name !== FlightServiceElements.AIRLINE_FAMILIES
      );
    } else {
      this.subscription.add(
        this.store.pipe(select(selectAvailableAirFamilies)).subscribe((result) => {
          if (result) {
            this.availableAirlineFamilies = result.map(({ name, id }) => ({ name, id }));
            this.availableAirlineFamilyNames = result.map((family) => family.name);
          }
        })
      );
      if (this.hasNDCContentType) {
        this.airlineScopeButtonGroupItems = this.generateAirlineScopeButtonGroupItems().filter(
          (item: ButtonGroupItem) => item.name !== FlightServiceElements.ALLIANCES
        );
      } else {
        this.airlineScopeButtonGroupItems = this.generateAirlineScopeButtonGroupItems();
      }
    }

    this.airlineScopeButtonGroupMap = this.airlineScopeButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    this.formGroup.setControl('hasSelected', this.formBuilder.control(false, Validators.requiredTrue));

    if (this.carrierData?.carrierCodes || (this.hasNDCContentType && !this.familiesV2)) {
      this.airlineScopeButtonGroupMap.carrierCodes.active = true;
      this.onAirlineScopeToggle(this.airlineScopeButtonGroupMap.carrierCodes, this.carrierData?.carrierCodes);
    } else if (this.carrierData?.alliances) {
      this.airlineScopeButtonGroupMap.alliances.active = true;
      this.onAirlineScopeToggle(this.airlineScopeButtonGroupMap.alliances, this.carrierData?.alliances);
    } else if (this.familiesV2 && this.carrierData?.carriersFamily) {
      this.airlineScopeButtonGroupMap.carriersFamily.active = true;
      this.onAirlineScopeToggle(this.airlineScopeButtonGroupMap.carriersFamily, this.carrierData?.carriersFamily);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAirlineScopeToggle(item: ButtonGroupItem, value?: string[] | string): void {
    this.airlineScopeButtonGroupItems
      .filter((buttonGroupItem) => buttonGroupItem !== item)
      .forEach((buttonGroupItem) => (buttonGroupItem.disabled = item.active ? true : this.disabled));

    if (item.active) {
      const validators = [Validators.required];
      if (item.name === FlightServiceElements.CARRIER_CODES) {
        validators.push(Validators.pattern(twoAlphaNumericRegex));
        if (this.showFlightsYY) {
          validators.push(carrierCodeValidator);
        }
      }
      if (item.name === FlightServiceElements.AIRLINE_FAMILIES) {
        this.formGroup.setControl('availableAirlineFamilies', this.formBuilder.control(this.availableAirlineFamilies));
      }
      this.formGroup.setControl(item.name, this.formBuilder.control(
        { value: value ?? this.INITIAL_FORM_FIELD_VALUE, disabled: this.disabled }, validators
      ));
    } else {
      this.formGroup.removeControl(item.name);
    }

    this.hasSelected?.setValue(hasActiveButtonItem(this.airlineScopeButtonGroupItems));
    this.hasSelected?.markAsTouched();
    this.changeDetectorRef.detectChanges();
  }

  private generateAirlineScopeButtonGroupItems(): ButtonGroupItem[] {
    return [
      {
        name: FlightServiceElements.CARRIER_CODES,
        active: false,
        disabled: this.disabled,
        title: $localize`:@@upp.global.criteria.carrierCode.label:Carrier code`
      },
      {
        name: FlightServiceElements.ALLIANCES,
        active: false,
        disabled: this.disabled,
        title: $localize`:@@upp.global.criteria.alliance.label:Alliance`
      },
      {
        name: FlightServiceElements.AIRLINE_FAMILIES,
        active: false,
        disabled: this.disabled,
        title: $localize`:@@upp.global.criteria.airlineFamilies.label:Air family`
      }
    ];
  }

  getControl(name: string): FormControl {
    return this.formGroup.get(name) as FormControl;
  }

  getAirlineFamiliesLookupOptions(): LookupOptions | null {
    if (this.canLookupAirlineFamilies()) {
      return {
        destinationComponent: `${UppComponentNames.FAMILIES}/air`,
        sourceComponent: UppComponentNames.FLIGHTS,
        sourceView: this.flightDetailsId ? UppViewNames.MODIFY : UppViewNames.CREATE,
        fieldPath: `carriersFamily`,
        singleSelect: !this.multiple
      };
    }
    return null;
  }

  private canLookupAirlineFamilies(): boolean {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === PERMISSION_VIEW_FAMILY) ?? false;
  }
}
