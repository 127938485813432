import { Action } from '@ngrx/store';
import {
  HotelChainsCreateRequest,
  HotelChainsSearchRequest,
  HotelChainsDeleteRequest,
  HotelChainsUpdateRequest,
  HotelPropertyCodesCreateRequest,
  HotelPropertyCodesSearchRequest,
  HotelPropertyCodesDeleteRequest,
  HotelPropertyCodesUpdateRequest
} from '../../families/model/family-request';
import { FamilyUi, FamilySearchCriteriaUI } from '../../families/model/';

export const FAMILIES_ACTION = {
  INIT_FAMILY_STATE: '[FAMILIES Action] Initializing Family state',
  CREATE_FAMILY_HOTEL_CHAINS: '[FAMILIES Action] Creating hotel chains family',
  CREATE_FAMILY_HOTEL_CODES: '[FAMILIES Action] Creating hotel property codes family',
  SET_CREATE_FAMILY_VALUE: '[FAMILIES Action] Setting create family values',
  FINISH_FAMILY_CREATION: '[FAMILIES Action] Finishing family creation',

  START_FAMILY_MODIFICATION: '[FAMILIES Action] Activating family modification',
  CANCEL_FAMILY_MODIFICATION: '[FAMILIES Action] Canceling family modification',
  SET_FAMILY_MODIFY_VALUE: '[FAMILIES Action] Setting family modify value',
  SET_FAMILY_UPDATE_SUCCESS_VALUES: '[FAMILIES Action] Setting family update success values',
  UPDATE_FAMILY_HOTEL_CHAINS: '[FAMILIES Action] Updating hotel chains family',
  UPDATE_FAMILY_HOTEL_CODES: '[FAMILIES Action] Updating hotel property codes family',
  FINISH_FAMILY_UPDATE: '[FAMILIES Action] Finishing family update',

  ACTIVATE_FAMILY_DISPLAY: '[FAMILIES Action] Activating family display',
  ACTIVATE_CREATED_FAMILY_DISPLAY: '[FAMILIES Action] Displaying created family display',
  SEARCH_FAMILY_HOTEL_CHAINS: '[FAMILIES Action] Searching hotel chains families',
  GET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS: '[FAMILIES Action] Getting all available hotel chains families',
  SEARCH_FAMILY_HOTEL_CODES: '[FAMILIES Action] Searching hotel property codes families',
  GET_ALL_AVAILABLE_FAMILY_HOTEL_CODES: '[FAMILIES Action] Getting all available hotel property codes families',
  SET_FAMILY_LIST: '[FAMILIES Action] Setting families',
  SET_LOOKUP_HOTEL_CHAINS_FAMILIES_LIST: '[FAMILIES Action] Setting lookup hotel chains families',
  SET_LOOKUP_HOTEL_PROPERTY_CODES_FAMILIES_LIST: '[FAMILIES Action] Setting lookup hotel peroperty codes families',
  SET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS: '[FAMILIES Action] Setting all available hotel family chains',
  SET_ALL_AVAILABLE_FAMILY_HOTEL_PROPERTY_CODES: '[FAMILIES Action] Setting all available hotel family property codes',
  CLEAR_FAMILY_LIST: '[FAMILIES Action] Clearing families',
  CLEAR_LOOKUP_HOTEL_CHAINS_FAMILY_LIST: '[FAMILIES Action] Clearing lookup hotel chains families',
  CLEAR_LOOKUP_HOTEL_PROPERTY_CODES_FAMILY_LIST: '[FAMILIES Action] Clearing lookup hotel property codes families',
  SET_FAMILY_SEARCH_VALUE: '[FAMILIES Action] Setting family search notification',

  DELETE_FAMILY_HOTEL_CHAINS: '[FAMILIES Action] Deleting hotel chains family',
  DELETE_FAMILY_HOTEL_CODES: '[FAMILIES Action] Deleting hotel property codess family',
  DELETE_FAMILY_CHAINS_CLEAR_STORE: '[FAMILIES Action] Clearing store after family hotel chains deletion',
  DELETE_FAMILY_CODES_CLEAR_STORE: '[FAMILIES Action] Clearing store after family hotel property codes deletion',
  DELETE_FAMILY_FROM_LIST: '[FAMILIES Action] Deleting family from list',
  DELETE_FAMILY_SEARCH_NOTIFICATION: '[FAMILIES Action] Deleting family search notification',
  DELETE_FAMILY_CREATE_NOTIFICATION: '[FAMILIES Action] Deleting family create notification',
  DELETE_FAMILY_MODIFY_NOTIFICATION: '[FAMILIES Action] Deleting family modify notification',
  DELETE_FAMILY_DISPLAY_NOTIFICATION: '[FAMILIES Action] Deleting family display notification',
  DELETE_FAMILY_LOOKUP_NOTIFICATION: '[FAMILIES Action] Deleting family lookup notification',

  MAKE_FAMILIES_SELECTION: '[FAMILIES Action] Make families selection',
  CLEAR_FAMILIES_SELECTION: '[FAMILIES Action] Clear families selection'
};

export class InitFamilyStateAction implements Action {
  readonly type = FAMILIES_ACTION.INIT_FAMILY_STATE;
  constructor(public payload: any) {}
}

export class CreateFamilyHotelChainsAction implements Action {
  readonly type = FAMILIES_ACTION.CREATE_FAMILY_HOTEL_CHAINS;
  constructor(public payload: { request: HotelChainsCreateRequest }) {}
}

export class CreateFamilyPropertyCodesAction implements Action {
  readonly type = FAMILIES_ACTION.CREATE_FAMILY_HOTEL_CODES;
  constructor(public payload: { request: HotelPropertyCodesCreateRequest }) {}
}

export class SetCreateFamilyValueAction implements Action {
  readonly type = FAMILIES_ACTION.SET_CREATE_FAMILY_VALUE;
  constructor(public payload: { familyUi: FamilyUi }) {}
}

export class FinishFamilyCreationAction implements Action {
  readonly type = FAMILIES_ACTION.FINISH_FAMILY_CREATION;
  constructor(public payload: { familyUi: FamilyUi }) {}
}

export class ActivateFamilyDisplayAction implements Action {
  readonly type = FAMILIES_ACTION.ACTIVATE_FAMILY_DISPLAY;
  constructor(public payload: { id: string }) {}
}

export class ActivateCreatedFamilyDisplayAction implements Action {
  readonly type = FAMILIES_ACTION.ACTIVATE_CREATED_FAMILY_DISPLAY;
  constructor(public payload: any) {}
}

export class UpdateFamilyHotelChainsAction implements Action {
  readonly type = FAMILIES_ACTION.UPDATE_FAMILY_HOTEL_CHAINS;
  constructor(public payload: { request: HotelChainsUpdateRequest }) {}
}

export class UpdateFamilyHotelPropertyCodesAction implements Action {
  readonly type = FAMILIES_ACTION.UPDATE_FAMILY_HOTEL_CODES;
  constructor(public payload: { request: HotelPropertyCodesUpdateRequest }) {}
}

export class SetFamilyModifyValueAction implements Action {
  readonly type = FAMILIES_ACTION.SET_FAMILY_MODIFY_VALUE;
  constructor(public payload: { familyUi: FamilyUi }) {}
}

export class StartFamilyModificationAction implements Action {
  readonly type = FAMILIES_ACTION.START_FAMILY_MODIFICATION;
  constructor(public payload: { id: string | undefined }) {}
}

export class SetFamilyUpdateSuccessValuesAction implements Action {
  readonly type = FAMILIES_ACTION.SET_FAMILY_UPDATE_SUCCESS_VALUES;
  constructor(public payload: { familyUi: FamilyUi }) {}
}

export class CancelFamilyModificationAction implements Action {
  readonly type = FAMILIES_ACTION.CANCEL_FAMILY_MODIFICATION;
  constructor(public payload: any) {}
}

export class FinishFamilyUpdateAction implements Action {
  readonly type = FAMILIES_ACTION.FINISH_FAMILY_UPDATE;
  constructor(public payload: { updatedFamilyUi: FamilyUi }) {}
}

export class SearchFamilyHotelChainsAction implements Action {
  readonly type = FAMILIES_ACTION.SEARCH_FAMILY_HOTEL_CHAINS;
  constructor(public payload: { request: HotelChainsSearchRequest; lookup?: boolean }) {}
}

export class GetAllAvailableFamilyHotelChainsAction implements Action {
  readonly type = FAMILIES_ACTION.GET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS;
  constructor(public payload: any) {}
}

export class SearchFamilyHotelPropertyCodesAction implements Action {
  readonly type = FAMILIES_ACTION.SEARCH_FAMILY_HOTEL_CODES;
  constructor(public payload: { request: HotelPropertyCodesSearchRequest; lookup?: boolean }) {}
}

export class GetAllAvailableFamilyHotelPropertyCodesAction implements Action {
  readonly type = FAMILIES_ACTION.GET_ALL_AVAILABLE_FAMILY_HOTEL_CODES;
  constructor(public payload: any) {}
}

export class SetFamiliesListAction implements Action {
  readonly type = FAMILIES_ACTION.SET_FAMILY_LIST;
  constructor(public payload: { familyUis: FamilyUi[] }) {}
}

export class SetLookupHotelChainsFamiliesListAction implements Action {
  readonly type = FAMILIES_ACTION.SET_LOOKUP_HOTEL_CHAINS_FAMILIES_LIST;
  constructor(public payload: { familyUis: FamilyUi[] }) {}
}

export class SetLookupHotelPropertyCodesFamiliesListAction implements Action {
  readonly type = FAMILIES_ACTION.SET_LOOKUP_HOTEL_PROPERTY_CODES_FAMILIES_LIST;
  constructor(public payload: { familyUis: FamilyUi[] }) {}
}

export class SetAllAvailableFamilyHotelChainsAction implements Action {
  readonly type = FAMILIES_ACTION.SET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS;
  constructor(public payload: { familyUis: FamilyUi[] }) {}
}

export class SetAllAvailableFamilyHotelPropertyCodesAction implements Action {
  readonly type = FAMILIES_ACTION.SET_ALL_AVAILABLE_FAMILY_HOTEL_PROPERTY_CODES;
  constructor(public payload: { familyUis: FamilyUi[] }) {}
}

export class ClearFamiliesAction implements Action {
  readonly type = FAMILIES_ACTION.CLEAR_FAMILY_LIST;
  constructor(public payload: any) {}
}

export class ClearLookupHotelChainsFamiliesAction implements Action {
  readonly type = FAMILIES_ACTION.CLEAR_LOOKUP_HOTEL_CHAINS_FAMILY_LIST;
  constructor(public payload: any) {}
}

export class ClearLookupHotelPropertyCodesFamiliesAction implements Action {
  readonly type = FAMILIES_ACTION.CLEAR_LOOKUP_HOTEL_PROPERTY_CODES_FAMILY_LIST;
  constructor(public payload: any) {}
}

export class SetFamilySearchValueAction implements Action {
  readonly type = FAMILIES_ACTION.SET_FAMILY_SEARCH_VALUE;
  constructor(public payload: { familySearchCriteria: FamilySearchCriteriaUI }) {}
}

export class DeleteFamilyHotelChainsAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_HOTEL_CHAINS;
  constructor(public payload: { request: HotelChainsDeleteRequest | undefined }) {}
}

export class DeleteFamilyHotelPropertyCodesAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_HOTEL_CODES;
  constructor(public payload: { request: HotelPropertyCodesDeleteRequest | undefined }) {}
}

export class DeleteFamilyChainsClearStoreAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_CHAINS_CLEAR_STORE;
  constructor(public payload: any) {}
}

export class DeleteFamilyCodesClearStoreAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_CODES_CLEAR_STORE;
  constructor(public payload: any) {}
}

export class DeleteFamilyFromListAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_FROM_LIST;
  constructor(public payload: { familyUi: FamilyUi }) {}
}

export class DeleteFamilyModifyNotificationAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_MODIFY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFamilyCreateNotificationAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_CREATE_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFamilyDisplayNotificationAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_DISPLAY_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFamilySearchNotificationAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_SEARCH_NOTIFICATION;
  constructor(public payload: any) {}
}

export class DeleteFamilyLookupNotificationAction implements Action {
  readonly type = FAMILIES_ACTION.DELETE_FAMILY_LOOKUP_NOTIFICATION;
  constructor(public payload: any) {}
}

export class SelectFamiliesAction implements Action {
  readonly type = FAMILIES_ACTION.MAKE_FAMILIES_SELECTION;
  constructor(public payload: any) {}
}
export class ClearFamiliesSelectionAction implements Action {
  readonly type = FAMILIES_ACTION.CLEAR_FAMILIES_SELECTION;
  constructor(public payload: any) {}
}

export type FamiliesActions =
  | InitFamilyStateAction
  | CreateFamilyHotelChainsAction
  | CreateFamilyPropertyCodesAction
  | SetCreateFamilyValueAction
  | UpdateFamilyHotelChainsAction
  | UpdateFamilyHotelPropertyCodesAction
  | SetFamilyModifyValueAction
  | StartFamilyModificationAction
  | SetFamilyUpdateSuccessValuesAction
  | CancelFamilyModificationAction
  | FinishFamilyUpdateAction
  | FinishFamilyCreationAction
  | ActivateFamilyDisplayAction
  | ActivateCreatedFamilyDisplayAction
  | SearchFamilyHotelChainsAction
  | GetAllAvailableFamilyHotelChainsAction
  | SearchFamilyHotelPropertyCodesAction
  | GetAllAvailableFamilyHotelPropertyCodesAction
  | SetFamiliesListAction
  | SetLookupHotelChainsFamiliesListAction
  | SetLookupHotelPropertyCodesFamiliesListAction
  | ClearFamiliesAction
  | SetFamilySearchValueAction
  | DeleteFamilyHotelChainsAction
  | DeleteFamilyHotelPropertyCodesAction
  | DeleteFamilyChainsClearStoreAction
  | DeleteFamilyCodesClearStoreAction
  | DeleteFamilyFromListAction
  | DeleteFamilyCreateNotificationAction
  | DeleteFamilyDisplayNotificationAction
  | DeleteFamilySearchNotificationAction
  | DeleteFamilyModifyNotificationAction
  | SelectFamiliesAction
  | ClearFamiliesSelectionAction
  | ClearLookupHotelChainsFamiliesAction
  | ClearLookupHotelPropertyCodesFamiliesAction
  | DeleteFamilyLookupNotificationAction;
