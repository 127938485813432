import { Notification } from '../../model/notification';
import { JourneyUi, initialJourneyUi } from '../../flights/model/flight-rule-ui';
import {
  JourneySearchCriteriaUi,
  initialFlightRuleSearchCriteria
} from '../../flights/model/flight-rule-search-criteria';

export const FLIGHTS_FEATURE = 'flightUppUi';

export interface FlightsState {
  flightRules: JourneyUi[];
  flightRule: {
    create: FlightRuleStatus;
    modify: FlightRuleStatus;
    display: FlightRuleStatus;
    search: {
      sending: boolean;
      value: JourneySearchCriteriaUi;
    };
    delete: {
      sending: boolean;
    };
  };
  notification?: Notification;
}

export interface FlightRuleStatus {
  sending: boolean;
  active?: boolean;
  value: JourneyUi;
  updatedRule?: JourneyUi;
}

export const initialFlightRuleStatus: FlightRuleStatus = {
  sending: false,
  active: false,
  value: initialJourneyUi
};

export const initialFlightState: FlightsState = {
  flightRules: [],
  flightRule: {
    create: initialFlightRuleStatus,
    modify: initialFlightRuleStatus,
    display: initialFlightRuleStatus,
    search: {
      sending: false,
      value: initialFlightRuleSearchCriteria
    },
    delete: {
      sending: false
    }
  },
  notification: {}
};

export const getInitialFlightState = (): FlightsState => {
  const initialRule = {
    sending: false,
    active: false,
    value: initialJourneyUi
  };
  return {
    flightRules: [],
    flightRule: {
      create: initialRule,
      modify: initialRule,
      display: initialRule,
      search: {
        sending: false,
        value: initialFlightRuleSearchCriteria
      },
      delete: {
        sending: false
      }
    },
    notification: {}
  };
};
