import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { RulesPostRequest } from 'build/openapi/model/models';
import { Observable } from 'rxjs';
import { FormComponent } from '../../model/FormComponent';
import { NavigationService } from '../../service/core/navigation.service';
import { UppComponent } from '../../service/model';
import { UserDetailsService } from '../../service/user-details.service';
import {
  ActivateCreatedFareRuleDisplayAction,
  CreateFareRuleAction,
  DeleteFareRuleCreateNotificationAction,
  SetCreateFareRuleValueAction
} from '../../store/fares/fares-action';
import {
  selectFareRuleCreateNotification,
  selectFareRuleCreateSending,
  selectFareRuleCreateValue
} from '../../store/fares/fares-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { FareFormValidator } from '../model/fare-form-validator';
import { convertFareRuleUiV2ToFareRuleUi, convertToFareRule } from '../model/fare-rule-converter';
import { FareRuleUi, fareUiEquals, initialFareRuleUi } from '../model/fare-rule-ui';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-fares-create',
  templateUrl: './fares-create.component.html',
  styleUrls: ['./fares-create.component.scss']
})
export class FaresCreateComponent implements OnInit, FormComponent {
  notification$: Observable<UppNotification | undefined>;
  mainMessages: NotificationMessages;

  fareForm: UntypedFormGroup | null;
  fareRuleUi: FareRuleUi = initialFareRuleUi;
  readonly = false;
  sendingStatus!: boolean;
  showFaresV2: boolean;

  uppComponent = UppComponent.FARES_CREATE;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly fareFormValidator: FareFormValidator,
    private readonly router: Router,
    private readonly store: Store<any>,
    private readonly configurationService: ConfigurationService
  ) {
    this.notification$ = this.store.pipe(select(selectFareRuleCreateNotification));
    this.store.pipe(select(selectFareRuleCreateSending)).subscribe(sending => (this.sendingStatus = sending));

    this.showFaresV2 = this.configurationService.getParameter<boolean>(FeatureFlags.dynamicExclusionV2);
    if (this.showFaresV2) {
      this.mainMessages = {
        error: $localize`:@@upp.dynamicExclusions.create.mainErrorText:Creation of dynamic exclusion rule failed due to the following errors:`,
        warning: $localize`:@@upp.dynamicExclusions.create.mainWarningText:Warnings were generated during the creation process of dynamic exclusion rule:`,
        success: $localize`:@@upp.dynamicExclusions.create.mainSuccessText:Well done! You get this message about your successful dynamic exclusion rule creation`,
        linkSuccessText: $localize`:@@upp.dynamicExclusions.create.linkSuccessText:Display new dynamic exclusion rule: `
      };
    } else {
      this.mainMessages = {
        error: $localize`:@@upp.fares.create.mainErrorText:Creation of fare rule failed due to the following errors:`,
        warning: $localize`:@@upp.fares.create.mainWarningText:Warnings were generated during the creation process of fare rule:`,
        success: $localize`:@@upp.fares.create.mainSuccessText:Well done! You get this message about your successful Fare Rule creation`,
        linkSuccessText: $localize`:@@upp.fares.create.linkSuccessText:Display new Fare Rule: `
      };
    }
    this.fareRuleUi = initialFareRuleUi;
  }

  ngOnInit(): void {
    if (this.showFaresV2) {
      this.navigationService.setSelectedMenuTitle(
        $localize`:@@upp.dynamicExclusions.create.navigationTitle:Dynamic Exclusion Rule Creation`
      );
    } else {
      this.navigationService.setSelectedMenuTitle($localize`:@@upp.fares.create.navigationTitle:Fare Rule Creation`);
    }
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.fareForm = this.formBuilder.group({});
    this.store.pipe(select(selectFareRuleCreateValue)).subscribe(fare => {
      if (fare) {
        this.fareRuleUi = {...fare};
        this.fareForm?.reset();
      }
    });
  }

  saveData() {
    this.fareForm?.markAllAsTouched();
    if (this.fareForm?.valid) {
      const request = this.createRequest();
      request.rule = this.userDetailsService.assignRuleEntity(request.rule);
      this.storeUnsavedChanges();
      this.store.dispatch(new CreateFareRuleAction({ request }));
    }
  }

  createRequest(): RulesPostRequest {
    return {
      rule: convertToFareRule(this.getFormValue())
    };
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetCreateFareRuleValueAction({ value: this.getFormValue() }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteFareRuleCreateNotificationAction({}));
  }

  showFareRuleInDisplay() {
    this.store.dispatch(new ActivateCreatedFareRuleDisplayAction({}));
    this.router.navigate(['fares/display']);
  }

  saveButtonEnabled(): boolean {
    if (this.fareForm && this.fareFormValidator.hasMandatoryData(this.fareForm)) {
      return this.fareForm.valid;
    }
    return false;
  }

  hasUnsavedChanges(): boolean {
    return !fareUiEquals(initialFareRuleUi, this.getFormValue());
  }

  private getFormValue(): FareRuleUi {
    return this.showFaresV2 ? convertFareRuleUiV2ToFareRuleUi(this.fareForm?.value) : this.fareForm?.value;
  }
}
