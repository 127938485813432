import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PosSearchComponent } from './pos-search/pos-search.component';
import { PosDisplayComponent } from './pos-display/pos-display.component';
import { PosCreateComponent } from './pos-create/pos-create.component';
import { PosModifyComponent } from './pos-modify/pos-modify.component';
import { CanDeactivatePosCreateGuard } from '../core/util/can-deactivate-pos-create.guard';
import { CanDeactivatePosModifyGuard } from '../core/util/can-deactivate-pos-modify.guard';
import { CanDeactivatePosSearchGuard } from '../core/util/can-deactivate-pos-search.guard';
import { CanManagePosGuard } from '../core/util/routing-guards/pos/can-manage-pos-guard';
import { CanViewPosGuard } from '../core/util/routing-guards/pos/can-view-pos-guard';
import { UppComponentNames } from '../service/model';
import { LoggedInGuard } from '../core/util/routing-guards/logged-in.guard';

const posRoutes: Routes = [
  {
    path: UppComponentNames.POS,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'search',
        component: PosSearchComponent,
        canDeactivate: [CanDeactivatePosSearchGuard],
        canActivate: [CanViewPosGuard]
      },
      {
        path: 'display/:id',
        component: PosDisplayComponent,
        canActivate: [CanViewPosGuard]
      },
      {
        path: 'modify/:id',
        component: PosModifyComponent,
        canDeactivate: [CanDeactivatePosModifyGuard],
        canActivate: [CanManagePosGuard]
      },
      {
        path: 'modify',
        component: PosModifyComponent,
        canDeactivate: [CanDeactivatePosModifyGuard],
        canActivate: [CanManagePosGuard]
      },
      {
        path: 'display',
        component: PosDisplayComponent,
        canActivate: [CanViewPosGuard]
      },
      {
        path: 'create',
        component: PosCreateComponent,
        canDeactivate: [CanDeactivatePosCreateGuard],
        canActivate: [CanManagePosGuard]
      },
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: '**', redirectTo: 'search', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(posRoutes)],
  exports: [RouterModule]
})
export class PointsOfSaleRoutingModule {}
