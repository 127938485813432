import { AbstractControl, ValidationErrors } from '@angular/forms';

export const integerNumberValidator = (control: AbstractControl): ValidationErrors | null => {
    const numericValue = Number(control.value);
    if (control.value === null || control.value === undefined) {
        return null;
    }
    if (Number.isInteger(numericValue)) {
        return null;
    }
    return { integerNumberValidator: { currentValue: numericValue } };
};
