import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState, CORE_FEATURE } from './state';

export const selectCore = createFeatureSelector<CoreState>(CORE_FEATURE);

export const selectClpConfig = createSelector(selectCore, (state: CoreState) => state.clpConfig);

export const selectLoggedIn = createSelector(selectCore, (state: CoreState) => state.loggedIn);

export const selectLoggedInUser = createSelector(selectCore, (state: CoreState) => (state ? state.user : undefined));

export const selectClpError = createSelector(selectCore, (state: CoreState) => state.error);

export const selectConfiguration = createSelector(selectCore, (state: CoreState) => state.configuration);
