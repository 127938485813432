<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<div class="mb-2">
  <form [formGroup]="searchCriteriaForm" id="hotelSearchCriteriaForm" (ngSubmit)="searchClicked()">
    <div class="row">
      <div class="col text-end mb-2">
        <button
          i18n="@@upp.global.button.clear"
          id="clearDisabled"
          type="button"
          [disabled]="sendingStatus"
          class="btn btn-outline-primary btn-sm"
          (click)="clearClicked()"
        >
          Clear
        </button>
      </div>
    </div>

    <div class="mb-4">
      <label i18n="@@upp.hotels.display.nameLabel" for="name" class="form-label">Name </label>
      <input
        type="text"
        class="form-control"
        id="name"
        formControlName="name"
        [ngClass]="{ 'is-invalid': !searchCriteriaForm.controls['name'].valid }"
        placeholder="Hotel rule name"
        i18n-placeholder="@@upp.hotels.common.namePlaceholder"
        ngbTooltip="Enter a hotel rule name, max. 30 alpha numeric without spaces"
        i18n-ngbTooltip="@@upp.hotels.common.nameTooltip"
        oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
      />

      <div *ngIf="!searchCriteriaForm.controls['name'].valid" class="invalid mt-1">
        <div *ngIf="searchCriteriaForm.controls['name'].errors.maxlength">
          <ng-container i18n="@@upp.validation.hotels.search.maximumCharacters"
            >Maximum number of characters acceptable:</ng-container
          >
          {{ searchCriteriaForm.controls['name'].errors.maxlength?.requiredLength }}
        </div>
        <div
          i18n="@@upp.validation.hotels.search.typeOfCharacters"
          *ngIf="
            searchCriteriaForm.controls['name'].errors.pattern && !searchCriteriaForm.controls['name'].errors.maxlength
          "
        >
          Only alphanumeric characters are acceptable
        </div>
      </div>

      <div class="row mb-1 mt-4">
        <div class="col">
          <div class="d-flex flex-wrap">
            <div *ngFor="let criteria of dynamicSearchCriteria">
              <button
                type="button"
                [id]="criteria.name"
                [ngClass]="
                  criteria.active
                    ? 'btn btn-primary btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
                    : 'btn btn-outline-primary btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
                "
                (click)="toggleCriteriaButtonAndControl(criteria.name)"
                [disabled]="criteria.disabled"
              >
                {{ criteria.title }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div *ngIf="isCriteriaActive(availableDynamicCriteria.ACTION_TYPE)" role="radiogroup" aria-labelledby="actionTypeSearchHotels">
            <hr class="separator" />
            <div class="d-flex flex-wrap">
              <span i18n="@@upp.hotels.search.actionTypeLabel" class="label-criteria" id="actionTypeSearchHotels">Action Type</span>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="actionTypeAll"
                  value="all"
                  name="actionType"
                  formControlName="actionType"
                />
                <label i18n="@@upp.global.labels.all" class="form-check-label" for="actionTypeAll">
                  All
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="actionTypeExclude"
                  value="exclude"
                  name="actionType"
                  formControlName="actionType"
                />
                <label i18n="@@upp.global.labels.exclude" class="form-check-label" for="actionTypeExclude">
                  Exclude
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="actionTypeLightExclude"
                  value="light_exclude"
                  name="actionType"
                  formControlName="actionType"
                />
                <label
                  i18n="@@upp.hotels.search.lightExcludeLabel"
                  class="form-check-label"
                  for="actionTypeLightExclude"
                >
                  Light Exclude
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="actionTypeInclude"
                  value="include"
                  name="actionType"
                  formControlName="actionType"
                />
                <label i18n="@@upp.global.labels.include" class="form-check-label" for="actionTypeInclude">
                  Include
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div *ngIf="isCriteriaActive(availableDynamicCriteria.RULE_STATUS)" role="radiogroup" aria-labelledby="ruleStatusSearchHotels">
            <hr class="separator" />
            <div class="d-flex flex-wrap">
              <span i18n="@@upp.hotels.search.ruleStatusLabel" class="label-criteria" id="ruleStatusSearchHotels">Rule Status</span>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="ruleStatusBoth"
                  value="all"
                  name="ruleStatus"
                  formControlName="ruleStatus"
                />
                <label i18n="@@upp.global.labels.both" class="form-check-label" for="ruleStatusBoth">
                  Both
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="ruleStatusActive"
                  value="active"
                  name="ruleStatus"
                  formControlName="ruleStatus"
                />
                <label i18n="@@upp.hotels.search.table.active" class="form-check-label" for="ruleStatusActive">
                  Active
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="ruleStatusInactive"
                  value="inactive"
                  name="ruleStatus"
                  formControlName="ruleStatus"
                />
                <label i18n="@@upp.hotels.search.table.inactive" class="form-check-label" for="ruleStatusInactive">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div *ngIf="isCriteriaActive(availableDynamicCriteria.COMMISSIONABLE)" role="radiogroup" aria-labelledby="commissionableSearchHotels">
            <hr class="separator" />
            <div class="d-flex flex-wrap">
              <span i18n="@@upp.hotels.details.commissionableLabel" class="label-criteria" id="commissionableSearchHotels">Commissionable</span>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="commissionableYes"
                  value="yes"
                  name="commissionable"
                  formControlName="commissionable"
                />
                <label i18n="@@upp.global.labels.yes" class="form-check-label" for="commissionableYes">
                  Yes
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="commissionableNo"
                  value="no"
                  name="commissionable"
                  formControlName="commissionable"
                />
                <label i18n="@@upp.global.labels.no" class="form-check-label" for="commissionableNo">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button
        i18n="@@upp.global.button.search"
        *ngIf="!sendingStatus"
        id="search"
        type="submit"
        class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
        [disabled]="!searchCriteriaForm.valid"
      >
        Search
      </button>

      <button
        i18n="@@upp.global.button.search"
        *ngIf="sendingStatus"
        id="searchIsSending"
        type="submit"
        disabled
        class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
      >
        Search
      </button>
    </div>
  </form>
</div>
