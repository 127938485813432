import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FamiliesRoutingModule } from './families-routing.module';
import { FamiliesCreateComponent } from './families-create/families-create.component';
import { FamiliesSearchComponent } from './families-search/families-search.component';
import { FamiliesSearchCriteriaComponent } from './families-search/families-search-criteria/families-search-criteria.component';
import { FamiliesDisplayComponent } from './families-display/families-display.component';
import { FamiliesModifyComponent } from './families-modify/families-modify.component';
import { FamiliesAppComponent } from './families-app.component';
import { FamiliesDetailsComponent } from './families-details/families-details.component';
import { ComponentsModule } from '../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PointsOfSaleModule } from '../points-of-sale/points-of-sale.module';
import { PipesModule } from '../core/util/pipes/pipes.module';
import { AirFamiliesSearchComponent } from './air/air-families-search/air-families-search.component';
import { AirFamilyFormComponent } from './air/air-family-form/air-family-form.component';

@NgModule({
  declarations: [
    FamiliesCreateComponent,
    FamiliesSearchComponent,
    FamiliesSearchCriteriaComponent,
    FamiliesDetailsComponent,
    FamiliesDisplayComponent,
    FamiliesModifyComponent,
    FamiliesAppComponent,
    AirFamiliesSearchComponent,
    AirFamilyFormComponent
  ],
  imports: [
    CommonModule,
    FamiliesRoutingModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PointsOfSaleModule,
    PipesModule
  ]
})
export class FamiliesModule {}
