@if (showFaresV2) {
  <h1 i18n="@@upp.dynamicExclusions.display.title">Display Dynamic Exclusion Rule</h1>
}
@else {
  <h1 i18n="@@upp.fares.display.title">Display Fare Rule</h1>
}

<ama-upp-notification
    [mainMessages]="mainMessages"
    [notification]="notification$ | async"
    (closeNotificationEvent)="closeNotification()">
</ama-upp-notification>

@if (showFaresV2) {
<ama-ng-upp-fares-details-v2 [fareDetailsForm]="fareForm" [fareDetails]="fareRule" [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-fares-details-v2>
}
@else {
<ama-ng-upp-fares-details [fareDetailsForm]="fareForm" [fareDetails]="fareRule" [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-fares-details>
}

<div class="d-flex justify-content-end">
    <button
        i18n="@@upp.global.button.modify"
        type="button"
        [disabled]="!modifyButtonEnabled()"
        class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
        (click)="modifyFare()"> Modify </button>
</div>
