import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../components/components.module';
import { FormValidatorsModule } from '../components/form-validators.module';
import { DfSelectModule } from '@design-factory/design-factory';
import { PointsOfSaleModule } from '../points-of-sale/points-of-sale.module';
import { HotelsCreateComponent } from './hotels-create/hotels-create.component';
import { HotelsDisplayComponent } from './hotels-display/hotels-display.component';
import { HotelsAppComponent } from './hotels-app.component';
import { HotelsRoutingModule } from './hotels-routing.module';
import { HotelsDetailsComponent } from './hotels-details/hotels-details.component';
import { HotelsSearchComponent } from './hotels-search/hotels-search.component';
import { HotelsSearchCriteriaComponent } from './hotels-search/hotels-search-criteria/hotels-search-criteria.component';
import { HotelsModifyComponent } from './hotels-modify/hotels-modify.component';
import { PipesModule } from '../core/util/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ComponentsModule,
    FormValidatorsModule,
    DfSelectModule,
    PointsOfSaleModule,
    HotelsRoutingModule,
    PipesModule
  ],
  declarations: [
    HotelsCreateComponent,
    HotelsModifyComponent,
    HotelsDisplayComponent,
    HotelsAppComponent,
    HotelsDetailsComponent,
    HotelsSearchComponent,
    HotelsSearchCriteriaComponent
  ],
  exports: [HotelsCreateComponent, HotelsDisplayComponent]
})
export class HotelsModule {}
