import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FamilySearchCriteriaUI, initialFamilySearchCriteria } from '../../model/family-ui';
import { Store, select } from '@ngrx/store';
import { selectFamilySearchValue } from '../../../store/families/families-selector';
import { SetFamilySearchValueAction } from '../../../store/families/families-action';
import { UppValidatorService } from '../../../service/upp-validator.service';
import { VALIDATORS } from '../../model/family-service-elements';
import { UppComponentNames } from '../../../service/model';
import { FormElements } from '../../../model/common-elements';
import { alphanumericSpaceMax30 } from '../../../service/model/common/validators';

@Component({
  selector: 'ama-ng-upp-families-search-criteria',
  templateUrl: './families-search-criteria.component.html',
  styleUrls: ['./families-search-criteria.component.scss']
})
export class FamiliesSearchCriteriaComponent implements OnInit {
  @Output() searchFamilies = new EventEmitter<FamilySearchCriteriaUI>();
  @Output() familyHotelType = new EventEmitter<string>();

  @Input() sendingStatus?: boolean;
  @Input() readonly = false;
  @Input() lookup?: boolean;
  @Input() initialFamilyType!: string;

  searchCriteriaForm!: UntypedFormGroup;
  searchCriteria: FamilySearchCriteriaUI = initialFamilySearchCriteria;

  hotelType: string | undefined;

  readonly elements = FormElements;
  readonly COMPONENT_NAME = UppComponentNames.FAMILIES;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly validatorService: UppValidatorService
  ) {
    this.validatorService.setComponentValidation(UppComponentNames.FAMILIES, VALIDATORS);
  }

  ngOnInit(): void {
    if (!this.lookup) {
      this.store.pipe(select(selectFamilySearchValue)).subscribe(value => (this.searchCriteria = value));
    }
    this.createAndLoadSearchForm();
    if (this.initialFamilyType) {
      this.searchCriteria = this.getInitialFamilySearchCriteria();
      this.changeHotelType(this.initialFamilyType);
      this.searchCriteriaForm?.patchValue(this.searchCriteria);
    } else {
      this.hotelType = this.searchCriteria.hotelFamilyType;
    }
  }

  createAndLoadSearchForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.maxLength(30), Validators.pattern(alphanumericSpaceMax30)]),
      hotelFamilyType: this.formBuilder.control({ value: 'chains', disabled: this.readonly }),
      hotelChains: this.formBuilder.control('', {
        validators: this.validatorService.getValidatorsForControl(UppComponentNames.FAMILIES, 'hotelChains', false)
      }),
      hotelPropertyCodes: this.formBuilder.control('', {
        validators: this.validatorService.getValidatorsForControl(UppComponentNames.FAMILIES, 'hotelPropertyCodes', false)
      })
    });
    this.searchCriteriaForm.patchValue(this.searchCriteria);
  }

  searchClicked() {
    this.searchFamilies.emit(this.searchCriteriaForm?.value);
    this.familyHotelType.emit(this.hotelType);
  }

  storeUnsavedFamilySearch() {
    this.store.dispatch(new SetFamilySearchValueAction({ familySearchCriteria: this.searchCriteriaForm?.value }));
  }

  clearClicked() {
    this.searchCriteria = this.getInitialFamilySearchCriteria();
    this.searchCriteriaForm?.patchValue(this.searchCriteria);
    this.searchCriteriaForm?.get(FormElements.NAME)?.setValue('');

    if (this.searchCriteriaForm?.contains(FormElements.CHAINS)) {
      this.searchCriteriaForm?.get(FormElements.CHAINS)?.setValue('');
    }
    if (this.searchCriteriaForm?.contains(FormElements.PROPERTY_CODES)) {
      this.searchCriteriaForm?.get(FormElements.PROPERTY_CODES)?.setValue('');
    }
  }

  changeHotelType(value: string) {
    this.hotelType = value;

    if (this.searchCriteriaForm?.contains(FormElements.CHAINS)) {
      this.searchCriteriaForm?.get(FormElements.CHAINS)?.setValue('');
    }
    if (this.searchCriteriaForm?.contains(FormElements.PROPERTY_CODES)) {
      this.searchCriteriaForm?.get(FormElements.PROPERTY_CODES)?.setValue('');
    }
  }

  isHotelCriteriaActive(hotelType: string): boolean {
    return hotelType === this.hotelType;
  }

  getInitialFamilySearchCriteria() {
    const result = { ...initialFamilySearchCriteria };

    if (this.initialFamilyType) {
      result.hotelFamilyType = this.initialFamilyType;
    }

    return result;
  }
}
