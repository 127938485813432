import { AbstractControl } from '@angular/forms';

export const isIncludeExcludeFilled = (control: AbstractControl, names: string[]) => {
  let result = false;
  names.forEach(name => {
    const val = control.get(name)?.value ?? '';

    if (val && val.length > 0) {
      result = true;
    }
  });
  return result;
};
