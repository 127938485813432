import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../service/core/navigation.service';

@Component({
  selector: 'ama-ng-upp-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  doclink =
    'https://servicehub.amadeus.com/c/portal/view-solution/' +
    '557253686/en_US/unified-preferencing-platform-upp-entry-point';

  constructor(private readonly navigationService: NavigationService) {}

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('About');
  }
}
