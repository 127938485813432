import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketsSearchComponent } from './markets-search/markets-search.component';
import { MarketsDisplayComponent } from './markets-display/markets-display.component';
import { MarketsModifyComponent } from './markets-modify/markets-modify.component';
import { MarketsCreateComponent } from './markets-create/markets-create.component';
import { CanDeactivateMarketCreateGuard } from '../core/util/can-deactivate-market-create.guard';
import { CanDeactivateMarketModifyGuard } from '../core/util/can-deactivate-market-modify.guard';
import { CanManageMarketGuard } from '../core/util/routing-guards/markets/can-manage-market-guard';
import { CanViewMarketGuard } from '../core/util/routing-guards/markets/can-view-market-guard';
import { CanDeactivateMarketSearchGuard } from '../core/util/can-deactivate-market-search.guard';
import { UppComponentNames } from '../service/model';
import { LoggedInGuard } from '../core/util/routing-guards/logged-in.guard';

const marketsRoutes: Routes = [
  {
    path: UppComponentNames.MARKETS,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'search',
        component: MarketsSearchComponent,
        canDeactivate: [CanDeactivateMarketSearchGuard],
        canActivate: [CanViewMarketGuard]
      },
      {
        path: 'display',
        component: MarketsDisplayComponent,
        canActivate: [CanViewMarketGuard]
      },
      {
        path: 'display/:id',
        component: MarketsDisplayComponent,
        canActivate: [CanViewMarketGuard]
      },
      {
        path: 'modify',
        component: MarketsModifyComponent,
        canDeactivate: [CanDeactivateMarketModifyGuard],
        canActivate: [CanManageMarketGuard]
      },
      {
        path: 'modify/:id',
        component: MarketsModifyComponent,
        canDeactivate: [CanDeactivateMarketModifyGuard],
        canActivate: [CanManageMarketGuard]
      },
      {
        path: 'create',
        component: MarketsCreateComponent,
        canDeactivate: [CanDeactivateMarketCreateGuard],
        canActivate: [CanManageMarketGuard]
      },
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: '**', redirectTo: 'search', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(marketsRoutes)],
  exports: [RouterModule]
})
export class MarketsRoutingModule {}
