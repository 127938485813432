@if (showFaresV2) {
  <h1 i18n="@@upp.dynamicExclusions.modify.title">Modify Dynamic Exclusion Rule</h1>
}
@else {
  <h1 i18n="@@upp.fares.modify.title">Modify Fare Rule</h1>
}

<div class="container-fluid">
    <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..."
        i18n-message="@@upp.global.messages.loading">
    </ama-loading-alert>
</div>

<ama-upp-notification
    [mainMessages]="mainMessages"
    [notification]="notification$ | async"
    (closeNotificationEvent)="closeNotification()">
</ama-upp-notification>

@if (showFaresV2) {
<ama-ng-upp-fares-details-v2 [fareDetailsForm]="fareForm" [fareDetails]="fareDetail" [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-fares-details-v2>
}
@else {
<ama-ng-upp-fares-details [fareDetailsForm]="fareForm" [fareDetails]="fareDetail" [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-fares-details>
}

<div *ngIf="!sendingStatus" class="d-flex justify-content-end">
    <button
        i18n="@@upp.global.button.cancel"
        type="button"
        class="btn btn-outline-primary mb-2 mb-md-0 me-2"
        (click)="cancelModification()"> Cancel </button>
    <button
        i18n="@@upp.global.button.save"
        type="submit"
        class="btn btn-primary mb-2 mb-md-0 me-2"
        [disabled]="!showFaresV2 && !saveEnabled()"
        (click)="saveModification()"> Save </button>
</div>

<div *ngIf="sendingStatus" class="d-flex justify-content-end">
    <button
        i18n="@@upp.global.button.cancel"
        type="button"
        disabled
        class="btn btn-outline-primary mb-2 mb-md-0 me-2"> Cancel </button>
    <button i18n="@@upp.global.button.save" type="button" disabled
        class="btn btn-primary mb-2 mb-md-0 me-2"> Save </button>
</div>
