import { Notification } from '../../model/notification';
import { HotelRuleUi, initialHotelRuleUi } from '../../hotels/model/hotel-rule-ui';
import {
  HotelRuleSearchCriteriaUi,
  initialHotelRuleSearchCriteria
} from '../../hotels/model/hotel-rule-search-criteria';

export const HOTELS_FEATURE = 'hotelUppUi';

export interface HotelsState {
  hotelRules: HotelRuleUi[];
  hotelRule: {
    create: HotelRuleStatus;
    modify: HotelRuleStatus;
    display: HotelRuleStatus;
    search: {
      sending: boolean;
      value: HotelRuleSearchCriteriaUi;
    };
    delete: {
      sending: boolean;
    };
  };
  notification?: Notification;
}

export interface HotelRuleStatus {
  sending: boolean;
  active?: boolean;
  value: HotelRuleUi;
  updatedRule?: HotelRuleUi;
}

export const initialHotelRuleStatus: HotelRuleStatus = {
  sending: false,
  active: false,
  value: initialHotelRuleUi
};

export const initialHotelState: HotelsState = {
  hotelRules: [],
  hotelRule: {
    create: initialHotelRuleStatus,
    modify: initialHotelRuleStatus,
    display: initialHotelRuleStatus,
    search: {
      sending: false,
      value: initialHotelRuleSearchCriteria
    },
    delete: {
      sending: false
    }
  },
  notification: {}
};
