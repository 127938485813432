import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoggedInUser } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';
import { ResolutionNavHelper } from '../../service/core/resolution-nav-helper.service';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectNavigation, selectShowPartitionSwitchPopup } from '../../store/navigation/navigation-selector';
import { MetaGroupSwitchAlertDisplayed } from '../../store/navigation/navigation-actions';

@Component({
  selector: 'ama-ng-upp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() user?: LoggedInUser;
  @Input() applicationName?: string;

  @Output() signOffClicked = new EventEmitter<void>();

  menuTitle = '';
  displayAlertPartitionChange = false;
  lookup = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly navigationService: NavigationService,
    private readonly config: NgbDropdownConfig,
    private readonly resolutionNavHelper: ResolutionNavHelper,
    private readonly store: Store
  ) {
    config.placement = 'bottom-right';
    config.autoClose = true;
  }

  ngOnInit() {
    this.updateMenuTitleVisibility();
    this.subscriptions.push(
      this.navigationService.selectedMenuTitle.subscribe((updatedTitle) => {
        this.menuTitle = updatedTitle;
      })
    );
    this.store.pipe(select(selectNavigation)).subscribe((lookupParam) => {
      this.lookup = lookupParam && lookupParam.lookup !== undefined ? lookupParam.lookup : false;
    });
    this.subscriptions.push(
      this.store.pipe(select(selectShowPartitionSwitchPopup)).subscribe((showPopup) => {
        if (showPopup) {
          this.displayAlertPartitionChange = true;
          this.store.dispatch(new MetaGroupSwitchAlertDisplayed());
        }
      })
    );
  }

  onSignOffClicked() {
    this.signOffClicked.emit();
  }

  updateMenuTitleVisibility() {
    this.resolutionNavHelper.updateTitleVisibility();
  }

  isTitleHidden() {
    return this.resolutionNavHelper.isTitleCollapsed;
  }

  toggleSideNav() {
    this.resolutionNavHelper.toggleSideNav();
  }

  onSelectPartition(partition: string) {
    this.navigationService.selectPartition(partition);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
