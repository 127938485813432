import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketsRoutingModule } from './markets-routing.module';
import { MarketsSearchComponent } from './markets-search/markets-search.component';
import { MarketsAppComponent } from './markets-app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DfSelectModule } from '@design-factory/design-factory';
import { ComponentsModule } from '../components/components.module';
import { FormValidatorsModule } from '../components/form-validators.module';
import { MarketsSearchCriteriaComponent } from './markets-search/markets-search-criteria/markets-search-criteria.component';
import { PointsOfSaleModule } from '../points-of-sale/points-of-sale.module';
import { MarketsDisplayComponent } from './markets-display/markets-display.component';
import { MarketsDetailsComponent } from './markets-details/markets-details.component';
import { MarketsModifyComponent } from './markets-modify/markets-modify.component';
import { MarketsCreateComponent } from './markets-create/markets-create.component';
import { PipesModule } from '../core/util/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    MarketsRoutingModule,
    ComponentsModule,
    FormValidatorsModule,
    DfSelectModule,
    PointsOfSaleModule,
    PipesModule
  ],
  declarations: [
    MarketsAppComponent,
    MarketsSearchComponent,
    MarketsSearchCriteriaComponent,
    MarketsDisplayComponent,
    MarketsDetailsComponent,
    MarketsModifyComponent,
    MarketsCreateComponent
  ],
  exports: [MarketsSearchComponent, MarketsSearchCriteriaComponent, MarketsDisplayComponent]
})
export class MarketsModule {}
