import { PosMarketResponseDetail } from '../resp/pos-market-response';
import {
  PosMarketDetailIncludeExcludeStructure,
  posMarketDetailIncludeExcludeStructureEquals
} from './pos-market-details-include-exclude';

export class PosMarketDetail {
  organization: string;
  name: string;
  id?: string;
  description?: string;
  version?: number;
  include?: PosMarketDetailIncludeExcludeStructure;
  exclude?: PosMarketDetailIncludeExcludeStructure;

  constructor(responseDetail: PosMarketResponseDetail) {
    this.name = responseDetail.name;
    this.id = responseDetail.id;
    this.organization = responseDetail.organization;
    this.version = responseDetail.version;
    if (responseDetail.description) {
      this.description = responseDetail.description;
    }
    if (responseDetail.include) {
      this.include = new PosMarketDetailIncludeExcludeStructure(responseDetail.include);
    }
    if (responseDetail.exclude) {
      this.exclude = new PosMarketDetailIncludeExcludeStructure(responseDetail.exclude);
    }
  }
}

export const getEmptyPosMarketDetail = (): PosMarketDetail => ({
  organization: '', name: '', description: ''
});

export const posMarketDetailEquals = (p1: PosMarketDetail, p2: PosMarketDetail): boolean => {
  if (p1 === p2) {
    return true;
  }
  if (p1 === undefined || p2 === undefined) {
    return false;
  }
  return p1.organization === p2.organization &&
      p1.name === p2.name &&
      p1.id === p2.id &&
      p1.description === p2.description &&
      p1.version === p2.version &&
      posMarketDetailIncludeExcludeStructureEquals(p1.include, p2.include) &&
      posMarketDetailIncludeExcludeStructureEquals(p1.exclude, p2.exclude);
};
