<h1 i18n="@@upp.hotels.display.title">Display Hotel Rule</h1>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-hotel-details
  [hotelDetailsForm]="hotelForm"
  [hotelDetails]="hotelRule"
  [readonly]="readonly"
  [parent]="uppComponent"
>
</ama-ng-upp-hotel-details>

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.modify"
    type="button"
    [disabled]="!modifyButtonEnabled()"
    class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
    (click)="modifyHotel()"
  >
    Modify
  </button>
</div>
