import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  MarketsSearchRequest,
  PosMarketRecord,
  PosMarketSearchParameter,
  UppComponentNames,
  UppComponent
} from '../../service/model';
import {
  selectMarketsRecords,
  selectMarketsSearchSending,
  selectMarketsSearchNotification,
  selectMarketsDeleteSending,
  selectSelectedMarketsNames
} from '../../store/markets/markets-selector';
import { NavigationService } from '../../service/core/navigation.service';
import { Router } from '@angular/router';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import {
  ClearMarketsResultsAction,
  SearchMarketsAction,
  DeleteMarketsSearchNotificationAction,
  ActivateMarketsDisplayAction,
  StartMarketsModificationAction,
  DeleteMarketsDisplayNotificationAction,
  DeleteMarketsAction,
  CopyMarketAction,
  DeleteMarketCreateNotificationAction,
  ClearMarketSelectionAction,
  SelectMarketAction
} from '../../store/markets/markets-actions';
import { MarketsSearchCriteriaComponent } from './markets-search-criteria/markets-search-criteria.component';
import { UppNotification } from '../../model/notification';
import { PosMarketDeleteRequest } from '../../service/model/req/pos-market-request';
import { LookupOptions } from '../../core/util/lookup-options';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import {
  DisplayableField,
  EntryType,
  SearchResult
} from '../../base/search/basic-search-result-table/basic-search-result-table.component';
import { PERMISSION_MANAGE_MARKET } from '../../service/user-permissions.service';
import { PosMarketService } from '../../service/pos-market.service';

@Component({
  selector: 'ama-ng-upp-markets-search',
  templateUrl: './markets-search.component.html',
  styleUrls: ['./markets-search.component.scss']
})
export class MarketsSearchComponent implements OnInit {

  @ViewChild(MarketsSearchCriteriaComponent)
  searchCriteriaComponent: MarketsSearchCriteriaComponent;

  marketsSearchSending$: Observable<boolean>;
  marketsDeleteSending$: Observable<boolean>;
  retrievedPosMarkets$: Observable<PosMarketRecord[]>;
  retrievedMarketsLookup$: Observable<PosMarketRecord[]>;
  selectedMarketNames$: Observable<Set<string>>;
  notificationSearch$: Observable<UppNotification>;
  notificationLookup: UppNotification;
  posMarketSearchResults: PosMarketRecord[];
  selectedMarketNames: Set<string>;

  uppComponent = UppComponent.MARKETS_SEARCH;
  mainMessages: NotificationMessages;

  lookup = false;
  lookupValues: string[];
  lookupOptions: LookupOptions;
  managePermission = PERMISSION_MANAGE_MARKET;

  displayableFields: DisplayableField[] = [
    {
      value: 'description',
      label: 'Description'
    }
  ];

  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly posMarketsService: PosMarketService
  ) {
    this.retrievedPosMarkets$ = this.store.pipe(select(selectMarketsRecords));
    this.selectedMarketNames$ = this.store.pipe(select(selectSelectedMarketsNames));
    this.marketsSearchSending$ = this.store.pipe(select(selectMarketsSearchSending));
    this.marketsDeleteSending$ = this.store.pipe(select(selectMarketsDeleteSending));
    this.notificationSearch$ = this.store.pipe(select(selectMarketsSearchNotification));
  }

  ngOnInit() {
    this.navigationService.enableNavigation();
    this.store.dispatch(new ClearMarketSelectionAction({}));
    this.navigationService.setSelectedMenuTitle('Markets Search');
    this.navigationService.activateMarkets();
    this.retrievedPosMarkets$.subscribe(
      posMarketSearchResultsArray => (this.posMarketSearchResults = posMarketSearchResultsArray)
    );
    this.selectedMarketNames$.subscribe(
      selectedMarketNamesSearchResults => (this.selectedMarketNames = selectedMarketNamesSearchResults)
    );
    this.mainMessages = {
      error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
      warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
      success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
    };
    this.store.pipe(select(selectNavigation)).subscribe(lookupParam => {
      if (lookupParam) {
        this.lookupOptions = lookupParam;
        this.lookupValues = lookupParam.data;
      }
    });

    this.lookup = !!this.lookupOptions?.lookup;

    if (this.lookup) {
      this.navigationService.disableNavigation();
    }
  }

  searchMarketsList(searchParameter: PosMarketSearchParameter) {
    const request = this.createSearchMarketsRequest(searchParameter);
    if (this.lookup) {
      this.retrievedMarketsLookup$ = this.posMarketsService.lookupMarkets(request);
      this.retrievedMarketsLookup$.subscribe(
        (result) => this.notificationLookup = result[0].statusNotification);
    } else {
      this.store.dispatch(new SearchMarketsAction({ request }));
    }
  }

  clearMarketsResults() {
    this.store.dispatch(new ClearMarketsResultsAction({}));
    this.store.dispatch(new ClearMarketSelectionAction({}));
  }

  showMarketsDetail(id: string) {
    this.store.dispatch(new ActivateMarketsDisplayAction({ id: undefined }));
    this.store.dispatch(new DeleteMarketsDisplayNotificationAction({}));
    this.router.navigate(['markets/display/' + id]); // NOSONAR
  }

  modifyMarketsDetail(detailId: string) {
    this.store.dispatch(new StartMarketsModificationAction({ id: detailId }));
    this.router.navigate(['markets/modify/']);
  }

  copyMarketsDetail(detailId: string) {
    this.store.dispatch(new CopyMarketAction({ id: detailId }));
    this.store.dispatch(new DeleteMarketCreateNotificationAction({}));
    this.router.navigate(['markets/create/']);
  }

  deleteMarketsEntry(request: PosMarketDeleteRequest) {
    this.store.dispatch(new DeleteMarketsAction({ request }));
  }

  closeNotification() {
    if (this.lookup) {
      this.notificationLookup = undefined;
    } else {
      this.store.dispatch(new DeleteMarketsSearchNotificationAction({}));
    }
  }

  createSearchMarketsRequest(searchParameter: PosMarketSearchParameter): MarketsSearchRequest {
    return { version: '2.0', market: searchParameter };
  }

  getSourceComponent(): UppComponentNames {
    return UppComponentNames.MARKETS;
  }

  getEntryType(): EntryType {
    return EntryType.MARKET;
  }

  recordToSearchResult(posRecord: PosMarketRecord): SearchResult {
    const posMarketDetail = posRecord.posMarketDetail;
    return {
      id: posMarketDetail.id,
      name: posMarketDetail.name,
      description: posMarketDetail.description,
      organization: posMarketDetail.organization,
      version: posMarketDetail.version
    };
  }

  entryToUpdateCreator(id: string, name: string, organization: string, version: string, ruleVersion: number): PosMarketDeleteRequest {
    return {
      version,
      market: {
        id,
        name,
        organization,
        version: ruleVersion
      }
    };
  }

  entryToUpdateNameResolver(entryToUpdate: PosMarketDeleteRequest) {
    return entryToUpdate.market.name;
  }

  storeUnsavedMarketsSearch() {
    if (!this.lookup) {
      this.searchCriteriaComponent.storeUnsavedMarketsSearch();
    }
  }

  getSearchResults(): Observable<PosMarketRecord[]> {
    if (this.lookup) {
      return this.retrievedMarketsLookup$;
    }
    return this.retrievedPosMarkets$;
  }

  getNotification(): Observable<UppNotification> {
    if (this.lookup) {
      return of(this.notificationLookup);
    }
    return this.notificationSearch$;
  }
}
