import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * This validator should be used for validating carrier code control. If value 'YY' (represents 'all airlines')
 * is selected no other airline codes should be allowed.
 */
export const carrierCodeValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value || typeof control.value === 'string') {
    return null;
  }

  return control.value.some((x: string) => x === 'YY') && control.value.length > 1
    ? {
        carrierCodeValidator: {
          allAirlinesSelected: true
        }
      }
    : null;
};
