import { Notification } from '../../model/notification';
import {
  FamilyUi,
  initialFamilyUi,
  FamilySearchCriteriaUI,
  initialFamilySearchCriteria
} from '../../families/model/family-ui';

export const FAMILIES_FEATURE = 'familyUppUi';

export interface FamiliesState {
  families: FamilyUi[];
  availableFamiliesHotelChains?: FamilyUi[];
  availableFamiliesHotelPropertyCodes?: FamilyUi[];
  lookupHotelChainsFamilies: FamilyUi[];
  lookupHotelPropertyCodesFamilies: FamilyUi[];
  selectedFamiliesNames: Set<string>;
  family: {
    create: FamilyStatus;
    modify: FamilyStatus;
    display: FamilyStatus;
    search: {
      sending: boolean;
      value: FamilySearchCriteriaUI;
    };
    delete: {
      sending: boolean;
    };
  };
  notification?: Notification;
}

export interface FamilyStatus {
  sending: boolean;
  active?: boolean;
  value: FamilyUi;
  updatedValue?: FamilyUi;
}

export const initialFamilyStatus: FamilyStatus = {
  sending: false,
  active: false,
  value: initialFamilyUi
};

export const initialFamilyState: FamiliesState = {
  families: [],
  lookupHotelChainsFamilies: [],
  lookupHotelPropertyCodesFamilies: [],
  selectedFamiliesNames: new Set<string>(),
  family: {
    create: initialFamilyStatus,
    modify: initialFamilyStatus,
    display: initialFamilyStatus,
    search: {
      sending: false,
      value: initialFamilySearchCriteria
    },
    delete: {
      sending: false
    }
  },
  notification: {}
};
