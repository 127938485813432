import { SelectableCriteria, SelectableCriteriaWithParams } from '../../base/search-criteria/selectable-criteria';
import { PosMarketDetailIncludeExcludeStructure } from '../../service/model/pos/pos-market-details-include-exclude';
import { countryCodes } from '../../core/util/resources';
import { Region, PosMarketRegions } from '../../service/model/pos/pos-market-regions';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { PosMarketDetail } from '../../service/model';
import { getPropertyByKey } from '../utils';

export class PosMarketsDetails {
  includeCriterias: SelectableCriteriaWithParams[] = [];
  excludeCriterias: SelectableCriteriaWithParams[] = [];

  countryCodes = countryCodes();
  regions: Region[] = PosMarketRegions.getRegionList();
  regionDescriptions: string[] = [];

  nameRegex = '^[A-Z0-9]{0,30}$';

  constructor() {
    this.buildRegionsDescriptionOnly();
  }

  initSelectableCriteriaForInclude(
    include: PosMarketDetailIncludeExcludeStructure | undefined,
    readonly: boolean,
    worldActivated: boolean
  ) {
    if (!include) {
      return;
    }
    const valueKeys = Object.keys(include);
    const values = Object.values(include);
    this.includeCriterias.forEach((criteria) => {
      const index = valueKeys.indexOf(criteria.name);
      if (index !== -1) {
        if (values[index] && values[index] !== '' && values[index].length > 0) {
          criteria.active = true;
        }
      }
      criteria.disabled = readonly || worldActivated;
    });
  }

  initSelectableCriteriaForExclude(exclude: PosMarketDetailIncludeExcludeStructure | undefined) {
    if (!exclude) {
      return;
    }
    const valueKeys = Object.keys(exclude);
    const values = Object.values(exclude);
    this.excludeCriterias.forEach((criteria) => {
      const index = valueKeys.indexOf(criteria.name);
      if (index !== -1) {
        if (values[index] && values[index] !== '' && values[index].length > 0) {
          criteria.active = true;
        }
      }
    });
  }

  updateCriteriaDisabledStatus(criteriaList: SelectableCriteria[], disable: boolean) {
    if (criteriaList) {
      criteriaList.forEach((criteria) => (criteria.disabled = disable));
    }
  }

  getValidStringArray(values: string[]): string[] {
    // In context of the patch value property we want to have the null value as
    // a valid trigger to delete any value from the corresponding element.
    if (values) {
      return values;
    }
    return [''];
  }

  isIncludeCriteriaActive(name: string): boolean {
    return this.isCriteriaActive(name, this.includeCriterias);
  }

  isExcludeCriteriaActive(name: string): boolean {
    return this.isCriteriaActive(name, this.excludeCriterias);
  }

  isCriteriaActive(name: string, criteriaList: SelectableCriteria[]): boolean {
    let active = false;
    criteriaList.forEach((criteria) => {
      if (criteria.name === name) {
        active = criteria.active;
      }
    });
    return active;
  }

  activateIncludeCriteria(name: string, detailsForm: UntypedFormGroup) {
    this.includeCriterias.forEach((criteria) => {
      if (criteria.name === name) {
        // before going to disable we want to check the content of the given control:
        if (criteria.active && !this.checkContentToBeEmpty(detailsForm.get('include')?.get(name))) {
          return;
        }
        criteria.active = !criteria.active;
      }
    });
  }

  activateExcludeCriteria(name: string, detailsForm: UntypedFormGroup) {
    this.excludeCriterias.forEach((criteria) => {
      if (criteria.name === name) {
        // before going to disable we want to check the content of the given control:
        if (criteria.active && !this.checkContentToBeEmpty(detailsForm.get('exclude')?.get(name))) {
          return;
        }
        criteria.active = !criteria.active;
      }
    });
  }

  resetVisibleCriteria(world: boolean, group: AbstractControl) {
    const formGroup = group as UntypedFormGroup; // NOSONAR
    Object.keys(formGroup.controls).forEach((key) => {
      if (key === 'world') {
        formGroup.controls[key].setValue(world);
      } else {
        formGroup.get(key)?.setValue([]);
      }
    });
  }

  updateIncludeCriteriaStatus(disabled: boolean) {
    this.includeCriterias.forEach((criteria) => {
      criteria.disabled = disabled;
      criteria.active = false;
    });
  }

  checkContentToBeEmpty(control?: AbstractControl | null): boolean {
    if (!control) {
      return false;
    }
    if (control.value === undefined || control.value === '' || control.value.length === 0) {
      return true;
    }
    return false;
  }

  // Every REGION has a code and a description.
  // And this array consists of the descriptions of all the regions (region codes are not included)
  buildRegionsDescriptionOnly() {
    this.regions.forEach((region) => {
      if (region !== undefined) {
        this.regionDescriptions.push(region.description);
      }
    });
  }

  resetSelectableCriteriaButtonsAndElements() {
    this.excludeCriterias.forEach((criteria) => (criteria.active = false));
    this.includeCriterias.forEach((criteria) => (criteria.active = false));
  }

  setupBadgeItems(criteria: SelectableCriteriaWithParams[], details: PosMarketDetail, exclude = false) {
    if (exclude) {
      criteria.forEach((criterion) => {
        criterion.badgeItems = details.exclude
          ? this.getValidStringArray(getPropertyByKey(details.exclude, criterion.name))
          : [];
      });
    } else {
      criteria.forEach((criterion) => {
        criterion.badgeItems = details.include
          ? this.getValidStringArray(getPropertyByKey(details.include, criterion.name))
          : [];
      });
    }
  }
}
