<h1 i18n="@@upp.families.create.title">Create Hotel Family</h1>

<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
  (openDisplayEvent)="showFamilyInDisplay()"
>
</ama-upp-notification>

<ama-ng-upp-families-details
  [familiesDetailsForm]="familyForm"
  [familyDetails]="familyUi"
  [readonly]="readonly"
  [parent]="uppComponent"
>
</ama-ng-upp-families-details>

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.save"
    *ngIf="!sendingStatus"
    type="submit"
    class="btn btn-primary mb-2 mb-md-0 me-2"
    [disabled]="!saveButtonEnabled()"
    (click)="saveData()"
  >
    Save
  </button>

  <button
    i18n="@@upp.global.button.save"
    *ngIf="sendingStatus"
    type="button"
    disabled
    class="btn btn-primary mb-2 mb-md-0 me-2"
  >
    Save
  </button>
</div>
