import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FamiliesState, FAMILIES_FEATURE } from './families-state';

export const selectFamiliesState = createFeatureSelector<FamiliesState>(FAMILIES_FEATURE);

export const selectFamilyCreateValue = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.create.value
);

export const selectFamilyRuleCreateSending = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.create.sending
);

export const selectFamilyRuleCreateNotification = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state.notification && state.notification.create) {
    return state.notification.create;
  }
  return undefined;
});

export const selectFamilyModifyValue = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.modify.value
);

export const selectFamilyModifySending = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.modify.sending
);

export const selectFamilyModifyActivated = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state.family.modify.active) {
    return state.family.modify.active;
  }
  return false;
});

export const selectFamilyModifyNotification = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state.notification && state.notification.modify) {
    return state.notification.modify;
  }
  return undefined;
});

export const selectFamilyDisplayValue = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.display.value
);

export const selectFamilyDisplayNotification = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state.notification && state.notification.display) {
    return state.notification.display;
  }
  return undefined;
});

export const selectFamilyRuleDisplayActivated = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state.family.display.active) {
    return state.family.display.active;
  }
  return false;
});

export const selectFamilies = createSelector(selectFamiliesState, (state: FamiliesState) => state.families);

export const selectAvailableFamiliesHotelPropertyCodes = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state?.availableFamiliesHotelPropertyCodes
);

export const selectAvailableFamiliesHotelChains = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state?.availableFamiliesHotelChains
);

export const selectLookupHotelChainsFamilies = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.lookupHotelChainsFamilies
);
export const selectLookupHotelPropertyCodesFamilies = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.lookupHotelPropertyCodesFamilies
);

export const selectFamilySearchSending = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.search.sending
);

export const selectFamilySearchNotification = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state.notification && state.notification.search) {
    return state.notification.search;
  }
  return undefined;
});

export const selectFamilySearchValue = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state.family.search.value
);

export const selectDeleteSending = createSelector(selectFamiliesState, (state: FamiliesState) => {
  if (state && state.family && state.family.delete) {
    return state.family.delete.sending;
  }
  return undefined;
});

export const selectSelectedFamilyNames = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state?.selectedFamiliesNames
);

export const selectFamilyLookupNotification = createSelector(
  selectFamiliesState,
  (state: FamiliesState) => state?.notification?.lookup
);
