import { DEFAULT_ERROR, EIGHT_SYMBOLS, TWO_SYMBOLS } from '../../service/model/common/validation-messages';
import { DESCRIPTION,
  EIGHT_SYMBOLS_LONG_LIST,
  NAME,
  TWO_LETTERS_COUNTRY_CODES_LONG_LIST } from '../../service/model/common/validators';
import { ValidationProvider } from '../../service/upp-validator.service';

export class FamilyServiceElements {
  static readonly NAME = 'name';
  static readonly DESCRIPTION = 'description';
  static readonly HOTEL_TYPE = 'hotelFamilyType';
  static readonly CHAINS = 'hotelChains';
  static readonly PROPERTY_CODES = 'hotelPropertyCodes';
  static readonly ORGANIZATION = 'organization';
}

export class FamilyRuleSearchCriteria {
  readonly HOTEL_TYPE = 'hotelType';
  readonly HOTEL_CHAINS_TYPE = 'hotelChain';
  readonly HOTEL_PROP_CODES_TYPE = 'hotelCode';
}

export const VALIDATORS = new Map<string, ValidationProvider>([
  [FamilyServiceElements.NAME, {validator: NAME, massages: DEFAULT_ERROR}],
  [FamilyServiceElements.DESCRIPTION, {validator: DESCRIPTION, massages: DEFAULT_ERROR}],
  [FamilyServiceElements.CHAINS, {validator: TWO_LETTERS_COUNTRY_CODES_LONG_LIST, massages: TWO_SYMBOLS}],
  [FamilyServiceElements.PROPERTY_CODES, {validator: EIGHT_SYMBOLS_LONG_LIST, massages: EIGHT_SYMBOLS}]
]);
