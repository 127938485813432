import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanViewFareGuard } from '../core/util/routing-guards/fares/can-view-fare-guard';
import { FaresSearchComponent } from './fares-search/fares-search.component';
import { CanManageFareGuard } from '../core/util/routing-guards/fares/can-manage-fare-guard';
import { FaresCreateComponent } from './fares-create/fares-create.component';
import { FaresModifyComponent } from './fares-modify/fares-modify.component';
import { FaresDisplayComponent } from './fares-display/fares-display.component';
import { CanDeactivateFareSearchGuard } from '../core/util/can-deactivate-fare-search.guard';
import { CanDeactivateFareModifyGuard } from '../core/util/can-deactivate-fare-modify.guard';
import { CanDeactivateFareCreateGuard } from '../core/util/can-deactivate-fare-create.guard';
import { UppComponentNames } from '../service/model';
import { LoggedInGuard } from '../core/util/routing-guards/logged-in.guard';

const fareRoutes: Routes = [
  {
    path: UppComponentNames.FARES,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: 'search',
        component: FaresSearchComponent,
        canDeactivate: [CanDeactivateFareSearchGuard],
        canActivate: [CanViewFareGuard]
      },
      {
        path: 'display',
        component: FaresDisplayComponent,
        canActivate: [CanViewFareGuard]
      },
      {
        path: 'display/:id',
        component: FaresDisplayComponent,
        canActivate: [CanViewFareGuard]
      },
      {
        path: 'modify',
        component: FaresModifyComponent,
        canDeactivate: [CanDeactivateFareModifyGuard],
        canActivate: [CanManageFareGuard]
      },
      {
        path: 'modify/:id',
        component: FaresModifyComponent,
        canDeactivate: [CanDeactivateFareModifyGuard],
        canActivate: [CanManageFareGuard]
      },
      {
        path: 'create',
        component: FaresCreateComponent,
        canDeactivate: [CanDeactivateFareCreateGuard],
        canActivate: [CanManageFareGuard]
      },
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: '**', redirectTo: 'search', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(fareRoutes)],
  exports: [RouterModule]
})
export class FaresRoutingModule {}
