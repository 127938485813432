<div class="footer container-fluid">
  <div class="row align-items-center">
    <div class="col-4 col-sm-3 col-md-3 col-lg-2">
      <div class="df-amadeuslogo-primary df-logo-dim"></div>
    </div>
    <div class="col-4 col-sm-6 col-md-6">
      <div>&copy;&nbsp;Amadeus&nbsp;IT&nbsp;Group&nbsp;SA</div>
    </div>
    <div class="col-4 col-sm-3 col-md-3 col-lg-4 footer-right">
      <a href="https://amadeus.com/en/policies/privacy-policy" target="_blank"
         rel="noopener noreferrer">Privacy&nbsp;Policy</a>
    </div>
  </div>
</div>
