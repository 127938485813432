import { initialFlightState, FlightsState, initialFlightRuleStatus, FlightRuleStatus } from './flights-state';
import { FlightsActions, FLIGHTS_ACTION } from './flights-action';
import { EXCLUDE_ACTION, JourneyUi, initialJourneyUi } from '../../flights/model';
import { shouldNotificationCleared } from '../helpers/reducer-helper';
import { UppNotification } from '../../model/notification';
import { CORE_ACTION } from '../core/actions';

export const flightsReducer = (state = initialFlightState, action: FlightsActions): FlightsState => {
  if (FlightReducerMethods.has(action.type)) {
    const reducerMethod = FlightReducerMethods.get(action.type);

    if (reducerMethod) {
      return reducerMethod(state, action);
    }
  }
  return state;
};

const getInitialFlightState = (_state = initialFlightState, action: FlightsActions): FlightsState => ({...action.payload});

const setPartitionFlightState = (state = initialFlightState, action: FlightsActions): FlightsState => ({...state });

const create = (state = initialFlightState): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      create: {
        sending: true,
        value: state.flightRule.create.value
      }
    },
    notification: {
      ...state.notification,
      create: {}
    }
});

const setCreateValue = (state = initialFlightState, action: FlightsActions): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      create: {
        ...state.flightRule.create,
        value: action.payload.value
      }
    }
});

const finishCreation = (state = initialFlightState, action: FlightsActions): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      create: {
        sending: false,
        value: state.flightRule.create.value,
        updatedRule: action.payload.journey
      }
    },
    notification: {
      ...state.notification,
      create: action.payload.journey.statusNotification
    }
});

const deleteCreateNotificationateValue = (state = initialFlightState): FlightsState => ({
    ...state,
    notification: {
      ...state.notification,
      create: {}
    }
});

const activateDisplay = (state = initialFlightState, action: FlightsActions): FlightsState => {
  let displayValue = state.flightRule.display.value;
  if (action.payload.id) {
    const ruleToDisplay = findRuleWithId(state.flightRules, action.payload.id);
    if (ruleToDisplay) {
      displayValue = ruleToDisplay;
    }
  }
  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      display: {
        ...state.flightRule.display,
        value: displayValue,
        active: true
      }
    }
  };
};

const deleteDisplayNotification = (state = initialFlightState): FlightsState => ({
    ...state,
    notification: {
      ...state.notification,
      display: {}
    }
});

const activateDispay = (state = initialFlightState): FlightsState => {
  let rule = state.flightRule.display.value;
  if (state.flightRule.create.updatedRule) {
    rule = state.flightRule.create.updatedRule;
  }
  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      display: {
        sending: false,
        value: rule,
        active: true
      }
    },
    notification: {
      ...state.notification,
      display: {}
    }
  };
};

const search = (state = initialFlightState): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      search: {
        ...state.flightRule.search,
        sending: true
      }
    }
});

const setList = (state = initialFlightState, action: FlightsActions): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      search: {
        ...state.flightRule.search,
        sending: false
      }
    },
    flightRules: action.payload.flightRules,
    notification: {
      ...state.notification,
      search: action.payload.flightRules[0].statusNotification
    }
});

const clearList = (state = initialFlightState): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      search: {
        ...state.flightRule.search,
        sending: false
      },
      display: initialFlightRuleStatus
    },
    flightRules: []
});

const deleteSearchNotification = (state = initialFlightState): FlightsState => ({
    ...state,
    notification: {
      ...state.notification,
      search: {}
    }
});

const setSearchValue = (state = initialFlightState, action: FlightsActions): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      search: {
        sending: state.flightRule.search.sending,
        value: action.payload.flightSearchCriteria
      }
    }
});

const deleteRule = (state = initialFlightState): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      delete: {
        ...state.flightRule.delete,
        sending: true
      }
    }
});

const deleteClearStore = (state = initialFlightState, action: FlightsActions): FlightsState => {
  const clearFlightRuleId = action.payload.journey.rule.id;

  const currentValueOfDeletedRuleDisplay = getFlightRuleValueAfterDelete(clearFlightRuleId, state.flightRule.display.value);
  const currentValueOfDeletedRuleModify = getFlightRuleValueAfterDelete(clearFlightRuleId, state.flightRule.modify.value);
  const updatedValueOfDeletedRule = getUpdatedFlightRuleAfterDelete(clearFlightRuleId, state.flightRule.create.updatedRule);

  if (!currentValueOfDeletedRuleDisplay) {
    throw new Error('currentValueOfDeletedRecordDisplay is null or undefined');
  }

  if (!currentValueOfDeletedRuleModify) {
    throw new Error('currentValueOfDeletedRecordModify is null or undefined');
  }

  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      display: {
        ...state.flightRule.display,
        value: currentValueOfDeletedRuleDisplay,
        active: checkFlightRuleIdActivation(clearFlightRuleId, state.flightRule.display)
      },
      create: {
        ...state.flightRule.create,
        updatedRule: updatedValueOfDeletedRule
      },
      modify: {
        ...state.flightRule.modify,
        value: currentValueOfDeletedRuleModify,
        active: checkFlightRuleIdActivation(clearFlightRuleId, state.flightRule.modify)
      }
    },
    notification: {
      ...state.notification,
      create: shouldNotificationCleared(state, clearFlightRuleId) ? {} : state.notification?.create
    }
  };
};

const deleteFromList = (state = initialFlightState, action: FlightsActions): FlightsState => {
  let rules = state.flightRules;
  let statusNotification: UppNotification = action.payload.deletedRule.statusNotification;
  if (statusNotification.success) {
    rules = deleteRuleFromList(action.payload.deletedRule.rule.id, state.flightRules);
    statusNotification = {};
  }

  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      delete: {
        ...state.flightRule.delete,
        sending: false
      }
    },
    flightRules: rules,
    notification: {
      ...state.notification,
      search: statusNotification
    }
  };
};

const setModifyValue = (state = initialFlightState, action: FlightsActions): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      modify: {
        ...state.flightRule.modify,
        value: action.payload.value
      }
    }
});

const startModification = (state = initialFlightState, action: FlightsActions): FlightsState => {
  const modificationValue = getFlightModifyValue(state, action.payload.id);
  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      modify: {
        ...state.flightRule.modify,
        value: modificationValue,
        active: true
      },
      display: {
        ...state.flightRule.display,
        active: true
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
  };
};

const update = (state = initialFlightState): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      modify: {
        sending: true,
        value: state.flightRule.modify.value,
        active: state.flightRule.modify.active
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
});

const cancelModification = (state = initialFlightState): FlightsState => ({
    ...state,
    flightRule: {
      ...state.flightRule,
      modify: {
        sending: state.flightRule.modify.sending,
        value: {
          rule: {
            organization: '',
            name: '',
            active: false,
            action: 'exclude'
          },
          applicability: {
            pointOfSaleName: ''
          }
        },
        active: false
      }
    },
    notification: {
      ...state.notification,
      modify: {},
      display: {}
    }
});

const updateSuccessValues = (state = initialFlightState, action: FlightsActions): FlightsState => {
  let updatedDisplayValue = state.flightRule.display.value;
  if (action.payload.updatedFlightRule) {
    updatedDisplayValue = action.payload.updatedFlightRule;
  }
  const updatedFlightRules = updateUpdatedFlightRules(state.flightRules, action.payload.updatedFlightRule);
  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      display: {
        ...state.flightRule.display,
        value: updatedDisplayValue
      },
      modify: {
        sending: state.flightRule.modify.sending,
        value: {
          rule: {
            organization: '',
            name: '',
            active: false,
            action: 'exclude'
          },
          applicability: {
            pointOfSaleName: ''
          }
        },
        active: false
      }
    },
    flightRules: updatedFlightRules
  };
};

const finishUpdate = (state = initialFlightState, action: FlightsActions): FlightsState => {

  const updatedFlightRule = getUpdatedFlightRule(state.flightRule.create.updatedRule, action.payload.updatedJourneyUi);

  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      modify: {
        ...state.flightRule.modify,
        sending: false
      },
      create: {
        ...state.flightRule.create,
        updatedRule: updatedFlightRule
      }
    },
    notification: {
      ...state.notification,
      modify: {
        warning: action.payload.updatedJourneyUi.statusNotification.warning,
        error: action.payload.updatedJourneyUi.statusNotification.error
      },
      display: {
        success: action.payload.updatedJourneyUi.statusNotification.success
      }
    }
  };
};

const deleteModifyNotification = (state = initialFlightState): FlightsState => ({
    ...state,
    notification: {
      ...state.notification,
      modify: {}
    }
});

export const findRuleWithId = (journeys: JourneyUi[], ruleId: string): JourneyUi | undefined => {
  if (!journeys || journeys.length === 0) {
    return undefined;
  }
  return journeys.find(journey => journey.rule.id === ruleId);
};

const getFlightModifyValue = (state: FlightsState, flightRuleId: string): JourneyUi => {
  let modificationValue = state.flightRule.modify.value;
  if (flightRuleId) {
    const recordToModify = findRuleWithId(state.flightRules, flightRuleId);
    if (recordToModify) {
      modificationValue = recordToModify;
    }
  }
  return modificationValue;
};

const getFlightDetailValue = (state: FlightsState, flightRuleId: string): JourneyUi | null => {
  const flightDetailValue = findRuleWithId(state.flightRules, flightRuleId);

  if (flightDetailValue) {
    return flightDetailValue;
  }

  if (state.flightRule.display.value.rule?.id === flightRuleId) {
    return state.flightRule.display.value;
  }

  return null;
};

const getUpdatedFlightRule = (createFlightRule: JourneyUi | undefined, lastFlightRule: JourneyUi) => {
  if (createFlightRule && lastFlightRule && createFlightRule.rule.id === lastFlightRule.rule.id) {
    return lastFlightRule;
  } else {
    return createFlightRule;
  }
};

export const updateUpdatedFlightRules = (flightRules: JourneyUi[], flightRuleToUpdate: JourneyUi): JourneyUi[] => {
  if (!flightRules || flightRules.length === 0) {
    return [flightRuleToUpdate];
  }

  return flightRules.map(obj => [flightRuleToUpdate].find(record => record.rule.id === obj.rule.id) || obj);
};

const getFlightRuleValueAfterDelete = (id: string, flightRule: JourneyUi): JourneyUi | undefined => {
  if (!flightRule) {
    return;
  }
  if (flightRule.rule.id === id) {
    return initialJourneyUi;
  }
  return flightRule;
};

const checkFlightRuleIdActivation = (id: string, flightRuleStatus: FlightRuleStatus): boolean => {
  if (!flightRuleStatus) {
    return false;
  }

  if (flightRuleStatus.active && flightRuleStatus.value && flightRuleStatus.value.rule.id === id) {
    return false;
  } else {
    return flightRuleStatus.active ?? false;
  }
};

const getUpdatedFlightRuleAfterDelete = (id: string, flightRule?: JourneyUi): JourneyUi | undefined => {
  if (!flightRule) {
    return;
  }
  if (flightRule.rule.id === id) {
    return undefined;
  }

  return flightRule;
};

const deleteRuleFromList = (id: string, flightRules: JourneyUi[]): JourneyUi[] => {
  if (!id || !flightRules) {
    return [];
  }
  return flightRules.filter(journey => journey.rule.id !== id);
};

const copy = (state: FlightsState, action: FlightsActions): FlightsState => {
  const flightToCopy = getFlightDetailValue(state, action.payload.id);

  if (!flightToCopy) {
    throw new Error('flightToCopy is null or undefined.');
  }

  return {
    ...state,
    flightRule: {
      ...state.flightRule,
      create: {
        ...state.flightRule.create,
        value: {
          ...flightToCopy,
          id: undefined,
          rule: {
            ...flightToCopy.rule,
            id: undefined,
            version: undefined,
            name: '',
            organization: '',
            description: `Copy of ${flightToCopy.rule.name}`,
            active: false,
            action: EXCLUDE_ACTION
          }
        },
        active: false
      }
    },
    notification: {
      ...state.notification
    }
  };
};

export const FlightReducerMethods: Map<string, (state: FlightsState, action: FlightsActions) => FlightsState> = new Map<string, (state: FlightsState, action: FlightsActions) => FlightsState>([
  [FLIGHTS_ACTION.INIT_FLIGHT_RULE_STATE, getInitialFlightState],
  [CORE_ACTION.UPDATE_SELECTED_PARTITION, setPartitionFlightState],
  [FLIGHTS_ACTION.CREATE_FLIGHT_RULE, create],
  [FLIGHTS_ACTION.SET_CREATE_FLIGHT_RULE_VALUE, setCreateValue],
  [FLIGHTS_ACTION.FINISH_FLIGHT_RULE_CREATION, finishCreation],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE_CREATE_NOTIFICATION, deleteCreateNotificationateValue],
  [FLIGHTS_ACTION.ACTIVATE_FLIGHT_RULE_DISPLAY, activateDisplay],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE_DISPLAY_NOTIFICATION, deleteDisplayNotification],
  [FLIGHTS_ACTION.ACTIVATE_CREATED_FLIGHT_RULE_DISPLAY, activateDispay],
  [FLIGHTS_ACTION.SEARCH_FLIGHT_RULE, search],
  [FLIGHTS_ACTION.SET_FLIGHT_RULE_LIST, setList],
  [FLIGHTS_ACTION.CLEAR_FLIGHT_RULE_LIST, clearList],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE_SEARCH_NOTIFICATION, deleteSearchNotification],
  [FLIGHTS_ACTION.SET_FLIGHT_RULE_SEARCH_VALUE, setSearchValue],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE, deleteRule],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE_CLEAR_STORE, deleteClearStore],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE_FROM_LIST, deleteFromList],
  [FLIGHTS_ACTION.SET_FLIGHT_MODIFY_VALUE, setModifyValue],
  [FLIGHTS_ACTION.START_FLIGHT_MODIFICATION, startModification],
  [FLIGHTS_ACTION.UPDATE_FLIGHT, update],
  [FLIGHTS_ACTION.CANCEL_FLIGHT_MODIFICATION, cancelModification],
  [FLIGHTS_ACTION.SET_FLIGHT_UPDATE_SUCCESS_VALUES, updateSuccessValues],
  [FLIGHTS_ACTION.FINISH_FLIGHT_UPDATE, finishUpdate],
  [FLIGHTS_ACTION.DELETE_FLIGHT_RULE_MODIFY_NOTIFICATION, deleteModifyNotification],
  [FLIGHTS_ACTION.COPY_FLIGHT_RULE, copy]
]);
