<h1 i18n="@@upp.hotels.modify.title">Modify Hotel Rule</h1>

<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-hotel-details
  [hotelDetailsForm]="hotelForm"
  [hotelDetails]="hotelDetail"
  [readonly]="readonly"
  [parent]="uppComponent"
>
</ama-ng-upp-hotel-details>

<div *ngIf="!sendingStatus" class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.cancel"
    type="button"
    class="btn btn-outline-primary mb-2 mb-md-0 me-2"
    (click)="cancelModification()"
  >
    Cancel
  </button>

  <button
    i18n="@@upp.global.button.save"
    type="submit"
    class="btn btn-primary mb-2 mb-md-0 me-2"
    [disabled]="!hotelForm.valid"
    (click)="saveModification()"
  >
    Save
  </button>
</div>

<div *ngIf="sendingStatus" class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.cancel"
    type="button"
    disabled
    class="btn btn-outline-primary mb-2 mb-md-0 me-2"
  >
    Cancel
  </button>

  <button i18n="@@upp.global.button.save" type="button" disabled class="btn btn-primary mb-2 mb-md-0 me-2">
    Save
  </button>
</div>
