import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MARKETS_FEATURE, MarketsState } from './markets-state';

export const selectMarkets = createFeatureSelector<MarketsState>(MARKETS_FEATURE);

export const selectMarketsRecords = createSelector(selectMarkets, (state: MarketsState) => state?.marketsRecords);

export const selectAllAvailableMarketsRecords = createSelector(
  selectMarkets,
  (state: MarketsState) => state?.availableMarketsRecords
);

export const selectMarketsSearchSending = createSelector(selectMarkets, (state: MarketsState) => {
  if (state && state.markets && state.markets.search) {
    return state.markets.search.sending;
  }
  return undefined;
});

export const selectMarketsSearchValue = createSelector(
  selectMarkets,
  (state: MarketsState) => state.markets.search?.value
);

export const selectMarketsSearchNotification = createSelector(selectMarkets, (state: MarketsState) => {
  if (state.notification && state.notification.search) {
    return state.notification.search;
  }
  return undefined;
});

export const selectMarketsDisplayValue = createSelector(
  selectMarkets,
  (state: MarketsState) => state.markets.display.value
);

export const selectMarketsDisplayNotification = createSelector(selectMarkets, (state: MarketsState) => {
  if (state.notification && state.notification.display) {
    return state.notification.display;
  }
  return undefined;
});

export const selectMarketsDisplayActivated = createSelector(selectMarkets, (state: MarketsState) => {
  if (state.markets.display.active) {
    return state.markets.display.active;
  }
  return false;
});

export const selectMarketsModifyValue = createSelector(
  selectMarkets,
  (state: MarketsState) => state.markets.modify.value
);

export const selectMarketsModifySending = createSelector(
  selectMarkets,
  (state: MarketsState) => state.markets.modify.sending
);

export const selectMarketsModifyActivated = createSelector(selectMarkets, (state: MarketsState) => {
  if (state.markets.modify.active) {
    return state.markets.modify.active;
  }
  return false;
});

export const selectMarketsModifyNotification = createSelector(selectMarkets, (state: MarketsState) => {
  if (state.notification && state.notification.modify) {
    return state.notification.modify;
  }
  return undefined;
});

export const selectMarketCreateValue = createSelector(
  selectMarkets,
  (state: MarketsState) => state.markets.create.value
);

export const selectMarketCreateSending = createSelector(
  selectMarkets,
  (state: MarketsState) => state.markets.create.sending
);

export const selectMarketCreateNotification = createSelector(selectMarkets, (state: MarketsState) => {
  if (state.notification && state.notification.create) {
    return state.notification.create;
  }
  return undefined;
});

export const selectMarketsDeleteSending = createSelector(selectMarkets, (state: MarketsState) => {
  if (state && state.markets && state.markets.delete) {
    return state.markets.delete.sending;
  }
  return undefined;
});

export const selectSelectedMarketsNames = createSelector(
  selectMarkets,
  (state: MarketsState) => state?.selectedMarketsNames
);
