import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../components/components.module';
import { FormValidatorsModule } from '../components/form-validators.module';
import { DfAlertModule, DfSelectModule } from '@design-factory/design-factory';

import { PointsOfSaleModule } from '../points-of-sale/points-of-sale.module';

import { FlightsRoutingModule } from './flights-routing.module';
import { FlightsSearchComponent } from './flights-search/flights-search.component';
import { FlightsDisplayComponent } from './flights-display/flights-display.component';
import { FlightsCreateComponent } from './flights-create/flights-create.component';
import { FlightsAppComponent } from './flights-app.component';
import { FlightsModifyComponent } from './flights-modify/flights-modify.component';
import { PipesModule } from '../core/util/pipes/pipes.module';
import { FlightsDetailsComponent } from './flights-details/flights-details.component';
import { RuleActionOrValueComponent } from './flights-details/rule-action-or-value/rule-action-or-value.component';
import { RouteContentComponent } from './flights-details/route-content/route-content.component';
import { JourneyContentComponent } from './flights-details/journey-content/journey-content.component';
import { FlightDetailsCarrierComponent } from './flights-details/components/flight-details-carrier/flight-details-carrier.component';
import { AirlineScopeSelectorComponent } from './flights-details/components/airline-scope-selector/airline-scope-selector.component';
import { FlightSearchCriteriaComponent } from './flights-search/flight-search-criteria/flight-search-criteria.component';
import { FareContentComponent } from './flights-details/fare-content/fare-content.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ComponentsModule,
    FormValidatorsModule,
    DfSelectModule,
    PointsOfSaleModule,
    FlightsRoutingModule,
    PipesModule,
    DfAlertModule
  ],
  declarations: [
    FlightsAppComponent,
    FlightsSearchComponent,
    FlightsDisplayComponent,
    FlightsCreateComponent,
    FlightsModifyComponent,
    FlightsDetailsComponent,
    RuleActionOrValueComponent,
    RouteContentComponent,
    JourneyContentComponent,
    FlightDetailsCarrierComponent,
    AirlineScopeSelectorComponent,
    FlightSearchCriteriaComponent,
    FareContentComponent
  ],
  exports: [FlightsCreateComponent, FlightsDisplayComponent]
})
export class FlightsModule {}
