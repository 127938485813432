export const arraysEquals = <T>(array1: T[] | undefined, array2: T[] | undefined, isEqual?: (t1: T, t2: T) => boolean): boolean => {
    if (array1 === array2) {
        return true;
    } else {
        if (array1 === undefined || array2 === undefined) {
            return false;
        } else {
            if (array1.length === array2.length) {
                return array1.every((item, index) => {
                    if (isEqual) {
                        return isEqual(item, array2[index]);
                    } else {
                        return item === array2[index];
                    }
                });
            }
            return false;
        }
    }
};

/**
 * Partition array into two arrays based on predicate function.
 * @param array to be partitioned.
 * @param predicatefunction to be used for partitioning. If true, item will be added to first array, otherwise to second.
 * @returns two arrays, first containing items for which predicate returned true,
 * second containing items for which predicate returned false.
 */
export const partition = <T>(array: T[], predicate: (t: T, index?: number, arr?: T[]) => boolean): [T[], T[]] => {
  const trueArray: T[] = [];
  const falseArray: T[] = [];
  array.forEach((item, index, arr) => (predicate(item, index, arr) ? trueArray : falseArray).push(item));
  return [trueArray, falseArray];
};
