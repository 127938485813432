import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  PosMarketRecord,
  PosMarketDeleteRequest,
  PosMarketSearchParameter,
  PosSearchRequest,
  TYPE_SUCCESS,
  UppComponent,
  UppComponentNames
} from '../../service/model';
import {
  SearchPosAction,
  ClearPosResultsAction,
  DeletePosAction,
  ActivatePosDisplayAction,
  StartPosModificationAction,
  DeletePosSearchNotificationAction,
  DeletePosDisplayNotificationAction,
  CopyPosAction,
  DeletePosCreateNotificationAction,
  ClearPosSelectionAction,
  SelectPosAction
} from '../../store/pos/pos-actions';
import {
  selectPosDeleteSending,
  selectPosRecords,
  selectPosSearchSending,
  selectPosSearchNotification
} from '../../store/pos/pos-selector';
import { NavigationService } from '../../service/core/navigation.service';
import { Router } from '@angular/router';
import { PosSearchCriteriaComponent } from './pos-search-criteria/pos-search-criteria.component';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { selectSelectedPosNames } from '../../store/pos/pos-selector';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import { LookupOptions } from '../../core/util/lookup-options';
import {
  DisplayableField,
  EntryType,
  SearchResult
} from '../../base/search/basic-search-result-table/basic-search-result-table.component';
import { PERMISSION_MANAGE_POS } from '../../service/user-permissions.service';
import { PosMarketService } from '../../service/pos-market.service';
import { map } from 'rxjs/operators';


@Component({
  selector: 'ama-ng-upp-pos-search',
  templateUrl: './pos-search.component.html',
  styleUrls: ['./pos-search.component.scss']
})
export class PosSearchComponent implements OnInit {

  @ViewChild(PosSearchCriteriaComponent)
  posSearchCriteriaComponent: PosSearchCriteriaComponent;

  retrievedPosMarkets$: Observable<PosMarketRecord[]>;
  retrievedPosMarketsLookup$: Observable<PosMarketRecord[]>;
  selectedPosNames$: Observable<Set<string>>;
  posSearchSending$: Observable<boolean>;
  posDeleteSending$: Observable<boolean>;
  notificationSearch$: Observable<UppNotification>;
  notificationLookup: UppNotification;

  posMarketSearchResults: PosMarketRecord[];
  selectedPosNames: Set<string>;

  uppComponent = UppComponent.POS_SEARCH;
  mainMessages: NotificationMessages;

  managePermission = PERMISSION_MANAGE_POS;

  lookup = false;
  lookupValues: string[];
  lookupOptions: LookupOptions;

  displayableFields: DisplayableField[] = [
    {
      value: 'description',
      label: 'Description'
    }
  ];

  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly posMarketsService: PosMarketService
  ) {
    this.retrievedPosMarkets$ = this.store.pipe(select(selectPosRecords));
    this.selectedPosNames$ = this.store.pipe(select(selectSelectedPosNames));
    this.posSearchSending$ = this.store.pipe(select(selectPosSearchSending));
    this.posDeleteSending$ = this.store.pipe(select(selectPosDeleteSending));
    this.notificationSearch$ = this.store.pipe(select(selectPosSearchNotification));
  }

  ngOnInit() {
    this.navigationService.enableNavigation();
    this.store.dispatch(new ClearPosSelectionAction({}));
    this.navigationService.setSelectedMenuTitle('POS Search');
    this.navigationService.activatePos();
    this.retrievedPosMarkets$.subscribe(
      posMarketSearchResultsArray => (this.posMarketSearchResults = posMarketSearchResultsArray)
    );
    this.selectedPosNames$.subscribe(
      selectedPosNamesSearchResults => (this.selectedPosNames = selectedPosNamesSearchResults)
    );
    this.mainMessages = {
      error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
      warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
      success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
    };
    this.store.pipe(select(selectNavigation)).subscribe(lookupParam => {
      if (lookupParam) {
        this.lookupOptions = lookupParam;
        this.lookupValues = lookupParam.data;
      }
    });

    this.lookup = !!this.lookupOptions?.lookup;

    if (this.lookup) {
      this.navigationService.disableNavigation();
    }
  }

  searchPosList(searchParameter: PosMarketSearchParameter) {
    const request = this.createSearchPosRequest(searchParameter);
    if (this.lookup) {
      this.retrievedPosMarketsLookup$ = this.posMarketsService.lookupPos(request);
      this.retrievedPosMarketsLookup$.subscribe(
        (result) => this.notificationLookup = result[0].statusNotification);
    } else {
      this.store.dispatch(new SearchPosAction({ request }));
    }
  }

  clearPosResults() {
    this.store.dispatch(new ClearPosResultsAction({}));
    this.store.dispatch(new ClearPosSelectionAction({}));
  }

  showPosDetail(id: string) {
    this.store.dispatch(new ActivatePosDisplayAction({ id: undefined }));
    this.store.dispatch(new DeletePosDisplayNotificationAction({}));
    this.router.navigate(['pos/display/' + id]);
  }

  modifyPosDetail(detailId: string) {
    this.store.dispatch(new StartPosModificationAction({ id: detailId }));
    this.router.navigate(['pos/modify/']);
  }

  copyPosDetail(detailId: string) {
    this.store.dispatch(new CopyPosAction({ id: detailId }));
    this.store.dispatch(new DeletePosCreateNotificationAction({}));
    this.router.navigate(['pos/create/']);
  }

  deletePosEntry(request: PosMarketDeleteRequest) {
    this.store.dispatch(new DeletePosAction({ request }));
  }

  closeNotification() {
    if (this.lookup) {
      this.notificationLookup = undefined;
    } else {
      this.store.dispatch(new DeletePosSearchNotificationAction({}));
    }
  }

  createSearchPosRequest(searchParameter: PosMarketSearchParameter): PosSearchRequest {
    return { version: '1.0', pointOfSale: searchParameter };
  }

  isErrorMessageAvailable(): boolean {
    if (this.posMarketSearchResults) {
      return this.posMarketSearchResults.filter(record => record.statusMessage !== undefined).length > 0;
    }
    return false;
  }

  filterErrorMessages(): string[] {
    const errorMessages: string[] = [];
    if (this.posMarketSearchResults) {
      this.posMarketSearchResults.forEach(record => {
        if (record.statusType !== TYPE_SUCCESS) {
          errorMessages.push(record.statusMessage[0]);
        }
      });
    }
    return errorMessages;
  }

  storeUnsavedPosSearch() {
    if (!this.lookup) {
      this.posSearchCriteriaComponent.storeUnsavedPosSearch();
    }
  }

  getSourceComponent(): UppComponentNames {
    return UppComponentNames.POS;
  }

  getEntryType(): EntryType {
    return EntryType.POINT_OF_SALE;
  }

  getSearchResults(): Observable<PosMarketRecord[]> {
    if (this.lookup) {
      return this.retrievedPosMarketsLookup$;
    }
    return this.retrievedPosMarkets$;
  }

  getNotification(): Observable<UppNotification> {
    if (this.lookup) {
      return of(this.notificationLookup);
    }
    return this.notificationSearch$;
  }

  recordToSearchResult(posRecord: PosMarketRecord): SearchResult {
    const posMarketDetail = posRecord.posMarketDetail;
    return {
      id: posMarketDetail.id,
      name: posMarketDetail.name,
      description: posMarketDetail.description,
      organization: posMarketDetail.organization,
      version: posMarketDetail.version
    };
  }

  entryToUpdateCreator(id: string, name: string, organization: string, version: string, ruleVersion: number): PosMarketDeleteRequest {
    return {
      version,
      pointOfSale: {
        id,
        name,
        organization,
        version: ruleVersion
      }
    };
  }

  entryToUpdateNameResolver(entryToUpdate: PosMarketDeleteRequest) {
    return entryToUpdate.pointOfSale.name;
  }
}
