@if (showFaresV2) {
  <h1 i18n="@@upp.dynamicExclusions.create.title">Create Dynamic Exclusion Rule</h1>
}
@else {
  <h1 i18n="@@upp.fares.create.title">Create Fare Rule</h1>
}

<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
  (openDisplayEvent)="showFareRuleInDisplay()"
>
</ama-upp-notification>

@if (showFaresV2) {
<ama-ng-upp-fares-details-v2 [fareDetailsForm]="fareForm" [fareDetails]="fareRuleUi" [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-fares-details-v2>
}
@else {
<ama-ng-upp-fares-details [fareDetailsForm]="fareForm" [fareDetails]="fareRuleUi" [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-fares-details>
}

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.save"
    *ngIf="!sendingStatus"
    type="submit"
    class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
    [disabled]="!showFaresV2 && !saveButtonEnabled()"
    (click)="saveData()"
  >
    Save
  </button>

  <button
    i18n="@@upp.global.button.save"
    *ngIf="sendingStatus"
    type="button"
    disabled
    class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
  >
    Save
  </button>
</div>
