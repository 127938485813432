import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResolutionNavHelper {
  isMenuCollapsed = true;
  isTitleCollapsed = true;

  hideSideNav() {
    this.isMenuCollapsed = true;
    this.updateTitleVisibility();
  }

  toggleSideNav() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.updateTitleVisibility();
  }

  updateTitleVisibility() {
    const toggleButton = document.getElementById('toggleButton');
    const toggleButtonIsVisible = toggleButton && toggleButton.offsetParent !== null;
    this.isTitleCollapsed = !toggleButtonIsVisible || !this.isMenuCollapsed;
  }
}
