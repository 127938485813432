import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FlightsCreateComponent } from '../../flights/flights-create/flights-create.component';
import { GuardService } from '../../service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFlightRuleCreateGuard  {
  constructor(private readonly guardService: GuardService) {}
  canDeactivate(component: FlightsCreateComponent): Observable<boolean> | boolean {
    return this.guardService.canDeactivate(component);
  }
}
