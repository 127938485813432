import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PosSearchComponent } from '../../points-of-sale/pos-search/pos-search.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivatePosSearchGuard  {
  canDeactivate(component: PosSearchComponent): Observable<boolean> | boolean {
    component.storeUnsavedPosSearch();
    return true;
  }
}
