import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FamiliesCreateComponent } from '../../families/families-create/families-create.component';
import { GuardService } from '../../service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFamilyCreateGuard  {
  constructor(
      private readonly guardService: GuardService) {
  }

  canDeactivate(component: FamiliesCreateComponent): Observable<boolean> | boolean {
    return this.guardService.canDeactivate(component);
  }
}
