import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserDetailsService } from '../../../../service/user-details.service';
import { PERMISSION_MANAGE_MARKET } from '../../../../service/user-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class CanManageMarketGuard  {
  constructor(private readonly userDetailsService: UserDetailsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = this.userDetailsService.loggedInUser?.permissions ?? [];
    return permissions.filter(p => p === PERMISSION_MANAGE_MARKET).length > 0;
  }
}
