import { Observable } from 'rxjs';

/**
 * Interface for components, that are routed to, and can be activated via activation parameters.
 *
 * Components, which have a defined route for them, and are activated based on an activation parameter,
 * should extend this abstract class and provide an implementation for the `isActivated` method.
 * This way the @activationParamGuard can be used to guard the route based on the activation parameter.
 *
 * @see activationParamGuard
 * @see AirFamiliesSearchComponent#isActivated
 */
export abstract class Activatable {
  abstract isActivated(): Observable<boolean>;
}
