import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserDetailsService } from '../../../../service/user-details.service';
import { PERMISSION_VIEW_FLIGHT } from '../../../../service/user-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class CanViewFlightGuard  {
  constructor(private readonly userDetailsService: UserDetailsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = this.userDetailsService.loggedInUser?.permissions ?? [];
    return permissions.filter(p => p === PERMISSION_VIEW_FLIGHT).length > 0;
  }
}
