import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HotelsCreateComponent } from '../../hotels/hotels-create/hotels-create.component';
import { GuardService } from '../../service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateHotelRuleCreateGuard  {
  constructor(private readonly guardService: GuardService) {
  }
  canDeactivate(component: HotelsCreateComponent): Observable<boolean> | boolean {
    return this.guardService.canDeactivate(component);
  }
}
