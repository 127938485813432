import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { FareServiceElements } from '../../fares/model/fare-service-elements';

@Injectable({ providedIn: 'root' })
export class RangeValidator {
    public priceRangeValidator(): ValidatorFn {
        return (formGroup: AbstractControl) => {
            if (!formGroup.get(FareServiceElements.PRICE_DIFFERENCE_MIN)
                || !formGroup.get(FareServiceElements.PRICE_DIFFERENCE_MAX)) {
                return null;
            }
            const startValue = formGroup.get(FareServiceElements.PRICE_DIFFERENCE_MIN)?.value;
            const endValue = formGroup.get(FareServiceElements.PRICE_DIFFERENCE_MAX)?.value;
            if (startValue === null || startValue === undefined || endValue === null || endValue === undefined) {
                return null;
            }
            if (startValue < endValue) {
                return null;
            }
            return { priceRange: { start: startValue, end: endValue } };
        };
    }

    public departureRangeValidator():
        ValidatorFn {
        return (formGroup: AbstractControl) => {
            if (!formGroup.get(FareServiceElements.DEPARTURE_RANGE_ACTIVE)?.value) {
                return null;
            } else {
                if (!formGroup.get(FareServiceElements.DEPATURE_TIME_WINDOW_MIN)
                    || !formGroup.get(FareServiceElements.DEPATURE_TIME_WINDOW_MAX)) {
                    return null;
                }
                const startValue = formGroup.get(FareServiceElements.DEPATURE_TIME_WINDOW_MIN)?.value;
                const endValue = formGroup.get(FareServiceElements.DEPATURE_TIME_WINDOW_MAX)?.value;
                if (startValue === null || startValue === undefined || endValue === null || endValue === undefined) {
                    return null;
                }
                if (startValue < endValue) {
                    return null;
                }
                return { departureRange: { start: startValue, end: endValue } };
            }
        };
    }
}
