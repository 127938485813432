import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HotelsState, HOTELS_FEATURE } from './hotels-state';

export const selectHotels = createFeatureSelector<HotelsState>(HOTELS_FEATURE);

export const selectHotelRuleCreateValue = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.create.value
);

export const selectHotelRuleCreateSending = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.create.sending
);

export const selectHotelRuleCreateNotification = createSelector(selectHotels, (state: HotelsState) => {
  if (state.notification && state.notification.create) {
    return state.notification.create;
  }
  return undefined;
});

export const selectHotelModifyValue = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.modify.value
);

export const selectHotelModifySending = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.modify.sending
);

export const selectHotelModifyActivated = createSelector(selectHotels, (state: HotelsState) => {
  if (state.hotelRule.modify.active) {
    return state.hotelRule.modify.active;
  }
  return false;
});

export const selectHotelModifyNotification = createSelector(selectHotels, (state: HotelsState) => {
  if (state.notification && state.notification.modify) {
    return state.notification.modify;
  }
  return undefined;
});

export const selectHotelRuleDisplayValue = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.display.value
);

export const selectHotelRuleDisplayNotification = createSelector(selectHotels, (state: HotelsState) => {
  if (state.notification && state.notification.display) {
    return state.notification.display;
  }
  return undefined;
});

export const selectHotelRuleDisplayActivated = createSelector(selectHotels, (state: HotelsState) => {
  if (state.hotelRule.display.active) {
    return state.hotelRule.display.active;
  }
  return false;
});

export const selectHotelRules = createSelector(selectHotels, (state: HotelsState) => state.hotelRules);

export const selectHotelRuleSearchSending = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.search.sending
);

export const selectHotelRuleSearchNotification = createSelector(selectHotels, (state: HotelsState) => {
  if (state.notification && state.notification.search) {
    return state.notification.search;
  }
  return undefined;
});

export const selectHotelRuleSearchValue = createSelector(
  selectHotels,
  (state: HotelsState) => state.hotelRule.search.value
);

export const selectHotelRuleDeleteSending = createSelector(selectHotels, (state: HotelsState) => {
  if (state && state.hotelRule && state.hotelRule.delete) {
    return state.hotelRule.delete.sending;
  }
  return undefined;
});
