import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonGroupItem } from '../../model/button-group-item';

@Component({
  selector: 'ama-ng-upp-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent {

  @Input() items!: ButtonGroupItem[];
  @Output() toggled = new EventEmitter<ButtonGroupItem>();

  toggle(item: ButtonGroupItem): void {
    if (!item.canToggle || item.canToggle()) {
      item.active = !item.active;
      this.toggled.emit(item);
    }
  }
}
