<h1 i18n="@@upp.flights.display.title">Display Flight Rule</h1>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-flights-details
  [flightDetailsForm]="flightForm"
  [flightDetails]="flightRule"
  [readonly]="readonly"
  [parent]="uppComponent">
</ama-ng-upp-flights-details>

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.copy"
    type="button"
    class="btn btn-outline-primary mb-2 mb-md-0 me-2 actionButton"
    (click)="copyFlight()"
    [disabled]="!hasManageAccess"
  >
    Copy
  </button>
  <button
    i18n="@@upp.global.button.modify"
    type="button"
    [disabled]="!modifyButtonEnabled()"
    class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
    (click)="modifyFlight()"
  >
    Modify
  </button>
</div>
