import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FareSubType, FareType, JourneyFare } from '../../model/journey';
import { ValueLabelItem } from '../../../model/value-label-item';
import { ButtonGroupItem } from '../../../model/button-group-item';
import { partition } from '../../../util/arrayUtils';
import { getPropertyByKey } from '../../../util/utils';

@Component({
  selector: 'ama-ng-upp-fare-content',
  templateUrl: './fare-content.component.html'
})
export class FareContentComponent implements OnInit, OnChanges {
  @Input() fareFormGroup: UntypedFormGroup;
  @Input() fareScope: JourneyFare;
  @Input() readonly = false;

  fareItems: FareItem[];

  fareScopeButtonGroupItems: ButtonGroupItem[];
  fareScopeButtonGroupMap: { [key: string]: ButtonGroupItem };

  readonly allFareTypes: string[] = Object.values(FareType);

  fareTypesMap = {
    [FareType.RP]: {
      value: FareType.RP,
      label: $localize`:@upp.flights.details.fareType.RP:RP - PUBLISHED FARES`
    },
    [FareType.RU]: {
      value: FareType.RU,
      label: $localize`:@upp.flights.details.fareType.RU:RU - PRIVATE/NEGOTIATED FARES`
    },
    [FareType.RW]: {
      value: FareType.RW,
      label: $localize`:@upp.flights.details.fareType.RW:RW - CORPORATE FARES`
    }
  };

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get fareTypeNames(): AbstractControl {
    return this.fareFormGroup.get('fareTypeNames');
  }

  groupValueFn = (groupName: string) => getPropertyByKey(this.fareTypesMap, groupName);

  ngOnInit(): void {
    this.fareItems = [
      this.fareTypesMap[FareType.RP],
      {
        value: FareSubType.TO,
        label: $localize`:@upp.flights.details.fareSubType.TO:TO - TOUR OPERATOR`,
        parent: FareType.RU
      },
      {
        value: FareSubType.CS,
        label: $localize`:@upp.flights.details.fareSubType.CS:CS - CONSOLIDATOR`,
        parent: FareType.RU
      },
      {
        value: FareSubType.VF,
        label: $localize`:@upp.flights.details.fareSubType.VF:VF - VISIT FRIEND`,
        parent: FareType.RU
      },
      this.fareTypesMap[FareType.RW]
    ];
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.fareScopeButtonGroupItems = this.generateFareScopeButtonGroupItems();
    this.fareScopeButtonGroupMap = this.fareScopeButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    this.fareFormGroup.setControl(
      'fareTypes',
      this.formBuilder.control({ value: this.fareScope?.fareTypes, disabled: this.readonly })
    );
    this.fareFormGroup.setControl(
      'subFareTypes',
      this.formBuilder.control({ value: this.fareScope?.subFareTypes, disabled: this.readonly })
    );
    this.fareFormGroup.setControl(
      'fareTypeNames',
      this.formBuilder.control({ value: this.fareScope?.fareTypeNames, disabled: this.readonly }, [Validators.required])
    );
  }

  fareTypeNamesChanged(newValue: string[]): void {
    const [fareTypes, subFareTypes] = partition<string>(newValue ?? [], (value) => this.allFareTypes.includes(value));

    if (subFareTypes.length > 0) {
      fareTypes.push(FareType.RU);
    }
    this.fareFormGroup.patchValue({
      fareTypes,
      subFareTypes
    });
  }

  private generateFareScopeButtonGroupItems(): ButtonGroupItem[] {
    return [
      {
        name: 'fareType',
        active: true,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.fareType.label:Fare type`,
        // As this is the only option for now we will activate it by default and will disable toggling.
        canToggle: () => false
      }
    ];
  }
}

interface FareItem extends ValueLabelItem<string> {
  parent?: any;
}
