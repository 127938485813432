<form [formGroup]="familiesDetailsForm" id="familiesDetailsForm">
  <div class="mb-3">
    <div class="row mt-3">
      <div class="col text-end">
        <button
          i18n="@@upp.global.button.clear"
          *ngIf="!readonly"
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="clearScreen()"
        >
          Clear
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-md-6 mb-5">
        <label class="label-font" for="name"
          ><ng-container i18n="@@upp.families.details.nameLabel">Name</ng-container><span class="mandatory">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="name"
          [formControlName]="elements.NAME"
          [readonly]="restrictInput()"
          [ngClass]="{ 'is-invalid': !familiesDetailsForm.controls[elements.NAME].valid }"
          placeholder="Hotel family name"
          i18n-placeholder="@@upp.families.common.namePlaceholder"
          ngbTooltip="Enter the Hotel Family name with letters, numbers or spaces (up to 30 characters)"
          i18n-ngbTooltip="@@upp.families.common.nameTooltip"
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
        />
        <div *ngIf="!familiesDetailsForm.controls[elements.NAME].valid" class="invalid mt-1">
          {{ getNameValidationError() }}
        </div>
      </div>
      <div class="col col-md-6 mb-5">
        <label i18n="@@upp.families.details.descriptionLabel" class="label-font" for="description">Description</label>
        <input
          type="text"
          class="form-control"
          id="description"
          [formControlName]="elements.DESCRIPTION"
          [ngClass]="{ 'is-invalid': !familiesDetailsForm.controls[elements.DESCRIPTION].valid }"
          [readonly]="this.readonly"
          placeholder="Hotel family description"
          i18n-placeholder="@@upp.families.details.descriptionPlaceholder"
          ngbTooltip="Enter a description for this family, max. 128 alpha numeric"
          i18n-ngbTooltip="@@upp.families.details.descriptionTooltip"
        />
        <div *ngIf="!familiesDetailsForm.controls[elements.DESCRIPTION].valid" class="invalid mt-1">
          <ng-container i18n="@@upp.validation.families.numberOfCharacters"
            >Maximum/Minimum number of characters acceptable:</ng-container
          >
          {{ familiesDetailsForm.controls[elements.DESCRIPTION].errors.maxlength.requiredLength }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div role="radiogroup" aria-labelledby="familyTypeDetails">
          <span i18n="@@upp.families.details.hotelFamilyTypeLabel" class="label-font" id="familyTypeDetails">Family Type</span>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="familyTypeHotelChains"
              [value]="elements.CHAINS"
              [name]="elements.HOTEL_TYPE"
              [formControlName]="elements.HOTEL_TYPE"
              (change)="changeHotelType(elements.CHAINS)"
            />
            <label i18n="@@upp.families.details.hotelChainLabel" class="form-check-label" for="familyTypeHotelChains">
              Hotel Chain
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              id="familyTypeHotelPropertyCodes"
              [value]="elements.PROPERTY_CODES"
              [name]="elements.HOTEL_TYPE"
              [formControlName]="elements.HOTEL_TYPE"
              (change)="changeHotelType(elements.PROPERTY_CODES)"
            />
            <label
              i18n="@@upp.families.details.hotelPropertyCodeLabel"
              class="form-check-label"
              for="familyTypeHotelPropertyCodes"
            >
              Hotel Property Code
            </label>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col">
        <ama-ng-upp-multi-select-badges
          *ngIf="isHotelCriteriaActive(elements.CHAINS)"
          [formElementName]="elements.CHAINS"
          formElementLabel="Hotel Chains"
          i18n-formElementLabel="@@upp.global.criteria.hotelChains.label"
          [formGroup]="familiesDetailsForm"
          placeholder="Hotel chain"
          i18n-placeholder="@@upp.global.criteria.hotelChains.placeholder"
          [numberOfBadges]="1000"
          [readonlyMode]="readonly"
          [badgeItems]="familyDetails.hotelChains"
          notFoundText="No hotel chain found"
          i18n-notFoundText="@@upp.global.criteria.hotelChains.notFoundText"
          tooltipText="Enter 2 character hotel chain(s)"
          i18n-tooltipText="@@upp.global.criteria.hotelChains.tooltip"
          [mandatoryMode]="true"
          [parent]="COMPONENT_NAME"
        >
        </ama-ng-upp-multi-select-badges>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ama-ng-upp-multi-select-badges
          *ngIf="isHotelCriteriaActive(elements.PROPERTY_CODES)"
          [formElementName]="elements.PROPERTY_CODES"
          formElementLabel="Hotel Property Codes"
          i18n-formElementLabel="@@upp.global.criteria.hotelPropertyCodes.label"
          [formGroup]="familiesDetailsForm"
          placeholder="Hotel property code"
          i18n-placeholder="@@upp.global.criteria.hotelPropertyCodes.placeholder"
          [numberOfBadges]="1000"
          [readonlyMode]="readonly"
          [badgeItems]="familyDetails.hotelPropertyCodes"
          notFoundText="No hotel property code found"
          i18n-notFoundText="@@upp.global.criteria.hotelPropertyCodes.notFoundText"
          tooltipText="Enter 8 character hotel property code(s)"
          i18n-tooltipText="@@upp.global.criteria.hotelPropertyCodes.tooltip"
          [mandatoryMode]="true"
          [parent]="COMPONENT_NAME"
        >
        </ama-ng-upp-multi-select-badges>
      </div>
    </div>
  </div>
</form>
