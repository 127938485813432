import { ResponseAlert } from '../model/common/response-status';
import { BaseResponse } from '../../base/model/base-response';
import { BaseUi } from '../../base/model/base-ui';
import { ResponseStatus } from '../model';

export const SUCCESS_STATUS = 'success';
export const WARNING_STATUS = 'warning';
export const FAILED_STATUS = 'error';

export const MISSING_RESULT_STATUS = 'Result status could not be retrieved.';
export const UNKNOWN_RESULT_STATUS = 'An unknown status has been retrieved.';

export const extractMessages = (alerts: ResponseAlert[] | undefined): string[] => {
  const messages: string[] = [];
  if (!alerts) {
    return messages;
  }

  for (const alert of alerts) {
    if (alert.message) {
      messages.push(alert.message);
    } else {
      messages.push('An unspecified error occurred');
    }
  }
  return messages;
};

export const buildErrorOrWarning = (warningUi: BaseUi, errorUi: BaseUi, response: BaseResponse): BaseUi => {
  if (!isValidResponse(response.status)) {
    return errorUi;
  }
  switch (response.status.state) {
    case WARNING_STATUS:
      return {
        ...warningUi,
        statusNotification: {
          ...warningUi.statusNotification,
          warning: extractMessages(response.status.warnings)
        }
      };
    case FAILED_STATUS:
      return {
        ...errorUi,
        statusNotification: {
          ...errorUi.statusNotification,
          error: extractMessages(response.status.errors)
        }
      };
    default:
      return warningUi;
  }
};

const isValidResponse = (responseStatus: ResponseStatus): boolean => {
  if (responseStatus && responseStatus.state !== undefined) {
    return true;
  }
  return false;
};
