import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../service/core/navigation.service';
import { FamilyUi, initialFamilyUi } from '../model';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  selectFamilyDisplayValue,
  selectFamilyRuleDisplayActivated,
  selectFamilyDisplayNotification
} from '../../store/families/families-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppComponent } from '../../service/model/upp-component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  ActivateFamilyDisplayAction,
  DeleteFamilyDisplayNotificationAction,
  SetFamilyModifyValueAction,
  StartFamilyModificationAction
} from '../../store/families/families-action';
import { PERMISSION_MANAGE_FAMILY } from '../../service/user-permissions.service';
import { UserDetailsService } from '../../service/user-details.service';
import { RouteURL } from '../../model/route-url';

@Component({
  selector: 'ama-ng-upp-families-display',
  templateUrl: './families-display.component.html',
  styleUrls: ['./families-display.component.scss']
})
export class FamiliesDisplayComponent implements OnInit {
  familyForm?: UntypedFormGroup | null;
  family: FamilyUi = initialFamilyUi;
  notification$!: Observable<UppNotification | undefined>;
  displayActivated!: boolean;

  readonly = true;
  isFamilyTypeDisabled = true;
  uppComponent = UppComponent.FAMILIES_DISPLAY;
  mainMessages: NotificationMessages;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.store
      .pipe(select(selectFamilyRuleDisplayActivated))
      .subscribe((activated) => (this.displayActivated = activated));
    this.notification$ = this.store.pipe(select(selectFamilyDisplayNotification));
    this.mainMessages = {
      error: $localize`:@@upp.families.display.mainErrorText:The following errors for family appeared:`,
      warning: $localize`:@@upp.families.display.mainWarningText:The following warning for family appeared:`,
      success: $localize`:@@upp.families.display.mainSuccessText:The family was stored successfully.`
    };
  }

  ngOnInit() {
    this.createForm();
    this.navigationService.setSelectedMenuTitle('Family display');
    this.navigationService.activateFamilies();
    if (!this.displayActivated) {
      this.router.navigate([RouteURL.familyHotelSearch]);
    } else {
      this.loadFamily();
    }
  }

  createForm() {
    this.familyForm = this.formBuilder.group({});
    this.store.pipe(select(selectFamilyDisplayValue)).subscribe((value) => (this.family = value));
  }

  loadFamily() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      if (id) {
        this.activateFamilyWithId(id);
      }
    });
  }

  activateFamilyWithId(paramId: string) {
    this.store.dispatch(new ActivateFamilyDisplayAction({ id: paramId }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteFamilyDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess() && this.family?.name !== '';
  }

  modifyFamily() {
    this.store.dispatch(new StartFamilyModificationAction({ id: undefined }));
    this.store.dispatch(new SetFamilyModifyValueAction({ familyUi: this.family }));
    this.router.navigate([`${RouteURL.familyHotelModify}/`], { queryParams: { sourceView: 'display' } });
  }

  hasManageAccess() {
    const permissions = this.userDetailsService.loggedInUser?.permissions ?? [];
    return permissions.filter((p) => p === PERMISSION_MANAGE_FAMILY).length > 0;
  }
}
