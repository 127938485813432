import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UppComponent, UppComponentNames } from '../../service/model';
import { UserDetailsService } from '../../service/user-details.service';
import { FamilyServiceElements, FamilyUi, initialFamilyUi, VALIDATORS } from '../model';
import { UppValidatorService } from '../../service/upp-validator.service';
import { NavigationService } from '../../service/core/navigation.service';
import { alphanumericSpaceMax30 } from '../../service/model/common/validators';

@Component({
  selector: 'ama-ng-upp-families-details',
  templateUrl: './families-details.component.html',
  styleUrls: ['./families-details.component.scss']
})
export class FamiliesDetailsComponent implements OnChanges {
  @Input() familiesDetailsForm?: UntypedFormGroup | null;
  @Input() familyDetails: FamilyUi = initialFamilyUi;
  @Input() readonly = false;
  @Input() isFamilyTypeDisabled = false;
  @Input() parent!: UppComponent;

  hotelType!: string;

  readonly elements = FamilyServiceElements;
  readonly COMPONENT_NAME = UppComponentNames.FAMILIES;
  readonly UNDEFINED_FORM_ERROR = 'Form is not defined';

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly validatorService: UppValidatorService,
    private readonly navigationService: NavigationService
  ) {
    this.validatorService.setComponentValidation(this.COMPONENT_NAME, VALIDATORS);
  }

  ngOnChanges() {
    this.initScreen();
    this.navigationService.enableNavigation();
  }

  initScreen() {
    this.createForm();
    this.familiesDetailsForm?.patchValue(this.familyDetails);
    this.hotelType = this.familyDetails.hotelFamilyType;
  }

  createForm() {
    if (this.familiesDetailsForm != null) {
      this.familiesDetailsForm.addControl(
        FamilyServiceElements.ORGANIZATION,
        this.formBuilder.control(this.userDetailsService.getLssOrganization())
      );
      this.familiesDetailsForm.addControl(
        FamilyServiceElements.NAME,
        this.formBuilder.control(
          { value: '', disabled: this.readonly },
          { validators: [Validators.maxLength(30), Validators.pattern(alphanumericSpaceMax30)] }
        )
      );
      this.familiesDetailsForm.addControl(
        FamilyServiceElements.DESCRIPTION,
        this.formBuilder.control('', Validators.maxLength(128))
      );
      if (this.familyDetails.hotelFamilyType) {
        this.familiesDetailsForm.addControl(
          FamilyServiceElements.HOTEL_TYPE,
          this.formBuilder.control({ value: this.familyDetails.hotelFamilyType, disabled: this.isFamilyTypeDisabled })
        );
      } else {
        this.familiesDetailsForm.addControl(
          FamilyServiceElements.HOTEL_TYPE,
          this.formBuilder.control({ value: FamilyServiceElements.CHAINS, disabled: this.isFamilyTypeDisabled })
        );
      }

      this.familiesDetailsForm.addControl('id', this.formBuilder.control({ value: '' }));
      this.familiesDetailsForm.addControl(
        'hotelChains',
        this.formBuilder.control('', {
          validators: this.validatorService.getValidatorsForControl(UppComponentNames.FAMILIES, 'hotelChains', false)
        })
      );
      this.familiesDetailsForm.addControl(
        'hotelPropertyCodes',
        this.formBuilder.control('', {
          validators: this.validatorService.getValidatorsForControl(UppComponentNames.FAMILIES, 'hotelPropertyCodes', false)
        })
      );
    }
  }

  clearScreen() {
    this.familyDetails = initialFamilyUi;

    this.familiesDetailsForm?.get(FamilyServiceElements.NAME)?.setValue('');
    this.familiesDetailsForm?.get(FamilyServiceElements.DESCRIPTION)?.setValue('');
    if (this.familiesDetailsForm?.contains(FamilyServiceElements.CHAINS)) {
      this.familiesDetailsForm?.get(FamilyServiceElements.CHAINS)?.setValue('');
    }
    if (this.familiesDetailsForm?.contains(FamilyServiceElements.PROPERTY_CODES)) {
      this.familiesDetailsForm.get(FamilyServiceElements.PROPERTY_CODES)?.setValue('');
    }
  }

  getNameValidationError() {
    if (
      this.familiesDetailsForm?.get(FamilyServiceElements.NAME)?.valid ||
      !this.familiesDetailsForm?.get(FamilyServiceElements.NAME)?.errors
    ) {
      return '';
    }
    if (this.familiesDetailsForm.get(FamilyServiceElements.NAME)?.errors?.maxlength) {
      const requiredLength = this.familiesDetailsForm.get(FamilyServiceElements.NAME)?.errors?.maxlength.requiredLength;
      return (
        $localize`:@@upp.validation.families.details.maximumCharacters:Maximum number of characters acceptable: ` + ' ' + requiredLength
      ); // NOSONAR
    }
    if (this.familiesDetailsForm.get(FamilyServiceElements.NAME)?.errors?.pattern) {
      return $localize`:@@upp.validation.families.details.typeOfCharacters:Only alphanumeric characters and spaces are acceptable`;
    }
    return '';
  }

  changeHotelType(value: any) {
    this.hotelType = value;

    if (this.familiesDetailsForm?.contains(FamilyServiceElements.CHAINS)) {
      this.familiesDetailsForm.get(FamilyServiceElements.CHAINS)?.setValue('');
    }
    if (this.familiesDetailsForm?.contains(FamilyServiceElements.PROPERTY_CODES)) {
      this.familiesDetailsForm.get(FamilyServiceElements.PROPERTY_CODES)?.setValue('');
    }
  }

  isHotelCriteriaActive(hotelType: string): boolean {
    return hotelType === this.hotelType;
  }

  restrictInput(): boolean {
    return this.parent !== UppComponent.FAMILIES_CREATE;
  }
}
