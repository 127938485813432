import { DEFAULT_ERROR, EIGHT_SYMBOLS, NAMES, THREE_SYMBOLS, TWO_SYMBOLS } from '../../service/model/common/validation-messages';
import { DESCRIPTION,EIGHT_SYMBOLS_LIST, NAMES_LIST, NAME,
  THREE_LETTERS_COUNTRY_CODES, TWO_LETTERS_COUNTRY_CODES } from '../../service/model/common/validators';
import { ValidationProvider } from '../../service/upp-validator.service';

export class HotelServiceElements {
  static readonly NAME = 'name';
  static readonly DESCRIPTION = 'description';
  static readonly POINT_OF_SALES = 'pointOfSaleNames';
  static readonly DESTINATIONS = 'destinationNames';
  static readonly HOTEL_CHAINS_FAMILIES = 'hotelChainsFamilies';
  static readonly HOTEL_PROPERTY_CODES_FAMILIES = 'hotelPropertyCodesFamilies';
  static readonly MASTER_CHAINS = 'masterChains';
  static readonly CHAINS = 'chains';
  static readonly PROPERTY_CODES = 'propertyCodes';
  static readonly RATE_CODES = 'rateCodes';
  static readonly RATE_FAMILIES = 'rateFamilies';
  static readonly COMMISSIONABLE = 'commissionable';
  static readonly SOURCE_TYPES = 'sourceTypes';
}

export const VALIDATORS = new Map<string, ValidationProvider>([
  [HotelServiceElements.NAME, {validator: NAME, massages: DEFAULT_ERROR}],
  [HotelServiceElements.DESCRIPTION, {validator: DESCRIPTION, massages: DEFAULT_ERROR}],
  [HotelServiceElements.RATE_FAMILIES, {validator: THREE_LETTERS_COUNTRY_CODES, massages: THREE_SYMBOLS}],
  [HotelServiceElements.RATE_CODES, {validator: THREE_LETTERS_COUNTRY_CODES, massages: THREE_SYMBOLS}],
  [HotelServiceElements.CHAINS, {validator: TWO_LETTERS_COUNTRY_CODES, massages: TWO_SYMBOLS}],
  [HotelServiceElements.MASTER_CHAINS, {validator: TWO_LETTERS_COUNTRY_CODES, massages: TWO_SYMBOLS}],
  [HotelServiceElements.PROPERTY_CODES, {validator: EIGHT_SYMBOLS_LIST, massages: EIGHT_SYMBOLS}],
  [HotelServiceElements.POINT_OF_SALES, {validator: NAMES_LIST, massages: NAMES}],
  [HotelServiceElements.DESTINATIONS, {validator: NAMES_LIST, massages: NAMES}],
  [HotelServiceElements.HOTEL_CHAINS_FAMILIES, {validator: NAMES_LIST, massages: NAMES}],
  [HotelServiceElements.HOTEL_PROPERTY_CODES_FAMILIES, {validator: NAMES_LIST, massages: NAMES}]
]);
