import { AbstractControl, FormArray } from '@angular/forms';

export const getFormControlByPath = (parent: AbstractControl, path: string): AbstractControl => {
  if (!path) {
    return parent;
  }

  if (!parent) {
    return null;
  }

  const indexOfSeparator = path.indexOf('.');
  let propertyName = path;
  if (indexOfSeparator > 0) {
    propertyName = path.substring(0, indexOfSeparator);
    path = path.substring(indexOfSeparator + 1);
  } else {
    path = null;
  }

  let innerFormGroup: AbstractControl;

  const indexOfOpeningBracket = propertyName.indexOf('[');
  if (indexOfOpeningBracket > 0) {
    const arrayIndex = propertyName.substring(indexOfOpeningBracket + 1, propertyName.length - 1);
    propertyName = propertyName.substring(0, indexOfOpeningBracket);
    innerFormGroup = (parent.get(propertyName) as FormArray).at(parseInt(arrayIndex, 10));
  } else {
    innerFormGroup = parent.get(propertyName);
  }

  if (path?.length > 0) {
    return getFormControlByPath(innerFormGroup, path);
  }

  return innerFormGroup;
};

export const getTopParent = (control: AbstractControl): AbstractControl => {
  if (control.parent) {
    return getTopParent(control.parent);
  }
  return control;
};
