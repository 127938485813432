import { Component, OnChanges, Input, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UppComponent, UppComponentNames, UppViewNames } from '../../service/model/upp-component';
import { UserDetailsService } from '../../service/user-details.service';
import { select, Store } from '@ngrx/store';
import { NavigationService } from '../../service/core/navigation.service';
import { FareRuleUi, initialFareRuleUi, FareRuleUiReference, FareRuleUiCriteria } from '../model/fare-rule-ui';
import { SelectableCriteria, SelectableCriteriaWithParams } from '../../base/search-criteria/selectable-criteria';
import { PERMISSION_VIEW_MARKET, PERMISSION_VIEW_POS } from '../../service/user-permissions.service';
import { LookupOptions } from '../../core/util/lookup-options';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import { FareServiceElements, PriceRangeType, ServiceType, VALIDATORS } from '../model/fare-service-elements';
import { Options, ChangeContext } from '@design-factory/design-factory';
import { selectAllAvailablePosRecords } from '../../store/pos/pos-selector';
import { selectAllAvailableMarketsRecords } from '../../store/markets/markets-selector';
import { UppValidatorService } from '../../service/upp-validator.service';
import { integerNumberValidator } from '../../components/form-validators/integer-number.validator';
import { ExclusionKeys, ReferenceKeys, initReferenceCriteria, initExclusionCriteria, cabinOptions } from '../model/fare-criteria-data';
import { getCriteria } from '../../util/criteria-utils';
import { RangeValidator } from '../../components/form-validators/range.validator';
import { positiveNumberValidator } from '../../components/form-validators/positive-number.validator';
import { getPropertyByKey } from '../../util/utils';

@Component({
  selector: 'ama-ng-upp-fares-details',
  templateUrl: './fares-details.component.html',
  styleUrls: ['./fares-details.component.scss']
})
export class FaresDetailsComponent implements OnChanges, AfterViewInit {

  @Input() fareDetailsForm!: UntypedFormGroup;
  @Input() fareDetails: FareRuleUi = initialFareRuleUi;
  @Input() readonly!: boolean;
  @Input() parent!: UppComponent;

  @ViewChild('cabinsTemplate') cabinsTemplate!: TemplateRef<any>;
  @ViewChild('serviceTypesTemplate') serviceTypesTemplate!: TemplateRef<any>;
  @ViewChild('contentTemplate') contentTemplate!: TemplateRef<any>;
  @ViewChild('priceRangeTemplate') priceRangeTemplate!: TemplateRef<any>;
  @ViewChild('departureTimeRangeTemplate') departureTimeRangeTemplate!: TemplateRef<any>;

  readonly elements = FareServiceElements;
  readonly COMPONENT_NAME = UppComponentNames.FARES;
  readonly cabinOptions = cabinOptions;

  MIN_DEPARTURE_SLIDER = -1440;
  MAX_DEPARTURE_SLIDER = 1440;
  minDepatureTimeValue: number = this.MIN_DEPARTURE_SLIDER;
  maxDepatureTimeValue: number = this.MAX_DEPARTURE_SLIDER;
  sliderControl = new UntypedFormControl([this.MIN_DEPARTURE_SLIDER, this.MAX_DEPARTURE_SLIDER]);
  nameRegex = '^[A-Z0-9]{0,30}$';
  contentOptions!: string;
  shouldEnableNumberOfConnections = false;
  exclusionCriteria: SelectableCriteriaWithParams[];
  referenceCriteria: SelectableCriteriaWithParams[];
  lookupOptions!: LookupOptions;
  availablePosNames: string[] = [];
  availableMarketsNames: string[] = [];

  serviceTypeOptions = [
    ServiceType.ALL, ServiceType.D, ServiceType.N, ServiceType.C
  ];

  posLookupOptions: LookupOptions = {
    destinationComponent: UppComponentNames.POS,
    sourceComponent: UppComponentNames.FARES,
    sourceView: UppViewNames.CREATE
  };

  marketLookupOptions: LookupOptions = {
    destinationComponent: UppComponentNames.MARKETS,
    sourceComponent: UppComponentNames.FARES,
    sourceView: UppViewNames.CREATE
  };

  sliderOptions: Options = {
    floor: -1440,
    ceil: 1440,
    ticksArray: [0],
    showTicks: true,
    ariaLabel: 'Label minimum value',
    ariaLabelHigh: 'Label maximum value',
    animate: false,
    noSwitching: true
  };

  readonlySlider: Options = {
    ...this.sliderOptions,
    disabled: true
  };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly store: Store,
    private readonly validatorService: UppValidatorService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly rangeValidator: RangeValidator
  ) {
    this.validatorService.setComponentValidation(this.COMPONENT_NAME, VALIDATORS);
    this.referenceCriteria = initReferenceCriteria();
    this.exclusionCriteria = initExclusionCriteria();
  }

  ngOnChanges() {
    this.navigationService.enableNavigation();
    this.store.pipe(select(selectNavigation)).subscribe(lookupOptions => {
      if (lookupOptions) {
        this.lookupOptions = lookupOptions;
      }
    });
    if (this.fareDetails.id) {
      this.posLookupOptions.sourceView = UppViewNames.MODIFY;
      this.marketLookupOptions.sourceView = UppViewNames.MODIFY;
    }
    this.setupCriteria();
    this.initScreen();
    this.initSelectableCriteriaButtonsAndElements(this.fareDetails);
  }

  ngAfterViewInit(): void {
    this.initializeCustomTemplates();
    this.changeDetector.detectChanges();
  }

  initScreen() {
    this.createForm();
    this.fareDetailsForm.patchValue(this.fareDetails);
    this.contentOptions = FareServiceElements.CONTENT_OPTIONS_NDC;
    this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(ExclusionKeys.ST)?.valueChanges
      .subscribe((currentValue) => this.onChangeServiceTypes(currentValue));
  }

  createForm() {
    if (this.fareDetailsForm != null) {
      this.fareDetailsForm.addControl('id', this.formBuilder.control(''));
      this.fareDetailsForm.addControl(
        FareServiceElements.RULE,
        this.formBuilder.group({
          version: this.formBuilder.control(''),
          organization: this.formBuilder.control(this.userDetailsService.getLssOrganization()),
          name: this.formBuilder.control(
            { value: '', disabled: this.readonly },
            { validators: [Validators.maxLength(30), Validators.pattern(this.nameRegex)] }
          ),
          description: this.formBuilder.control(
            { value: undefined, disabled: this.readonly },
            Validators.maxLength(128)
          ),
          active: this.formBuilder.control({ value: true, disabled: this.readonly }),
          action: this.formBuilder.control({ value: '', disabled: this.readonly })
        })
      );

      this.fareDetailsForm.addControl(
        FareServiceElements.REFERENCE,
        this.formBuilder.group({
          cabins: this.formBuilder.control({ value: null, disabled: this.readonly }),
          fareTypes: this.formBuilder.control({ value: '', disabled: this.readonly }),
          pointOfSaleNames: this.formBuilder.control({ value: '', disabled: this.readonly }),
          originMarkets: this.formBuilder.control({ value: '', disabled: this.readonly }),
          destinationMarkets: this.formBuilder.control({ value: '', disabled: this.readonly }),
          airlines: this.formBuilder.control({ value: '', disabled: this.readonly })
        })
      );

      this.minDepatureTimeValue = this.fareDetails.exclusion?.departureTimeWindowMin ?
        this.fareDetails.exclusion.departureTimeWindowMin : this.MIN_DEPARTURE_SLIDER;
      this.maxDepatureTimeValue = this.fareDetails.exclusion?.departureTimeWindowMax ?
        this.fareDetails.exclusion.departureTimeWindowMax : this.MAX_DEPARTURE_SLIDER;

      this.fareDetailsForm.addControl(
        FareServiceElements.EXCLUSION,
        this.formBuilder.group({
          content: this.formBuilder.control({ value: FareServiceElements.CONTENT_OPTIONS_NDC, disabled: this.readonly }),
            priceDifferenceMin: this.formBuilder.control(
              { value: null, disabled: this.readonly },
            { validators: [integerNumberValidator, Validators.min(-500), Validators.max(500)] }),
            priceDifferenceMax: this.formBuilder.control(
              { value: null, disabled: this.readonly },
            { validators: [integerNumberValidator,positiveNumberValidator, Validators.max(5000)]}),
          priceRangeType: this.formBuilder.control({value: PriceRangeType.RATE, disabled: this.readonly}),
          ruleEffectiveDatesStart: this.formBuilder.control({value: '', disabled: this.readonly}),
          ruleEffectiveDatesEnd: this.formBuilder.control({value: '', disabled: this.readonly}),
          fareTypes: this.formBuilder.control({value: '', disabled: this.readonly}),
          serviceTypes: this.formBuilder.control({value: '', disabled: this.readonly}),
            departureRangeActive: this.formBuilder.control({ value: false, disabled: this.readonly }),
            departureTimeSlider: this.sliderControl,
          departureTimeWindowMin: this.formBuilder.control({ value: null, disabled: this.readonly },
              {
                validators: [
                  integerNumberValidator,
                  Validators.min(this.MIN_DEPARTURE_SLIDER),
                Validators.max(this.MAX_DEPARTURE_SLIDER)]
            }),
          departureTimeWindowMax: this.formBuilder.control({ value: null, disabled: this.readonly },
              {
                validators: [
                  integerNumberValidator,
                  Validators.min(this.MIN_DEPARTURE_SLIDER),
                Validators.max(this.MAX_DEPARTURE_SLIDER)]
            }),
            numberOfConnections: this.formBuilder.control({ value: 0, disabled: this.readonly })
          },
          {
            validators:
              [this.rangeValidator.priceRangeValidator(), this.rangeValidator.departureRangeValidator()]
          }
        ));
    }
  }

  clearScreen() {
    this.fareDetails = initialFareRuleUi;
    this.fareDetailsForm.patchValue(initialFareRuleUi);
    this.referenceCriteria = initReferenceCriteria();
    this.exclusionCriteria = initExclusionCriteria();
    this.setupCriteria();
    this.initializeCustomTemplates();
  }

  isRuleActivated(): boolean {
    return this.fareDetailsForm.get(FareServiceElements.RULE)?.get(FareServiceElements.ACTIVE)?.value === true;
  }

  departureRangeActive(): boolean {
    return this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPARTURE_RANGE_ACTIVE)?.value === true;
  }

  priceDifferenceInPercentage(): boolean {
    return this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.PRICE_RANGE_TYPE)?.value
      === PriceRangeType.RATE;
  }

  priceDifferenceInAmount(): boolean {
    return this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.PRICE_RANGE_TYPE)?.value
      === PriceRangeType.FLAT;
  }

  isNameControlValid(): boolean {
    // return this.fareDetailsForm.get('rule').get('name').valid;
    return true;
  }

  isDescriptionControlValid(): boolean | undefined {
    return this.fareDetailsForm.get(FareServiceElements.RULE)?.get('description')?.valid;
  }

  isNumberOfConnectionsValid(): boolean | undefined {
    return this.fareDetailsForm.get(FareServiceElements.EXCLUSION)
      ?.get(FareServiceElements.NUMBER_OF_CONNECTIONS)?.valid;
  }

  getNameValidationError(): string {
    // if (
    //   this.fareDetailsForm.get('rule').get('name').valid ||
    //   !this.fareDetailsForm.get('rule').get('name').errors
    // ) {
    //   return '';
    // }
    // if (this.fareDetailsForm.get('rule').get('name').errors.maxlength) {
    //   const requiredLength = this.fareDetailsForm.get('rule').get('name').errors.maxlength.requiredLength;
    //   return (
    //     $localize`:@@upp.validation.fares.details.maximumCharacters:Maximum number of characters acceptable: ` + ' ' + requiredLength
    //   ); // NOSONAR
    // }
    // if (this.fareDetailsForm.get('rule').get('name').errors.pattern) {
    //   return $localize`:@@upp.validation.fares.details.typeOfCharacters:Only alphanumeric characters are acceptable`;
    // }
    return '';
  }

  getDescriptionValidationError(): string {
    if (
      this.fareDetailsForm.get(FareServiceElements.RULE)?.get(FareServiceElements.DESCRIPTION)?.valid ||
      !this.fareDetailsForm.get(FareServiceElements.RULE)?.get(FareServiceElements.DESCRIPTION)?.errors
    ) {
      return '';
    }
    if (this.fareDetailsForm.get(FareServiceElements.RULE)?.get(FareServiceElements.DESCRIPTION)?.errors?.maxlength) {
      const requiredLength = this.fareDetailsForm.get(FareServiceElements.RULE)?.get(
        FareServiceElements.DESCRIPTION)?.errors?.maxlength.requiredLength;
      return (
        $localize`:@@upp.validation.fares.details.maximumCharacters:Maximum number of characters acceptable: ` + ' ' + requiredLength
      ); // NOSONAR
    }

    return '';
  }

  getNumberConnectionsValidationError(): string {
    const errors = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.NUMBER_OF_CONNECTIONS)?.errors;
    if (errors?.required) {
      return $localize`:@@upp.validation.connectingServiceType:Enter the number of connections when selecting service types All or Connecting`;
    }
    if (errors?.min) {
      return (
        $localize`:@@upp.validation.connections.min:Minimum number of connections acceptable: ` + ' ' + errors.min.min
      );
    }
    if (errors?.max) {
      return (
        $localize`:@@upp.validation.connections.max:Maximum number of connections acceptable: ` + ' ' + errors.max.max
      );
    }
    if (errors?.positiveNumberValidator) {
      return $localize`:@@upp.validation.positiveNumbers:Enter only positive numbers`;
    }
    if (errors?.integerNumberValidator) {
      return $localize`:@@upp.validation.integers:Only digits without decimals are acceptable`;
    }
    return '';
  }

  getReferenceCriteriaTitle(): string {
    return $localize`:@@upp.fares.create.referenceTitle:Please define your reference fare`;
  }

  canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }

  canLookup(permisionName: string): boolean {
    if (this.readonly) {
      return false;
    }
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return (permissions?.filter(p => p === permisionName).length ?? 0) > 0;
  }

  initLookupData(lookupOptions: LookupOptions) {
    this.fareDetails = {
      ...this.fareDetails,
      reference: {
        ...this.fareDetails.reference
      }
    };

    if (this.fareDetails.reference) {
      this.fareDetails.reference[lookupOptions.fieldName as keyof FareRuleUiReference] = lookupOptions.data;
    }
  }

  isReferenceCriteriaActive(name: string): boolean {
    return this.getReferenceCriteria(name)?.active ?? false;
  }

  isExclusionCriteriaActive(name: string): boolean {
    return this.getExclusionCriteria(name)?.active ?? false;
  }

  toggleCustomReferenceCriteria(criteriaName: string) {
    const currentCriteria = this.getReferenceCriteria(criteriaName);
    if (criteriaName === ReferenceKeys.CAB) {
      const cabinControl = this.fareDetailsForm.get(FareServiceElements.REFERENCE)?.get(ReferenceKeys.CAB);
      if (cabinControl?.value) {
        return;
      }
    }
    if (!currentCriteria) {
      throw new Error('Criteria not found');
      }
      currentCriteria.active = !currentCriteria.active;
  }

  toggleCustomExclusionCriteria(criteriaName: string) {
    const currentCriteria = this.exclusionCriteria.find(criteria => criteria.name === criteriaName);

    if (!currentCriteria) {
      throw new Error('Criteria not found');
    }

    if (this.fareDetailsForm && this.fareDetailsForm.get(FareServiceElements.EXCLUSION)) {
      if (criteriaName === ExclusionKeys.DTR && currentCriteria.active && this.departureRangeActive()) {
        return;
      }
      const exclusionForm = this.fareDetailsForm.get(FareServiceElements.EXCLUSION);
      if (exclusionForm?.get(criteriaName)?.value?.length > 0 && currentCriteria.active) {
        return;
      }
    }

      currentCriteria.active = !currentCriteria.active;
  }

  priceLowerLimitPlaceholder(): string {
    if (this.priceDifferenceInAmount()) {
      return $localize`:@@upp.fare.details.priceRange.lower.amount.placeholder:Price range amount lower limit`;
    }
    return $localize`:@@upp.fare.details.priceRange.lower.percentage.placeholder:Price range percentage lower limit`;
  }

  priceUpperLimitPlaceholder(): string {
    if (this.priceDifferenceInAmount()) {
      return $localize`:@@upp.fare.details.priceRange.upper.amount.placeholder:Price range amount upper limit`;
    }
    return $localize`:@@upp.fare.details.priceRange.upper.percentage.placeholder:Price range percentage upper limit`;
  }

  priceLowerLimitTooltip(): string {
    if (this.priceDifferenceInAmount()) {
      return $localize`:@@upp.fare.details.priceRange.lower.amount.tooltip:Enter a numeric value for the lower limit`;
    }
    return $localize`:@@upp.fare.details.priceRange.lower.percentage.tooltip:Enter a percentage for the price range`;
  }

  priceUpperLimitTooltip(): string {
    if (this.priceDifferenceInAmount()) {
      return $localize`:@@upp.fare.details.priceRange.upper.amount.tooltip:Enter a numeric value for the upper limit`;
    }
    return $localize`:@@upp.fare.details.priceRange.upper.percentage.tooltip:Enter a percentage for the price range`;
  }


  getExclusionCriteriaTitle(): string {
    return $localize`:@@upp.fares.create.textExcludeCriteria:You are about to define the criteria for an exclusion`;
  }

  onChangeServiceTypes(values: any) {
    const numberOfConnections = this.fareDetailsForm?.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.NUMBER_OF_CONNECTIONS);

    if (values?.includes(ServiceType.ALL) || values?.includes(ServiceType.C)) {
      this.shouldEnableNumberOfConnections = true;
      numberOfConnections?.setValidators(this.validatorService.getValidatorsForControl(
        UppComponentNames.FARES, FareServiceElements.NUMBER_OF_CONNECTIONS, false));
      numberOfConnections?.updateValueAndValidity();
    } else {
      this.shouldEnableNumberOfConnections = false;
      numberOfConnections?.clearValidators();
      numberOfConnections?.updateValueAndValidity();
      this.clearNumberOfConnections();
    }
  }

  onChangeDepartureToggle(event: any) {
    if(!event.currentTarget.checked) {
      this.clearDepartureTimeInputs();
      this.resetDepartureSlider();
    }
  }

  onChangeDepatureTimeMinEvent(event: any) {
    if(this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MIN)?.valid){
      this.minDepatureTimeValue = event.target.value;
    }
    if (this.lowerDepartureTimeValid()) {
      this.sliderControl.setValue([this.minDepatureTimeValue, this.maxDepatureTimeValue]);
    }
  }

  onChangeDepatureTimeMaxEvent(event: any) {
    if(this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MAX)?.valid) {
      this.maxDepatureTimeValue = event.target.value;
    }
    if (this.upperDepartureTimeValid()) {
      this.sliderControl.setValue([this.minDepatureTimeValue, this.maxDepatureTimeValue]);
    }
  }

  onSliderUserChange(changeContext: ChangeContext) {
    if (changeContext.value) {
      this.minDepatureTimeValue = changeContext.value;
    }

    if (changeContext.highValue) {
      this.maxDepatureTimeValue = changeContext.highValue;
    }
  }

  lowerPriceValid(): boolean {
    const minValue = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.PRICE_DIFFERENCE_MIN);
    const valid = !this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.errors?.priceRange && minValue?.valid;
    if (valid && this.priceDifferenceInPercentage() && minValue.value != null && minValue.value < -100) {
      return false;
    }
    return valid ?? false;
  }

  upperPriceValid(): boolean {
    return !this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.errors?.priceRange &&
      (this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.PRICE_DIFFERENCE_MAX)?.valid ?? false);
  }

  getLowerPriceRangeErrorMessage(): string {
    const exclusionForm = this.fareDetailsForm.get(FareServiceElements.EXCLUSION);
    const errors = exclusionForm?.get(FareServiceElements.PRICE_DIFFERENCE_MIN)?.errors;
    if (errors?.integerNumberValidator) {
      return $localize`:@@upp.validation.priceRange.lower.integer:Only digits without decimals are acceptable`;
    }
    const minValue = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.PRICE_DIFFERENCE_MIN);
    if (this.priceDifferenceInPercentage() && minValue?.value < -100) {
      return $localize`:@@upp.validation.priceRange.percentage.lower.min:Value is less than minimum ${-100}`;
    }
    if (errors?.max) {
      return $localize`:@@upp.validation.priceRange.lower.max:Value is more than maximum ${errors.max.max}`;
    }
    if (errors?.min) {
      return $localize`:@@upp.validation.priceRange.lower.min:Value is less than minimum `
        + errors.min.min;
    }
    if (exclusionForm?.errors?.priceRange) {
      return $localize`:@@upp.validation.priceRange.lowerBiggerThanUpper:Amount must be less than upper limit`;
    }
    return '';
  }

  getUpperPriceRangeErrorMessage(): string {
    const exclusionForm = this.fareDetailsForm.get(FareServiceElements.EXCLUSION);
    const errors = exclusionForm?.get(FareServiceElements.PRICE_DIFFERENCE_MAX)?.errors;
    if (errors?.integerNumberValidator) {
      return $localize`:@@upp.validation.priceRange.upper.integer:Only digits without decimals are acceptable`;
    }
    if (errors?.max) {
      return $localize`:@@upp.validation.priceRange.upper.max:Value is more than maximum ${errors.max.max}`;
    }
    if (errors?.positiveNumberValidator) {
      return $localize`:@@upp.validation.priceRange.positiveUpper:Enter only positive amounts`;
    }
    if (exclusionForm?.errors?.priceRange) {
      return $localize`:@@upp.validation.priceRange.upperSmallerThanLower:Amount must be more than lower limit`;
    }
    return '';
  }

  lowerDepartureTimeValid(): boolean {
    return !this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.errors?.departureRange &&
      (this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MIN)?.valid ?? false);
  }

  upperDepartureTimeValid(): boolean {
    return !this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.errors?.departureRange &&
      (this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MAX)?.valid ?? false);
  }

  getLowerDepartureTimeErrorMessage(): string {
    const rangeErrors = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.errors?.departureRange;
    const controlErrors = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)
      ?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MIN)?.errors;
    if (controlErrors?.min) {
      return $localize`:@@upp.validation.min:Value is less than minimum`;
    }
    if (controlErrors?.max) {
      return $localize`:@@upp.validation.max:Value is more than maximum`;
    }
    if (controlErrors?.integerNumberValidator) {
      return $localize`:@@upp.validation.departure.lower.integers:Only digits without decimals are acceptable`;
    }
    if (rangeErrors) {
      return $localize`:@@upp.validation.departureTime.lowerBiggerThanUpper:Value must be less than upper limit`;
    }
    return '';
  }

  getUpperDepartureTimeErrorMessage(): string {
    const rangeErrors = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)?.errors?.departureRange;
    const controlErrors = this.fareDetailsForm.get(FareServiceElements.EXCLUSION)
      ?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MAX)?.errors;
    if (controlErrors?.min) {
      return $localize`:@@upp.validation.min:Value is less than minimum`;
    }
    if (controlErrors?.max) {
      return $localize`:@@upp.validation.max:Value is more than maximum`;
    }
    if (controlErrors?.integerNumberValidator) {
      return $localize`:@@upp.validation.departure.upper.integers:Only digits without decimals are acceptable`;
    }
    if (rangeErrors) {
      return $localize`:@@upp.validation.departureTime.upperSmallerThanLower:Value must be more than lower limit`;
    }
    return '';
  }

  getExclusionCriteria(criteriaName: string): SelectableCriteriaWithParams | undefined {
    return getCriteria(this.exclusionCriteria, criteriaName);
  }

  getFormGroup(name: string): UntypedFormGroup | null {
    return this.fareDetailsForm.get(name) as UntypedFormGroup;
  }

  private setupCriteria(): void {
    this.setCriteriaDisabled();
    this.prepareAutocompleteData();
    this.prepareLookupData();
    this.setupReferenceBadgeItems(this.referenceCriteria);
    this.setupExclusionBadgeItems(this.exclusionCriteria);
  }

  private setupReferenceBadgeItems(criteria:  SelectableCriteriaWithParams[]) {
    criteria.forEach(criterion => {
      criterion.badgeItems = this.fareDetails.reference ? getPropertyByKey(this.fareDetails.reference, criterion.name) : [];
    });
  }

  private setupExclusionBadgeItems(criteria: SelectableCriteriaWithParams[]) {
    criteria.forEach(criterion => {
      criterion.badgeItems = this.fareDetails.exclusion ? getPropertyByKey(this.fareDetails.exclusion, criterion.name) : [];
    });
  }

  private initSelectableCriteriaButtonsAndElements(fareRuleUi: FareRuleUi) {
    if (!fareRuleUi) {
      return;
    }

    this.activateCriteriaButtonsAndInputs(
      this.referenceCriteria,
      Object.keys(fareRuleUi.reference ?? {}),
      Object.values(fareRuleUi.reference ?? {})
    );
    this.activateCriteriaButtonsAndInputs(
      this.exclusionCriteria,
      Object.keys(fareRuleUi.exclusion ?? {}),
      Object.values(fareRuleUi.exclusion ?? {})
    );

    this.activateCustomCriteria(fareRuleUi);
  }

  private activateCriteriaButtonsAndInputs(criteriaList: SelectableCriteria[], keys: string[], values: any[]) {
    criteriaList.forEach(criteria => {
      const index = keys.indexOf(criteria.name);
      if (index !== -1) {
        if (values[index] && values[index] !== '' && values[index].length > 0) {
          criteria.active = true;
        } else {
          criteria.active = false;
        }
      }
    });
  }

  private activateCustomCriteria(fareRuleUi: FareRuleUi) {
    const exclusionCriteria = this.getExclusionCriteria(ExclusionKeys.DTR);

    if (exclusionCriteria) {
      exclusionCriteria.active =
        fareRuleUi.exclusion?.departureRangeActive
        ? fareRuleUi.exclusion.departureRangeActive
        : false;
    }

    if (fareRuleUi.reference?.cabins?.value) {
      const referenceCabCriteria = this.getReferenceCriteria(ReferenceKeys.CAB);

      if (referenceCabCriteria !== undefined) {
        referenceCabCriteria.active = true;
      }
    }
  }

  private prepareAutocompleteData(): void {
    this.store.pipe(select(selectAllAvailablePosRecords)).subscribe(result => {
      if (result != null) {
        this.availablePosNames = result.map(posMarketRecord => posMarketRecord.posMarketDetail?.name)
          .filter(name => name !== undefined) as string[];

        const referencePosCriteria = this.getReferenceCriteria(ReferenceKeys.POS);

        if (referencePosCriteria !== undefined) {
          referencePosCriteria.possibleValues = this.availablePosNames;
        }
      }
    });
    this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe(result => {
      if (result != null) {
        this.availableMarketsNames = result.map(posMarketRecord => posMarketRecord.posMarketDetail?.name)
          .filter(name => name !== undefined) as string[];

        const referenceOMCriteria = this.getReferenceCriteria(ReferenceKeys.OM);
        const referenceDMCriteria = this.getReferenceCriteria(ReferenceKeys.DM);

        if (referenceOMCriteria !== undefined) {
          referenceOMCriteria.possibleValues = this.availableMarketsNames;
        }
        if (referenceDMCriteria !== undefined) {
          referenceDMCriteria.possibleValues = this.availableMarketsNames;
        }
      }
    });
  }

  private prepareLookupData(): void {
    if (this.lookupOptions !== undefined && this.lookupOptions.data) {
      this.initLookupData(this.lookupOptions);
      this.navigationService.clearLookupData();
    }

    const posReferenceCriteria = this.getReferenceCriteria(ReferenceKeys.POS);
    const omReferenceCriteria = this.getReferenceCriteria(ReferenceKeys.OM);
    const dmReferenceCriteria = this.getReferenceCriteria(ReferenceKeys.DM);

    if (this.canLookupPos() && posReferenceCriteria) {
      posReferenceCriteria.lookupOptions = this.posLookupOptions;
    }
    if (this.canLookupMarket() && omReferenceCriteria && dmReferenceCriteria) {
      omReferenceCriteria.lookupOptions = this.marketLookupOptions;
      dmReferenceCriteria.lookupOptions = this.marketLookupOptions;
    }
  }

  private setCriteriaDisabled(): void {
    this.referenceCriteria.forEach(criteria => criteria.disabled = this.readonly);
    this.exclusionCriteria.forEach(criteria => criteria.disabled = this.readonly);
  }

  private initializeCustomTemplates(): void {
    const referenceCabCriteria = this.getReferenceCriteria(ReferenceKeys.CAB);

    if (referenceCabCriteria !== undefined) {
      referenceCabCriteria.customTemplate = this.cabinsTemplate;
    }

    const exclusionCntCriteria = this.getExclusionCriteria(ExclusionKeys.CNT);
    const exclusionPrCriteria = this.getExclusionCriteria(ExclusionKeys.PR);
    const exclusionDtrCriteria = this.getExclusionCriteria(ExclusionKeys.DTR);
    const exclusionStCriteria = this.getExclusionCriteria(ExclusionKeys.ST);

    if (exclusionCntCriteria) {
      exclusionCntCriteria.customTemplate = this.contentTemplate;
    }
    if (exclusionPrCriteria) {
      exclusionPrCriteria.customTemplate = this.priceRangeTemplate;
    }
    if (exclusionDtrCriteria) {
      exclusionDtrCriteria.customTemplate = this.departureTimeRangeTemplate;
    }
    if (exclusionStCriteria) {
      exclusionStCriteria.customTemplate = this.serviceTypesTemplate;
    }
  }

  private getReferenceCriteria(criteriaName: string): SelectableCriteriaWithParams | undefined {
    return getCriteria(this.referenceCriteria, criteriaName);
  }

  private clearDepartureTimeInputs(): void {
    this.fareDetailsForm
      .get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MIN)?.setValue(null);
    this.fareDetailsForm
      .get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.DEPATURE_TIME_WINDOW_MAX)?.setValue(null);
  }

  private clearNumberOfConnections(): void {
    this.fareDetailsForm
      .get(FareServiceElements.EXCLUSION)?.get(FareServiceElements.NUMBER_OF_CONNECTIONS)?.setValue(null);
  }

  private resetDepartureSlider(): void {
    this.sliderControl.setValue([this.MIN_DEPARTURE_SLIDER, this.MAX_DEPARTURE_SLIDER]);
  }
}
