import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const exactLengthValidator = (requiredLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const isExactLength = Array.isArray(control.value)
      ? control.value.every((x: string) => x.length === requiredLength)
      : control.value?.length === requiredLength;
    if (control.value && !isExactLength) {
      return {
        exactLengthValidator: {
          requiredValue: requiredLength
        }
      };
    }
    return null;
  };
};
