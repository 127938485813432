import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationState, NAVIGATION_FEATURE } from './navigation-state';

export const selectNavigationFeature = createFeatureSelector<NavigationState>(NAVIGATION_FEATURE);

export const selectNavigation = createSelector(selectNavigationFeature, (state: NavigationState) => state?.navigation);

export const selectPartitionDestination = createSelector(selectNavigationFeature, (state: NavigationState) => state?.partitionDestination);

export const selectShowPartitionSwitchPopup = createSelector(selectNavigationFeature, (state: NavigationState) => state?.showPartitionSwitchPopup);
