import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  POS_ACTION,
  PosActions,
  FinishPosCreationAction,
  FinishPosUpdateAction,
  SetPosListAction,
  DeletePosClearStoreAction,
  DeletePosSearchNotificationAction,
  DeletePosFromListAction,
  SetAllAvailablePosListAction
} from './pos-actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { PosMarketService } from '../../service/pos-market.service';
import { PosMarketRecord } from '../../service/model';
import { TYPE_ERROR } from '../../service/model/pos/pos-market-record';
import { UserSessionExpired } from '../core/actions';
import { ConfigurationService } from '../../service/configuration/configuration.service';

@Injectable()
export class PosEffects {

  searchPos: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(POS_ACTION.SEARCH_POS),
      mergeMap(action => {
        const { request } = (action as PosActions).payload;
        return this.posMarketService.searchPos(request).pipe(
          switchMap(posList => [new DeletePosSearchNotificationAction({}), new SetPosListAction({ posList })]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new SetPosListAction({ posList: [createErrorRecord(error)] }));
          })
        );
      })
    ));

  getAllAvailablePos: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(POS_ACTION.GET_ALL_AVAILABLE_POS),
      mergeMap(action => {
        const partitionEnabled = this.configurationService.getParameter('UPP_PARTITIONS_A');
        const { request } = {
          request: {
            version: '2.0',
            pointOfSale: {}
          }
        };

        request.pointOfSale = partitionEnabled
          ? { partitionId: (action as PosActions).payload }
          : { organization: (action as PosActions).payload };

        return this.posMarketService.searchPos(request).pipe(
          switchMap(posList => [
            new SetAllAvailablePosListAction({ posList }),
            new DeletePosSearchNotificationAction({})
          ]),
          catchError(error => of(new SetAllAvailablePosListAction({ posList: [createErrorRecord(error)] })))
        );
      })
    ));

  deletePos: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(POS_ACTION.DELETE_POS),
      mergeMap(action => {
        const { request } = (action as PosActions).payload;
        return this.posMarketService.deletePos(request).pipe(
          switchMap(deletedPos => [
            new DeletePosSearchNotificationAction({}),
            new DeletePosClearStoreAction(request),
            new DeletePosFromListAction({ deletedRecord: deletedPos })
          ]),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new DeletePosFromListAction({ deletedRecord: createErrorRecord(error) }));
          })
        );
      })
    ));

  createPos: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(POS_ACTION.CREATE_POS),
      mergeMap(action => {
        const { request } = (action as PosActions).payload;
        return this.posMarketService.createPos(request).pipe(
          map(createdRecord => new FinishPosCreationAction({ posRecord: createdRecord })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishPosCreationAction({ posRecord: createErrorRecord(error) }));
          })
        );
      })
    ));

  updatePos: Observable<Action> = createEffect(() => this.actions.pipe(
      ofType(POS_ACTION.UPDATE_POS),
      mergeMap(action => {
        const { request } = (action as PosActions).payload;
        return this.posMarketService.updatePos(request).pipe(
          map(posRecord => new FinishPosUpdateAction({ updatedPosRecord: posRecord })),
          catchError(error => {
            if (error.status === 401) {
              return of(new UserSessionExpired(error.message));
            }
            return of(new FinishPosUpdateAction({ updatedPosRecord: createErrorRecord(error) }));
          })
        );
      })
    ));

    constructor(
      private readonly actions: Actions,
      private readonly posMarketService: PosMarketService,
      private readonly configurationService: ConfigurationService
    ) {}
}

export const createErrorRecord = (error: Error): PosMarketRecord => ({
    id: 'Error',
    statusType: TYPE_ERROR,
    statusMessage: [error.message],
    statusNotification: { error: [error.message] }
});
