import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, filter, map } from 'rxjs';
import { UserDetailsService } from '../../service/user-details.service';
import { DisplayableField, EntryType } from '../../base/search/basic-search-result-table/basic-search-result-table.component';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { NavigationService } from '../../service/core/navigation.service';
import { UppComponentNames } from '../../service/model';
import { PERMISSION_MANAGE_FARES } from '../../service/user-permissions.service';
import {
  ActivateFareRuleDisplayAction,
  ClearFareRulesAction,
  DeleteFareRuleAction,
  DeleteFareRuleDisplayNotificationAction,
  DeleteFareRuleSearchNotificationAction,
  SearchFareRuleAction,
  StartFareModificationAction
} from '../../store/fares/fares-action';
import {
  selectFareRuleDeleteSending,
  selectFareRules,
  selectFareRuleSearchNotification,
  selectFareRuleSearchSending
} from '../../store/fares/fares-selector';
import { FareRuleDeleteRequest, FareRuleSearchRequest } from '../model/fare-request';
import { FareSearchCriteriaUi } from '../model/fare-rule-search-criteria';
import { FareRuleUi } from '../model/fare-rule-ui';
import { dynamicExclusionDisplayableFields, fareDisplayableFields } from './fare-search-displayable-fields';
import { FaresSearchCriteriaComponent } from './fares-search-criteria/fares-search-criteria.component';
import { convertToFareSearchRequest } from '../model/fare-rule-converter';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-fares-search',
  templateUrl: './fares-search.component.html'
})
export class FaresSearchComponent implements OnInit, OnDestroy {
  @ViewChild(FaresSearchCriteriaComponent)
  searchCriteriaComponent: FaresSearchCriteriaComponent;

  fareRuleSearchSending$: Observable<boolean>;
  fareRuleDeleteSending$: Observable<boolean>;
  fareRules$: Observable<FareRuleUi[]>;
  notification$: Observable<UppNotification>;

  mainMessages: NotificationMessages;
  managePermission = PERMISSION_MANAGE_FARES;
  displayableFields: DisplayableField[] = [];

  dynamicExclusionV2: boolean | null = null;
  subscription: Subscription | null;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly configurationService: ConfigurationService
  ) {
    this.fareRuleSearchSending$ = this.store.pipe(select(selectFareRuleSearchSending));
    this.fareRuleDeleteSending$ = this.store.pipe(
      select(selectFareRuleDeleteSending),
      map(value => Boolean(value))
    );
    this.fareRules$ = this.store.pipe(select(selectFareRules));
    this.notification$ = this.store.pipe(
      select(selectFareRuleSearchNotification),
      filter((notification): notification is UppNotification => notification !== undefined)
    );
    this.mainMessages = {
      error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
      warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
      success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
    };
    this.subscription = this.configurationService.getParameter$(FeatureFlags.dynamicExclusionV2).subscribe((value) => {
      this.dynamicExclusionV2 = value ?? false;
      this.displayableFields = this.dynamicExclusionV2 ? dynamicExclusionDisplayableFields : fareDisplayableFields;
    });
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.fares.search.navigationTitle:Fare Search`);
    this.navigationService.activateFares();
    this.navigationService.enableNavigation();
  }

  searchFareRules(searchCriteria: FareSearchCriteriaUi) {
    const request = this.createFareRulesSearchRequest(searchCriteria);
    request.rule = this.userDetailsService.assignRuleEntity(request.rule);
    this.store.dispatch(new SearchFareRuleAction({ request }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteFareRuleSearchNotificationAction({}));
  }

  clearFareRules() {
    this.store.dispatch(new ClearFareRulesAction({}));
    this.router.navigate(['fares/search']);
  }

  getSourceComponent(): UppComponentNames {
    return UppComponentNames.FARES;
  }

  getEntryType(): EntryType {
    return EntryType.FARE;
  }

  recordToSearchResult(fareRecord: FareRuleUi): any {
    const fareRecordForSearch: any = {
      id: fareRecord.id,
      name: fareRecord.rule?.name ?? '',
      description: fareRecord.rule?.description ?? '',
      version: fareRecord.rule?.version ?? '',
      pointOfSaleNames: fareRecord.reference?.pointOfSaleNames?.toString() ?? '',
      originNames: fareRecord.reference?.originMarkets?.toString() ?? '',
      destinationNames: fareRecord.reference?.destinationMarkets?.toString() ?? '',
      ruleStatusActive: fareRecord.rule?.active ?? false
    };

    if (!this.dynamicExclusionV2) {
      fareRecordForSearch['actionType' as keyof typeof fareRecordForSearch]= $localize`:@@upp.fares.search.table.exclude:Excl.`;
    }
    return fareRecordForSearch;
  }

  entryToUpdateCreator(id: string, name: string, organization: string, version: string, ruleVersion: number): FareRuleDeleteRequest {
    return {
      version,
      fare: {
        partition: '1A',
        ruleType: 'DYCTEX',
        release: 0,
        persistentId: id,
        ruleVersion: ruleVersion.toString()
      }
    };
  }

  entryToUpdateNameResolver(entryToUpdate: FareRuleDeleteRequest) {
    return entryToUpdate.fare.persistentId;
  }

  showFareRule(id: string) {
    this.store.dispatch(new ActivateFareRuleDisplayAction({ id: undefined }));
    this.store.dispatch(new DeleteFareRuleDisplayNotificationAction({}));
    this.router.navigate(['fares/display/' + id]);
  }

  modifyFareRule(id: string) {
    this.store.dispatch(new StartFareModificationAction({ id }));
    this.router.navigate(['fares/modify/']);
  }

  deleteFareRule(request: FareRuleDeleteRequest) {
    this.store.dispatch(new DeleteFareRuleAction({ request }));
  }

  storeUnsavedFareSearch() {
    this.searchCriteriaComponent.storeUnsavedFareSearch();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  private createFareRulesSearchRequest(searchCriteriaUi: FareSearchCriteriaUi): FareRuleSearchRequest {
    return convertToFareSearchRequest(searchCriteriaUi);
  }

}
