import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonState } from './common-state';

export const COMMON_FEATURE = 'common';
export const commonSelector = createFeatureSelector<CommonState>(COMMON_FEATURE);

export const selectKeyValuePairs = createSelector(
  commonSelector,
  (state: CommonState) => state
);
