import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FamiliesSearchComponent } from '../../families/families-search/families-search.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFamilySearchGuard  {
  canDeactivate(component: FamiliesSearchComponent): Observable<boolean> | boolean {
    component.storeUnsavedFamilySearch();
    return true;
  }
}
