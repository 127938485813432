@if (dynamicExclusionV2) {
  <h1 i18n="@@upp.dynamicExclusions.search.title" data-test="dynamic-exclusion-rule-search-title"
  >Search Dynamic Exclusion Rules</h1>
}
@else {
  <h1 i18n="@@upp.fares.search.title" data-test="fares-rule-search-title">Search Fare Rules</h1>
}

<div class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="fareRuleDeleteSending$ | async"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</div>

<ama-upp-notification
  id="fares-search-notification"
  class="col"
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-fares-search-criteria
  (searchFares)="searchFareRules($event)"
  [sendingStatus]="fareRuleSearchSending$ | async"
>
</ama-ng-upp-fares-search-criteria>

<ama-ng-upp-basic-search-result-table
  [searchResults]="fareRules$ | async"
  [sendingStatus]="fareRuleSearchSending$ | async"
  [sourceComponent]="getSourceComponent()"
  [entryType]="getEntryType()"
  [recordToSearchResult]="recordToSearchResult"
  [entryToUpdateCreator]="entryToUpdateCreator"
  [entryToUpdateNameResolver]="entryToUpdateNameResolver"
  (deleteEntry)="deleteFareRule($any($event))"
  (clearTableEvent)="clearFareRules()"
  (showDetail)="showFareRule($event)"
  (modifyDetail)="modifyFareRule($event)"
  [managePermission]="managePermission"
  [copyForbidden]="true"
  [displayableFields]="displayableFields"
></ama-ng-upp-basic-search-result-table>
