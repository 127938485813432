import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UppComponent } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import { Observable, Subscription, combineLatestWith, filter } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UserDetailsService } from '../../service/user-details.service';

import { JourneyUi, FlightRuleCreateRequest, convertToJourney, initialJourneyUi, journeyUiEquals } from '../model';
import {
  SetCreateFlightRuleValueAction,
  CreateFlightRuleAction,
  DeleteFlightRuleCreateNotificationAction,
  ActivateCreatedFlightRuleDisplayAction,
  FinishFlightRuleCreationAction
} from '../../store/flight/flights-action';

import {
  selectFlightRuleCreateValue,
  selectFlightRuleCreateNotification,
  selectFlightRuleCreateSending
} from '../../store/flight/flights-selector';
import { FormComponent } from '../../model/FormComponent';
import { ContentType } from '../model/journey';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';
import { FlightService } from '../../service/flight.service';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import { LookupOptions } from 'src/app/core/util/lookup-options';

@Component({
  selector: 'ama-ng-upp-flight-create',
  templateUrl: './flights-create.component.html'
})
export class FlightsCreateComponent implements OnInit, OnDestroy, FormComponent {
  notification$: Observable<UppNotification>;
  mainMessages: NotificationMessages;

  flightForm: UntypedFormGroup;
  journeyUi: JourneyUi;
  readonly = false;
  sendingStatus: boolean;

  uppComponent = UppComponent.FLIGHTS_CREATE;

  showFlightsMGT = false;
  showFamiliesV2 = false;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService,
    private readonly flightService: FlightService,
    private readonly router: Router,
    private readonly store: Store<any>
  ) {
    this.notification$ = this.store.pipe(
      select(selectFlightRuleCreateNotification),
      filter((notification): notification is UppNotification => notification !== undefined)
    );
    this.subscription.add(
      this.store.pipe(select(selectFlightRuleCreateSending)).subscribe((sending) => (this.sendingStatus = sending))
    );
    this.mainMessages = {
      error: $localize`:@@upp.flights.create.mainErrorText:Creation of flight rule failed due to the following errors:`,
      warning: $localize`:@@upp.flights.create.mainWarningText:Warnings were generated during the creation process of flight rule:`,
      success: $localize`:@@upp.flights.create.mainSuccessText:Well done! You get this message about your successful Flight Rule creation`,
      linkSuccessText: $localize`:@@upp.flights.create.linkSuccessText:Display new Flight Rule: `
    };
    this.subscription.add(this.configurationService.getParameter$(FeatureFlags.flightsMGT)
      .pipe(combineLatestWith(this.configurationService.getParameter$(FeatureFlags.familiesV2)))
      .subscribe(([flightsMGT, familiesV2]) => {
        this.showFlightsMGT = flightsMGT ?? false;
        this.showFamiliesV2 = familiesV2 ?? false;
      })
    );
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.flights.create.navigationTitle:Flight Rule Creation`);
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.flightForm = this.formBuilder.group({});
    let lookupOptions: LookupOptions | undefined = undefined ;
    this.subscription.add(
      this.store.pipe(select(selectNavigation)).subscribe((lookupParam) => {
        if (lookupParam) {
          lookupOptions = lookupParam;
        }
      })
    );
    this.subscription.add(
      this.store.pipe(select(selectFlightRuleCreateValue)).subscribe((journey) => {
        if (journey) {
          this.journeyUi = journey;
          // Clear subRules where mock data is applied.
          // TODO: Remove when backend is implemented.
          if (
            (this.showFlightsMGT || (this.showFamiliesV2 && !lookupOptions?.fieldName)) &&
            this.journeyUi.content?.subRules &&
            !this.journeyUi.rule.description?.includes('Copy of')
          ) {
            this.journeyUi = {
              ...this.journeyUi,
              content: {
                ...this.journeyUi.content,
                subRules: []
              }
            };
          }
          this.flightForm.reset();
        }
      })
    );
  }

  saveData() {
    // Touch all fields in order to display errors
    this.flightForm.markAllAsTouched();
    if (this.flightForm.valid) {
      const request = this.createRequest();
      request.journey.rule = this.userDetailsService.assignEntity(request.journey.rule);
      this.storeUnsavedChanges();

      // Mock data for NDC content type with activated MGT activation parameter
      if ((this.showFlightsMGT && request.journey?.applicability?.contentTypes?.includes(ContentType.NDC)) || this.showFamiliesV2) {
        this.subscription.add(
          this.flightService.createFlightRuleMockData(request).subscribe((journeyUi) => {
            this.store.dispatch(new FinishFlightRuleCreationAction({ journey: journeyUi }));
          })
        );
      } else {
        this.store.dispatch(new CreateFlightRuleAction({ request }));
      }
    }
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetCreateFlightRuleValueAction({ value: this.flightForm.getRawValue() }));
  }

  createRequest(): FlightRuleCreateRequest {
    return {
      version: '1.0',
      journey: convertToJourney(this.flightForm.getRawValue())
    };
  }

  closeNotification() {
    this.store.dispatch(new DeleteFlightRuleCreateNotificationAction({}));
  }

  showFlightRuleInDisplay() {
    this.store.dispatch(new ActivateCreatedFlightRuleDisplayAction({}));
    this.router.navigate(['flights/display']);
  }

  hasUnsavedChanges(): boolean {
    return !journeyUiEquals(initialJourneyUi, this.flightForm.value);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
