<div class="mb-5" [formGroup]="fareFormGroup">
  <div class="mb-5">
    <label class="d-block mb-3">
      <ng-container i18n="@@upp.flights.details.fareScopeLabel">Fare scope</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <ama-ng-upp-button-group [items]="fareScopeButtonGroupItems"></ama-ng-upp-button-group>
  </div>

  <div *ngIf="fareScopeButtonGroupMap.fareType.active">
    <label class="form-label">
      <ng-container i18n="@@upp.global.criteria.fareType.label">Fare type</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <ama-ng-upp-lookup-select [items]="fareItems" bindLabel="label" bindValue="value" [multiple]="true"
      groupBy="parent" [groupValue]="groupValueFn" [selectableGroup]="true"
      formControlName="fareTypeNames" (changed)="fareTypeNamesChanged($event)"
      notFoundText="No fare types found"
      i18n-notFoundText="@@upp.global.criteria.fareType.notFoundText"
      ngbTooltip="Select one or multiple fare types."
      i18n-ngbTooltip="@@upp.flights.details.fareTypeTooltip">
    </ama-ng-upp-lookup-select>
    <ama-ng-upp-validation-error-message [control]="fareTypeNames"></ama-ng-upp-validation-error-message>
  </div>
</div>
