import { FlightRuleSearchResponse, FlightRuleCreateUpdateResponse } from '../../flights/model/flight-response';
import { TYPE_ERROR, TYPE_SUCCESS } from '../model';
import { UppNotification, DEFAULT_SUCCESS } from '../../model/notification';
import { TYPE_WARNING } from '../model/pos/pos-market-record';
import { JourneyUi, convertToJourneyUi, FlightRuleDeleteRequest } from '../../flights/model';
import { SUCCESS_STATUS, MISSING_RESULT_STATUS, UNKNOWN_RESULT_STATUS, buildErrorOrWarning } from './response-handler';
import { BaseResponse } from '../../base/model/base-response';

export const successDeleteJourneyUi: JourneyUi = {
  statusType: TYPE_SUCCESS,
  statusNotification: { success: [DEFAULT_SUCCESS] },
  rule: {
    id: '',
    organization: '',
    name: '',
    action: '',
    active: false
  },
  applicability: {
    pointOfSaleName: ''
  }
};

export const warningJourneyUi: JourneyUi = {
  statusType: TYPE_WARNING,
  statusNotification: { warning: [UNKNOWN_RESULT_STATUS] },
  rule: {
    id: 'flightRuleWarning',
    organization: '',
    name: '',
    action: '',
    active: false
  },
  applicability: {
    pointOfSaleName: ''
  }
};

export const errorJourneyUi: JourneyUi = {
  statusType: TYPE_ERROR,
  statusNotification: { error: [MISSING_RESULT_STATUS] },
  rule: {
    id: 'flightRuleError',
    organization: '',
    name: '',
    action: '',
    active: false
  },
  applicability: {
    pointOfSaleName: ''
  }
};

export const buildFlightFromCreateOrUpateResponse = (response: FlightRuleCreateUpdateResponse): JourneyUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return createSuccessRuleForCreate(response, true);
  }
  return buildFailedResponse(response);
};

export const buildFlightRuleListFromSearchResponse = (response: FlightRuleSearchResponse): JourneyUi[] => {
  if (response.status.state === SUCCESS_STATUS) {
    return createFlightRuleList(response);
  }
  return [buildFailedResponse(response)];
};

export const buildFlightRuleFromDeleteResponse = (response: BaseResponse, request: FlightRuleDeleteRequest): JourneyUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return {
      ...successDeleteJourneyUi,
      rule: {
        ...successDeleteJourneyUi.rule,
        id: request.journey.rule.id
      }
    };
  }

  return buildFailedResponse(response);
};

const buildFailedResponse = (response: BaseResponse): JourneyUi =>
  buildErrorOrWarning(warningJourneyUi, errorJourneyUi, response) as JourneyUi;

const createFlightRuleList = (response: FlightRuleSearchResponse): JourneyUi[] => {
  const journeys: JourneyUi[] = [];
  response.journeys.forEach(journey => journeys.push(convertToJourneyUi(journey)));
  return journeys;
};

const createSuccessRuleForCreate = (response: FlightRuleCreateUpdateResponse, addLinkData: boolean): JourneyUi => {
  const notification: UppNotification = {
    success: [DEFAULT_SUCCESS]
  };

  if (!response.journey.rule.id) {
    throw new Error('id is null or undefined.');
  }

  if (addLinkData) {
    notification.links = [
      {
        id: response.journey.rule.id,
        name: response.journey.rule.name
      }
    ];
  }
  const flightRule = convertToJourneyUi(response.journey);
  flightRule.statusType = TYPE_SUCCESS;
  flightRule.statusNotification = notification;
  return flightRule;
};
