import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { JourneyUiJourneyScope, initialJourneyUiJourneyScope } from '../../model';
import { UppComponentNames } from '../../../service/model/upp-component';
import { journeyTypesGroupValueFn, journeyTypesOptions } from '../../../model/common-elements';

@Component({
  selector: 'ama-ng-upp-journey-content',
  templateUrl: './journey-content.component.html'
})
export class JourneyContentComponent implements OnInit {
  @Input() journeyFormGroup: UntypedFormGroup;
  @Input() journeyScope: JourneyUiJourneyScope = initialJourneyUiJourneyScope;
  @Input() readonly = false;

  UppComponentNames = UppComponentNames;

  journeyTypesOptions = journeyTypesOptions;
  journeyTypesGroupValueFn = journeyTypesGroupValueFn;

  constructor(
    private readonly formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.journeyFormGroup?.setControl('flightCategories', this.formBuilder.control({
      value: this.journeyScope?.flightCategories,
      disabled: this.readonly
    }, [Validators.required]));
  }
}
