import { Component, Input, OnInit } from '@angular/core';
import { Card } from '../model/card';

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.scss']
})
export class CardDeckComponent implements OnInit {
  @Input() itemsPerRow!: number;
  @Input() cards!: Card[];
  rowsIndices!: number[];
  percentageOfDeck!: number;

  constructor() {}

  ngOnInit(): void {
    this.rowsIndices = [];
    let currentRowIndex = 0;
    for (let i = 0; i < this.cards.length; i++) {
      if (i % this.itemsPerRow === 0) {
        this.rowsIndices.push(currentRowIndex++);
      }
    }

    this.percentageOfDeck = 100 / this.itemsPerRow;
  }
}
