import { Component, Input, signal, TemplateRef, WritableSignal } from '@angular/core';
import { LoginAlertModel } from '@seco/login';
import { DfModalService } from '@design-factory/design-factory';

@Component({
  selector: 'app-ama-upp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @Input() clpError: LoginAlertModel[] = [];

  cookieBannerLoaded: WritableSignal<boolean> = signal(true);

  constructor(private readonly modalService: DfModalService) {
    this.cookieBannerLoaded.set(!this.checkCookieBannerDeclined());
  }

  public closeCookieBanner(): void {
    localStorage.setItem(UPP_COOKIE_BANNER_CLOSED_STORAGE_FLAG, 'true');
    this.cookieBannerLoaded.set(false);
  }

  public openCookiePolicyModal(content: TemplateRef<any>): void {
    this.modalService.open(content);
  }

  private checkCookieBannerDeclined(): boolean {
    return Boolean(localStorage.getItem(UPP_COOKIE_BANNER_CLOSED_STORAGE_FLAG));
  }
}

export const UPP_COOKIE_BANNER_CLOSED_STORAGE_FLAG = 'UPP_COOKIE_BANNER_CLOSED';
