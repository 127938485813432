import {
  DEFAULT_ERROR,
  NAMES,
  TWO_SYMBOLS
} from '../../service/model/common/validation-messages';
import {
  CARRIERS,
  DESCRIPTION,
  NAME,
  NAMES_LIST
} from '../../service/model/common/validators';
import { ValidationProvider } from '../../service/upp-validator.service';

export class FlightServiceElements {
  static readonly NAME = 'name';
  static readonly DESCRIPTION = 'description';
  static readonly POINT_OF_SALES = 'pointOfSaleNames';
  static readonly ORIGINS = 'originNames';
  static readonly DESTINATIONS = 'destinationNames';
  static readonly CARRIERS = 'carriers';
  static readonly FIRST_MARKET = 'firstMarketName';
  static readonly SECOND_MARKET = 'secondMarketName';

  static readonly CARRIER_CODES = 'carrierCodes';
  static readonly ALLIANCES = 'alliances';
  static readonly AIRLINE_FAMILIES = 'carriersFamily';
  static readonly PRIME_DATA = 'primeData';
  static readonly OPERATING_CARRIER_DATA = 'operatingCarrierData';
  static readonly MARKETING_CARRIER_DATA = 'marketingCarrierData';
  static readonly FLIGHT_RANGES = 'flightRanges';
  static readonly SEGMENT_POSITIONS = 'segmentPositions';
  static readonly ROUTE_MARKET_NAME = 'name';
  static readonly ROUTE_MARKET_POSITION = 'position';
  static readonly JOURNEY_CATEGORY_TYPES = 'flightCategories';
}

export const VALIDATORS = new Map<string, ValidationProvider>([
  [FlightServiceElements.NAME, { validator: NAME, massages: DEFAULT_ERROR }],
  [FlightServiceElements.DESCRIPTION, { validator: DESCRIPTION, massages: DEFAULT_ERROR }],
  [FlightServiceElements.CARRIERS, { validator: CARRIERS, massages: TWO_SYMBOLS }],
  [FlightServiceElements.POINT_OF_SALES, { validator: NAMES_LIST, massages: NAMES }],
  [FlightServiceElements.ORIGINS, { validator: NAMES_LIST, massages: NAMES }],
  [FlightServiceElements.DESTINATIONS, { validator: NAMES_LIST, massages: NAMES }]
]);
