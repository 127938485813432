<h1 i18n="@@upp.families.display.title">Display Hotel Family</h1>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-families-details
  [familiesDetailsForm]="familyForm"
  [familyDetails]="family"
  [readonly]="readonly"
  [isFamilyTypeDisabled]="isFamilyTypeDisabled"
  [parent]="uppComponent"
>
</ama-ng-upp-families-details>

<div class="d-flex justify-content-end">
  <button
    i18n="@@upp.global.button.modify"
    type="button"
    [disabled]="!modifyButtonEnabled()"
    class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
    (click)="modifyFamily()"
  >
    Modify
  </button>
</div>
