import { PosMarketDetail } from './pos-market-detail';
import { BaseUi } from '../../../base/model/base-ui';

export const TYPE_SUCCESS = 'success';
export const TYPE_WARNING = 'warning';
export const TYPE_ERROR = 'error';

export interface PosMarketRecord extends BaseUi {
  statusMessage?: string[];
  posMarketDetail?: PosMarketDetail;
}
