import { DEFAULT_ERROR, NAMES, TEN_CARRIERS_MSG } from '../../service/model/common/validation-messages';
import { DESCRIPTION, NAME, NUMBER_OF_CONNECTIONS, SINGLE_NAME, TEN_CARRIERS } from '../../service/model/common/validators';
import { ValidationProvider } from '../../service/upp-validator.service';

export class FareServiceElements {
  static readonly RULE = 'rule';
  static readonly EXCLUSION = 'exclusion';
  static readonly REFERENCE = 'reference';
  static readonly NAME = 'name';
  static readonly DESCRIPTION = 'description';
  static readonly ACTIVE = 'active';
  static readonly POS = 'pointOfSaleNames';
  static readonly ORIGIN_MARKETS = 'originMarkets';
  static readonly DESTINATION_MARKETS = 'destinationMarkets';
  static readonly CONTENT_OPTIONS = 'content';
  static readonly CONTENT_OPTIONS_EDIFACT = 'EDIFACT';
  static readonly CONTENT_OPTIONS_NDC = 'N';
  static readonly RULE_EFFECTIVE_DATES_START = 'ruleEffectiveDatesStart';
  static readonly RULE_EFFECTIVE_DATES_END = 'ruleEffectiveDatesEnd';
  static readonly PRICE_RANGE_TYPE = 'priceRangeType';
  static readonly DEPARTURE_RANGE_ACTIVE = 'departureRangeActive';
  static readonly DEPATURE_TIME_WINDOW_MIN = 'departureTimeWindowMin';
  static readonly DEPATURE_TIME_WINDOW_MAX = 'departureTimeWindowMax';
  static readonly DEPARTURE_TIME_SLIDER = 'departureTimeSlider';
  static readonly PRICE_DIFFERENCE_MIN = 'priceDifferenceMin';
  static readonly PRICE_DIFFERENCE_MAX = 'priceDifferenceMax';
  static readonly NUMBER_OF_CONNECTIONS = 'numberOfConnections';
  static readonly AIRLINES = 'airlines';
  static readonly CARRIERS = 'carriers';
}

export enum CabinOptions {
  M = 'standardEconomy',
  Y = 'premiumEconomy',
  W = 'economy',
  C = 'business',
  F = 'first'
}

export const CabinCodes = new Map<CabinOptions, string>([
  [CabinOptions.M, 'M'],
  [CabinOptions.Y, 'Y'],
  [CabinOptions.W, 'W'],
  [CabinOptions.C, 'C'],
  [CabinOptions.F, 'F']
]);

export enum FareTypeOptions {
  ALL = 'All',
  RP = 'ATPCO Public',
  RV = 'ATPCO Nego',
  RB = 'ATPCO Corporate',
  RN = 'Amadeus Nego',
  RC = 'Amadeus Corporate'
}

export const FareTypeCodes = new Map<FareTypeOptions, string>([
  [FareTypeOptions.RP, 'RP'],
  [FareTypeOptions.RV, 'RV'],
  [FareTypeOptions.RB, 'RB'],
  [FareTypeOptions.RN, 'RN'],
  [FareTypeOptions.RC, 'RC']
]);

export enum PriceRangeType {
  RATE = 'R',
  FLAT = 'F',
}

export enum ServiceType {
  ALL = 'All',
  D = 'Direct',
  N = 'Non stop',
  C = 'Connecting'
}

export const ServiceTypeCodes = new Map<ServiceType, string>([
  [ServiceType.D, 'D'],
  [ServiceType.N, 'N'],
  [ServiceType.C, 'C']
]);

export enum ServiceTypeOptions {
  ALL = 'All',
  DIRECT = 'Direct',
  NON_STOP = 'Non stop',
  CONNECTING = 'Connecting'
}

export const VALIDATORS = new Map<string, ValidationProvider>([
  [FareServiceElements.NAME, {validator: NAME, massages: DEFAULT_ERROR}],
  [FareServiceElements.DESCRIPTION, {validator: DESCRIPTION, massages: DEFAULT_ERROR}],
  [FareServiceElements.AIRLINES, {validator: TEN_CARRIERS, massages: TEN_CARRIERS_MSG}],
  [FareServiceElements.CARRIERS, {validator: TEN_CARRIERS, massages: TEN_CARRIERS_MSG}],
  [FareServiceElements.NUMBER_OF_CONNECTIONS, {validator: NUMBER_OF_CONNECTIONS, massages: DEFAULT_ERROR}],
  [FareServiceElements.POS, {validator: SINGLE_NAME, massages: NAMES}],
  [FareServiceElements.ORIGIN_MARKETS, {validator: SINGLE_NAME, massages: NAMES}],
  [FareServiceElements.DESTINATION_MARKETS, {validator: SINGLE_NAME, massages: NAMES}]
]);

