import { PosMarketDetail, PosMarketSearchCriteria } from '../../service/model';
import { PosMarketRecord } from '../../service/model/pos/pos-market-record';
import { Notification } from '../../model/notification';

export const POS_FEATURE = 'posUppUi';

export interface PosState {
  posRecords?: PosMarketRecord[];
  availablePosRecords?: PosMarketRecord[];
  selectedPosNames?: Set<string>;
  pointOfSale: {
    search?: {
      sending: boolean;
      value: PosMarketSearchCriteria;
    };
    delete?: {
      sending: boolean;
    };
    create: PosStatus;
    modify: PosStatus;
    display: PosStatus;
  };
  notification?: Notification;
}

export interface PosStatus {
  sending: boolean;
  active?: boolean;
  value: PosMarketDetail;
  updatedRecord?: PosMarketRecord;
}

export const initialPosStatus: PosStatus = {
  sending: false,
  value: {
    organization: '',
    name: ''
  },
  active: false
};

export const initialPointOfSale = {
  search: {
    sending: false,
    value: {
      name: '',
      include: {
        world: false,
        regions: [] as any[],
        countries: [] as any[],
        cities: [] as any[],
        airports: [] as any[],
        officeIds: [] as any[],
        corporateCodesAndQualifiers: [] as any[]
      }
    }
  },
  delete: {
    sending: false
  },
  create: initialPosStatus,
  modify: initialPosStatus,
  display: initialPosStatus
};

export const posInitialState: PosState = {
  pointOfSale: initialPointOfSale
};
