import { SelectableCriteriaWithParams } from '../../base/search-criteria/selectable-criteria';
import { CabinOptions, FareTypeOptions } from './fare-service-elements';

export enum ReferenceKeys {
    POS = 'pointOfSaleNames',
    OM = 'originMarkets',
    DM = 'destinationMarkets',
    AIR = 'airlines',
    CAB = 'cabins',
    FT = 'fareTypes'
}

export enum ExclusionKeys {
    CAR = 'carriers',
    CNT = 'content',
    PR = 'priceRangeType',
    DTR = 'departureTimeRange',
    FT = 'fareTypes',
    ST = 'serviceTypes'
}

export const fareTypeOptions: string[] = [
    $localize`:@@upp.fares.details.fareType.all:` + FareTypeOptions.ALL,
    $localize`:@@upp.fares.details.fareType.atpcoPublic:` + FareTypeOptions.RP,
    $localize`:@@upp.fares.details.fareType.atpcoNego:` + FareTypeOptions.RV,
    $localize`:@@upp.fares.details.fareType.atcoCorporate:` + FareTypeOptions.RB,
    $localize`:@@upp.fares.details.fareType.amaNego:` + FareTypeOptions.RN,
    $localize`:@@upp.fares.details.fareType.amaCorporate:` + FareTypeOptions.RC
];

export const cabinOptions = [
    {
        value: CabinOptions.M,
        label: $localize`:@@upp.fares.create.cabin.option.standardEconomy:Standard Economy`
    },
    {
        value: CabinOptions.Y,
        label: $localize`:@@upp.fares.create.cabin.option.premiumEconomy:Premium Economy`
    },
    {
        value: CabinOptions.W,
        label: $localize`:@@upp.fares.create.cabin.option.economy:Economy`
    },
    {
        value: CabinOptions.C,
        label: $localize`:@@upp.fares.create.cabin.option.business:Business`
    },
    {
        value: CabinOptions.F,
        label: $localize`:@@upp.fares.create.cabin.option.first:First`
    }
];

export const FareTypeCodes = new Map<FareTypeOptions, string>([
  [FareTypeOptions.RP, 'RP'],
  [FareTypeOptions.RV, 'RV'],
  [FareTypeOptions.RB, 'RB'],
  [FareTypeOptions.RN, 'RN'],
  [FareTypeOptions.RC, 'RC']
]);

export const fareTypesOptions = [
  {
    value: 'RP',
    label: $localize`:@@upp.fares.create.fareType.option.rp:RP - Published Fares`
  },
  {
    value: 'RV',
    label: $localize`:@@upp.fares.create.fareType.option.rv:RV - Negotiated Fares`
  },
  {
    value: 'RB',
    label: $localize`:@@upp.fares.create.fareType.option.rb:RB - Corporate Fares`
  },
  {
    value: 'RN',
    label: $localize`:@@upp.fares.create.fareType.option.rn:RN - Amadeus Negotiated Fares`
  },
  {
    value: 'RC',
    label: $localize`:@@upp.fares.create.fareType.option.rc:RC - Amadeus Corporate Fares`
  }
];

export const initReferenceCriteria = (): SelectableCriteriaWithParams[] =>
    [
        {
            name: ReferenceKeys.POS,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.pos.label:POS`,
            numberOfBadges: 1,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.pos.placeholder:POS name`,
            tooltip: $localize`:@@upp.global.criteria.pos.tooltip:Enter a POS name, max. 30 alpha numeric`,
            notFoundText: $localize`:@@upp.global.criteria.pos.notFoundText:No POS found`
        },
        {
            name: ReferenceKeys.OM,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.originMarkets.label:Origin Markets`,
            numberOfBadges: 1,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.originMarkets.placeholder:Origin Market name`,
            tooltip: $localize`:@@upp.global.criteria.originMarkets.tooltip:Enter a origin market name, max. 30 alpha numeric`,
            notFoundText: $localize`:@@upp.global.criteria.originMarkets.notFoundText:No origin markets found`
        },
        {
            name: ReferenceKeys.DM,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.destinationMarkets.label:Destination Markets`,
            numberOfBadges: 1,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.destinationMarkets.placeholder:Destination Market name`,
            tooltip: $localize`:@@upp.global.criteria.destinationMarkets.tooltip:Enter a destination market name, max. 30 alpha numeric`,
            notFoundText: $localize`:@@upp.global.criteria.destinationMarket.notFoundText:No destination markets found`
        },
        {
            name: ReferenceKeys.AIR,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.airlines.label:Airlines`,
            numberOfBadges: 10,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.airlines.placeholder:Airline code`,
            tooltip: $localize`:@@upp.global.criteria.airlines.tooltip:Enter 2 character airline code(s)`,
            notFoundText: $localize`:@@upp.global.criteria.airlines.notFoundText:No airlines found`
        },
        {
            name: ReferenceKeys.CAB,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.cabin.label:Cabin`,
            customInputField: true
        },
        {
            name: ReferenceKeys.FT,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.fareTypes.label:Fare Types`,
            numberOfBadges: 100,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.fareTypes.placeholder:Fare types`,
            tooltip: $localize`:@@upp.global.criteria.fareTypes.tooltip:Select one or more fare types`,
            notFoundText: $localize`:@@upp.global.criteria.fareTypes.notFoundText:No fare type found`,
            disableNewValues: true,
            possibleValues: fareTypeOptions
        }
    ];

export const initExclusionCriteria = (): SelectableCriteriaWithParams[] =>
    [
        {
            name: ExclusionKeys.CNT,
            active: true,
            disabled: false,
            title: $localize`:@@upp.global.criteria.content.label:Content`,
            customInputField: true
        },
        {
            name: ExclusionKeys.PR,
            active: true,
            disabled: false,
            title: $localize`:@@upp.global.criteria.priceRange.label:Price Range`,
            customInputField: true
        },
        {
            name: ExclusionKeys.CAR,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.airlines.label:Airlines`,
            numberOfBadges: 10,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.airlines.placeholder:Airline code`,
            tooltip: $localize`:@@upp.global.criteria.airlines.tooltip:Enter 2 character airline code(s)`,
            notFoundText: $localize`:@@upp.global.criteria.airlines.notFoundText:No airlines found`
        },
        {
            name: ExclusionKeys.DTR,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.departureTimeRange.label:Departure Time Range`,
            customInputField: true
        },
        {
            name: ExclusionKeys.FT,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.fareTypes.label:Fare Types`,
            numberOfBadges: 100,
            badgeItems: [],
            placeholder: $localize`:@@upp.global.criteria.fareTypes.placeholder:Fare types`,
            tooltip: $localize`:@@upp.global.criteria.fareTypes.tooltip:Select one or more fare types`,
            notFoundText: $localize`:@@upp.global.criteria.fareTypes.notFoundText:No fare type found`,
            disableNewValues: true,
            possibleValues: fareTypeOptions
        },
        {
            name: ExclusionKeys.ST,
            active: false,
            disabled: false,
            title: $localize`:@@upp.global.criteria.serviceTypes.label:Service Types`,
            customInputField: true
        }
    ];
